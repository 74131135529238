import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationFragmentFragmentDoc } from '../api/OrganizationFragment.generated';
export type GetOrganizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename: 'Query', organizations: { __typename: 'OrganizationConnection', edges: Array<{ __typename: 'OrganizationEdge', node: { __typename: 'Organization', id: string, name: string, type: Types.OrganizationType, logoImageUrl: string | null } }> } };


export const GetOrganizationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOrganizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationFragment"}}]}}]}}]}}]}},...OrganizationFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetOrganizationsQuery, GetOrganizationsQueryVariables>;