import { useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { endOfDay } from "date-fns";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";
import useSound from "use-sound";
import NotFoundScreen from "../404";
import { MeetingStatus } from "../api/graphql";
import getFrom from "../lib/getFrom";
import useApolloErrorHandler from "../lib/handleApolloError";
import {
  ServerToClientEvents,
  useServerEvent,
} from "../lib/serverEventsProvider";
import { useCurrentCorrespondent } from "../SessionBoundary";
import DialingScreen, { BlankDialingScreen } from "./DialingScreen";
import { EndVoiceCallDocument } from "./EndVoiceCall.generated";
import { GetMeetingInfoDocument } from "./GetMeetingInfo.generated";
import LiveCall from "./LiveCall";
import VoiceCallRingTone from "./LiveCall/sounds/VoiceCallRingTone.mp3";
import Rating from "./Rating";

type VoiceCallPhase =
  | "dialing"
  | "call"
  | "declined"
  | "ended"
  | "terminated"
  | "missed"
  | "missed-closed"
  | "connectedElsewhere";

export default function VoiceCallScreen() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const location = useLocation();
  const user = useCurrentCorrespondent();
  const from = getFrom(location) || "/";
  const [phase, setPhase] = useState<VoiceCallPhase>("dialing");
  const [muted, setMuted] = useState(false);
  const [alerts, setAlerts] = useState(true);
  const [selfEndedCall, setSelfEndedCall] = useState<boolean>(false);
  const [peerEndedCall, setPeerEndedCall] = useState<boolean>(false);
  const [hasPeerEverJoined, setHasPeerEverJoined] = useState(false);
  const [alreadyClosingFromMissed, setAlreadyClosingFromMissed] =
    useState(false);

  // Ringtone data
  const [ringtonePlayable, setRingtonePlayable] = useState<boolean>(false);
  const [ringtonePlaying, setRingtonePlaying] = useState<boolean>(false);
  const [playRingtone, { stop }] = useSound(VoiceCallRingTone, {
    interrupt: true, // Safety guard - `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    onplay: () => setRingtonePlaying(true),
    onend: () => setRingtonePlaying(false),
  });
  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();

  const [endCallMutation] = useMutation(EndVoiceCallDocument, {
    variables: {
      input: {
        meetingId: id || "",
        status: hasPeerEverJoined ? MeetingStatus.Ended : MeetingStatus.NoShow,
        // `userInitiated` is currently hard-coded to `true` below because onLeave is only called
        // from clicking the "End call" button, so we can safely assume user intent. If that
        // changes, revisit whether or not this needs to be dynamic
        userInitiated: true,
      },
    },
  });

  const onLeave = useCallback(() => {
    // Set the local user as the party
    // responsible for ending the call
    setSelfEndedCall(true);
    // Call the mutation
    endCallMutation().then(() => {
      if (hasPeerEverJoined) setPhase("ended");
      else setPhase("missed-closed");
    });
  }, [hasPeerEverJoined, endCallMutation, setSelfEndedCall]);

  const onEnded = useCallback(() => {
    if (!["terminated", "connectedElsewhere"].includes(phase)) {
      if (hasPeerEverJoined) setPhase("ended");
      else setPhase("missed-closed");
    }
  }, [hasPeerEverJoined, phase]);

  if (!id) throw new Error("Missing meeting id");

  const { data, loading, error, refetch } = useQuery(GetMeetingInfoDocument, {
    fetchPolicy: "no-cache", // do not cache access token
    variables: { meetingId: id, checkVoiceCallLimit: true },
    onError: (e) => handleApolloError(e),
  });
  const visitors =
    data?.meeting.correspondents.filter((c) => c.__typename === "Visitor") ??
    [];
  const peerFirstName =
    visitors.length > 0 ? visitors[0].firstName : t("Your contact");

  useServerEvent("newVoiceCall", ({ id: newCallId }) => {
    if (newCallId === id) refetch();
  });

  // Informs the user that the call recipient never answered
  const informCallEvent = useCallback(
    (vcPhase: VoiceCallPhase) => {
      const firstName = {
        firstName: peerFirstName,
      };
      snackbarContext.alert(
        "info",
        vcPhase === "declined"
          ? t("{{firstName}} declined the call", firstName)
          : t("{{firstName}} did not answer", firstName)
      );
    },
    [snackbarContext, t, peerFirstName]
  );

  // Used to play a ringtone to indicate to the
  // user their call is outgoing. Stops whenever
  // the phase is not "call"
  useEffect(() => {
    if (phase === "call" && !hasPeerEverJoined && ringtonePlayable) {
      if (!ringtonePlaying) {
        // Note the `onplay` callback defined
        // above handles setting ringtonePlaying,
        // which is why we're not setting in state here.
        // Looping is accomplished automatically because
        // when the track ends, it will call `onend` which
        // will set ringtonePlaying to false, allowing the
        // play function to be invoked anew
        playRingtone();
      }
    } else if (ringtonePlaying) {
      // Otherwise stop if playing
      stop();
    }
    // Clean-up
    return () => {
      if (ringtonePlaying) stop();
    };
  }, [
    phase,
    hasPeerEverJoined,
    playRingtone,
    stop,
    ringtonePlaying,
    ringtonePlayable,
  ]);

  // To better inform the user, shows a snackbar when the call phase
  // is set to missed or missed-closed to inform the user that call
  // recipient never answered
  useEffect(() => {
    if (["missed", "missed-closed", "declined"].includes(phase))
      informCallEvent(phase);
  }, [phase, informCallEvent]);

  const onDeclined = useCallback(
    ({ id: declinedCallId }: { id: string }) => {
      if (declinedCallId === id && phase === "call") setPhase("declined");
    },
    [phase, id]
  );

  useServerEvent("voiceCallDeclined", onDeclined);

  useServerEvent(
    "voiceCallEnded",
    useCallback(
      ({
        id: meetingId,
        userInitiated,
      }: ServerToClientEvents["voiceCallEnded"]) => {
        if (id === meetingId) {
          if (userInitiated) setPeerEndedCall(true);
          onEnded();
        }
      },
      [id, onEnded]
    )
  );

  useEffect(() => {
    if (
      data &&
      data.meeting.status === MeetingStatus.Live &&
      phase === "dialing"
    )
      setPhase("call");
    if (
      data &&
      [
        MeetingStatus.Ended,
        MeetingStatus.Terminated,
        MeetingStatus.NoShow,
      ].includes(data.meeting.status) &&
      Date.now() < endOfDay(data.meeting.interval.endAt).getTime() // This is end of day local time: https://date-fns.org/docs/endOfDay
    ) {
      setPhase("ended");
    }
  }, [data, data?.meeting.status, phase]);

  useEffect(() => {
    if (phase === "missed" && !alreadyClosingFromMissed) {
      setTimeout(() => {
        setPhase("missed-closed");
      }, 2000);
      setAlreadyClosingFromMissed(true);
    }
  }, [phase, setPhase, alreadyClosingFromMissed, setAlreadyClosingFromMissed]);

  if (loading || !data?.meeting) return <BlankDialingScreen />;

  if (error) throw error;

  if (
    ![MeetingStatus.Live, MeetingStatus.Scheduled].includes(
      data.meeting.status
    ) &&
    phase !== "ended" // phase is 'ended' if we already determined that the call ended today.
  )
    return <NotFoundScreen />;

  let children: React.ReactNode;
  if (phase === "dialing") {
    children = (
      <DialingScreen
        muted={muted}
        alerts={alerts}
        onLeave={onLeave}
        onToggleAlerts={() => setAlerts((a) => !a)}
        onToggleAudio={() => setMuted((m) => !m)}
        meeting={data.meeting}
        missed={false}
      />
    );
  } else if (phase === "call") {
    children = (
      <Suspense
        fallback={
          <DialingScreen
            muted={muted}
            alerts={alerts}
            onLeave={onLeave}
            onToggleAudio={() => setMuted((m) => !m)}
            onToggleAlerts={() => setAlerts((a) => !a)}
            meeting={data.meeting}
            missed={false}
          />
        }
      >
        <LiveCall
          meeting={data.meeting}
          onLeave={onLeave}
          beginHidden={false}
          beginMuted={muted}
          alerts={alerts}
          onToggleAlerts={() => setAlerts((a) => !a)}
          onPeerJoined={() => {
            if (ringtonePlaying) stop();
            setHasPeerEverJoined(true);
          }}
          onTerminated={() => setPhase("terminated")}
          onConnectedElsewhere={() => setPhase("connectedElsewhere")}
          onAudioReady={() => setRingtonePlayable(true)}
          onEnded={onEnded}
        />
      </Suspense>
    );
  } else if (phase === "missed") {
    children = (
      <DialingScreen
        muted={muted}
        alerts={alerts}
        onToggleAudio={() => {}}
        onToggleAlerts={() => {}}
        meeting={data.meeting}
        onLeave={() => {}}
        missed
      />
    );
  } else if (phase === "missed-closed" || phase === "declined") {
    return <Navigate to={from} />;
  } else {
    children = (
      <Rating
        title={
          selfEndedCall
            ? t("You ended the call")
            : peerEndedCall
              ? t("{{firstName}} ended the call", {
                  firstName: peerFirstName,
                })
              : t("Voice call ended")
        }
        meeting={data.meeting}
        isFeedbackDisabled={!hasPeerEverJoined}
        terminated={phase === "terminated"}
        connectedElsewhere={phase === "connectedElsewhere"}
        allowTextFeedback={user.__typename === "Visitor"}
      />
    );
  }

  return children;
}
