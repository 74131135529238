import { Media } from "@ameelio/ui";
import { Bolt, ImageOutlined, PictureAsPdfOutlined } from "@mui/icons-material";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import useChange from "@react-hook/change";
import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Facility,
  Inmate,
  InstantReply,
  Message,
  MessageAvailability,
  MessageType,
  Visitor,
} from "../api/graphql";
import getFilenameFromUrl from "../lib/File";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { shade } from "../theme";
import EventBubble from "./EventBubble";
import getInstantReplyContent from "./getInstantReplyContent";
import MessageStatus from "./MessageStatus";
import TimelineEventWrapper from "./TimelineEventWrapper";
import ZoomableImage from "./ZoomableImage";

export type MessageEventObject = Pick<
  Message,
  | "id"
  | "availability"
  | "isRead"
  | "createdAt"
  | "content"
  | "statusDetails"
  | "isInstantReply"
  | "fileUrl"
  | "type"
> & {
  sender: Pick<Visitor, "id"> | Pick<Inmate, "id">;
};

type Props = {
  facility: Pick<Facility, "publicId">;
  latestOwnMessageId: string | undefined;
  object: MessageEventObject;
};

function useMessageContent(message: MessageEventObject) {
  const { t } = useTranslation();
  const instantReplyContent = getInstantReplyContent(
    t,
    message.content as InstantReply
  );
  const user = useCurrentCorrespondent();
  const myMessage = message.sender.id === user.id;

  if (message.isInstantReply) return instantReplyContent;
  return myMessage || message.availability === MessageAvailability.Available
    ? message.content
    : message.availability === MessageAvailability.Pending
      ? message.type === MessageType.Photo
        ? t("This photo is hidden to you while being reviewed")
        : t("This message is hidden to you while being reviewed")
      : message.type === MessageType.Photo
        ? t("This photo is hidden because it was rejected")
        : t("This message is hidden because it was rejected");
}

function MediaNotAvailable({
  message,
  mediaIcon,
}: {
  message: string;
  mediaIcon?: JSX.Element;
}) {
  return (
    <Box
      sx={{
        py: 1,
        px: 2,
        backgroundColor: grey["600"],
        borderRadius: "16px",
        color: "common.white",
      }}
    >
      <Media
        gap={0.5}
        image={
          mediaIcon || <ImageOutlined fontSize="inherit" color="inherit" />
        }
      >
        <Typography variant="body2" color="common.white">
          {message}
        </Typography>
      </Media>
    </Box>
  );
}

const imageStyle: CSSProperties = {
  maxHeight: "240px",
  maxWidth: "240px",
  borderRadius: "16px",
  cursor: "pointer",
};

function MessageFileItem({ facility, object, latestOwnMessageId }: Props) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const [isNewMessage, setIsNewMessage] = useState(!object.isRead);
  const myMessage = object.sender.id === user.id;

  useChange(latestOwnMessageId, () => {
    setIsNewMessage(!object.isRead);
  });

  const theme = useTheme();
  const available = object.availability === MessageAvailability.Available;
  const { bgColor, textColor } = myMessage
    ? available
      ? { bgColor: "primary.main", textColor: "white" }
      : { bgColor: shade(theme.palette.primary.main, 50), textColor: "white" }
    : available
      ? { bgColor: grey[100], textColor: "text.primary" }
      : { bgColor: grey[600], textColor: "white" };

  let display = null;
  if (object.type === MessageType.Document) {
    display = (() => {
      if (object.availability === MessageAvailability.Available) {
        return (
          <EventBubble color={bgColor}>
            <Media
              gap={0.5}
              image={
                <PictureAsPdfOutlined
                  fontSize="inherit"
                  sx={{ color: textColor }}
                />
              }
            >
              <Link
                href={object.fileUrl || ""}
                sx={{ color: textColor, textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getFilenameFromUrl(object.fileUrl || "")}
              </Link>
            </Media>
          </EventBubble>
        );
      }
      if (object.availability === MessageAvailability.Pending) {
        // Different logic based on sender/recever
        if (myMessage) {
          return (
            <Box style={{ opacity: 0.45 }}>
              <Media
                gap={0.5}
                image={
                  <PictureAsPdfOutlined fontSize="inherit" color="inherit" />
                }
              >
                <Typography variant="body2">
                  {getFilenameFromUrl(object.fileUrl || "")}
                </Typography>
              </Media>
            </Box>
          );
        }
        return (
          <MediaNotAvailable
            message={t("This document is hidden to you while being reviewed")}
            mediaIcon={
              <PictureAsPdfOutlined fontSize="inherit" color="inherit" />
            }
          />
        );
      }
      // It was rejected
      return (
        <MediaNotAvailable
          message={t("This document is hidden because it was rejected")}
          mediaIcon={
            <PictureAsPdfOutlined fontSize="inherit" color="inherit" />
          }
        />
      );
    })();
  } else {
    display = (() => {
      if (object.availability === MessageAvailability.Available) {
        return (
          <ZoomableImage
            src={object.fileUrl || ""}
            messageId={object.id}
            style={imageStyle}
          />
        );
      }
      if (object.availability === MessageAvailability.Pending) {
        // Different logic based on sender/recever
        if (myMessage) {
          return (
            <ZoomableImage
              src={object.fileUrl || ""}
              messageId={object.id}
              style={{ ...imageStyle, opacity: 0.45 }}
            />
          );
        }
        return (
          <MediaNotAvailable
            message={t("This photo is hidden to you while being reviewed")}
          />
        );
      }
      // It was rejected
      return (
        <MediaNotAvailable
          message={t("This photo is hidden because it was rejected")}
        />
      );
    })();
  }

  return (
    <>
      <TimelineEventWrapper
        alignment={myMessage ? "right" : "left"}
        status={myMessage ? "sent" : isNewMessage ? "unread" : undefined}
        timestamp={object.createdAt}
        isAvailable={available}
      >
        {display}
      </TimelineEventWrapper>
      <Box
        sx={{
          display: "flex",
          mx: 2,
          mt: 0.5,
          justifyContent: myMessage ? "flex-end" : "flex-start",
        }}
      >
        <MessageStatus
          myMessage={myMessage}
          facility={facility}
          message={object}
        />
      </Box>
    </>
  );
}

export default function MessageEventItem({
  facility,
  object,
  latestOwnMessageId,
}: Props) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const [isNewMessage, setIsNewMessage] = useState(!object.isRead);
  const myMessage = object.sender.id === user.id;
  const content = useMessageContent(object);

  useChange(latestOwnMessageId, () => {
    setIsNewMessage(!object.isRead);
  });

  const theme = useTheme();
  const available = object.availability === MessageAvailability.Available;
  const { bgColor, textColor } = myMessage
    ? available
      ? { bgColor: "primary.main", textColor: "white" }
      : { bgColor: shade(theme.palette.primary.main, 50), textColor: "white" }
    : available
      ? { bgColor: grey[100], textColor: "text.primary" }
      : { bgColor: grey[600], textColor: "white" };

  if (
    object.type === MessageType.Document ||
    object.type === MessageType.Photo
  ) {
    return (
      <MessageFileItem
        facility={facility}
        object={object}
        latestOwnMessageId={latestOwnMessageId}
      />
    );
  }

  return (
    <>
      <TimelineEventWrapper
        alignment={myMessage ? "right" : "left"}
        status={myMessage ? "sent" : isNewMessage ? "unread" : undefined}
        timestamp={object.createdAt}
        isAvailable={available}
      >
        <EventBubble color={bgColor}>
          <Typography variant="body2" color={textColor}>
            {content}
          </Typography>
        </EventBubble>
      </TimelineEventWrapper>
      <Box
        sx={{
          display: "flex",
          mx: 2,
          justifyContent: myMessage ? "flex-end" : "flex-start",
        }}
      >
        {object.isInstantReply && (
          <Media gap={0.5} image={<Bolt fontSize="inherit" color="disabled" />}>
            <Typography sx={{ color: "text.disabled" }} variant="caption">
              {t("Instant reply")}
            </Typography>
          </Media>
        )}
        <MessageStatus
          myMessage={myMessage}
          facility={facility}
          message={object}
        />
      </Box>
    </>
  );
}
