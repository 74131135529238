import { useEffect, useRef, useState } from "react";

type Props = {
  audioMuted?: boolean;
  stream: MediaStream | undefined;
  volumeThreshold?: number;
  lowThrottle?: number;
  highThrottle?: number;
};

export const TYPICAL_MINIMUM = -75;
export const TYPICAL_MAXIMUM = -30;

// the frequent updates of the volume state causes the component to re-render multiple times per second.
// One way to reduce the number of re-renders is to use a debouncing mechanism to limit how often the volume state is updated.
// This sensitivity value is used to determine how much the volume must change before the state is updated.
const VOLUME_SENSITIVITY_DB = 5;

const MAX_FRAME_RATE = 30;

const getRelativeVolume = (volume: number) =>
  Math.min(
    1,
    Math.max(
      0,
      (volume - TYPICAL_MINIMUM) / (TYPICAL_MAXIMUM - TYPICAL_MINIMUM)
    )
  );

/**
 * useAudioLevel will monitor the given stream and report how loud it is. the
 * rest of the arguments control a variable sampling interval.
 *
 * NOTE: any component using this will re-render multiple times per second,
 * according to the throttling setup.
 *
 * NOTE: volume is currently measured from the loudest frequency in a sound
 * sample. this is a naive implementation and may not represent human speech.
 */
export default function useAudioLevel({
  audioMuted,
  stream,
  volumeThreshold,
  lowThrottle,
  highThrottle,
}: Props) {
  const [volume, setVolume] = useState(TYPICAL_MINIMUM);
  const throttlePeriod = useRef(highThrottle);
  const lastVolume = useRef(TYPICAL_MINIMUM);
  const numAudioTracks = stream?.getAudioTracks().length || 0;

  useEffect(() => {
    if (stream && numAudioTracks > 0) {
      const context = new AudioContext();
      const source = context.createMediaStreamSource(stream);
      const analyzer = context.createAnalyser();
      analyzer.fftSize = 2048;

      source.connect(analyzer);

      const buffer = new Float32Array(analyzer.frequencyBinCount);

      let active = true;
      let lastAnimationUpdate = window.performance.now();

      const animate = () => {
        if (!active || audioMuted) return;

        requestAnimationFrame(animate);

        // Throttle animation
        const now = window.performance.now();
        const elapsed = now - lastAnimationUpdate;

        if (
          lowThrottle &&
          highThrottle &&
          elapsed < (throttlePeriod?.current || 0)
        )
          return;
        if (elapsed < 1000 / MAX_FRAME_RATE) return;

        lastAnimationUpdate = now;

        analyzer.getFloatFrequencyData(buffer);

        // Estimate volume by max frequency bin. (note:
        // this will mark white noise as quiet
        // and sine waves as very loud even at the same sound energy)
        // In future we might update this to do something fancier
        // to pick out frequencies close to human speech and measure those.
        const newVolume = buffer.reduce((a, b) => Math.max(a, b));

        // Update the throttle period if the threshold has been reached
        if (volumeThreshold) {
          const relativeVolume = getRelativeVolume(newVolume);
          throttlePeriod.current =
            volumeThreshold && relativeVolume > volumeThreshold
              ? highThrottle // Measure less frequently
              : lowThrottle; // Measure more frequently;
        }

        // Update the volume if it has changed significantly
        if (Math.abs(newVolume - lastVolume.current) > VOLUME_SENSITIVITY_DB) {
          lastVolume.current = newVolume;
          setVolume(newVolume);
        }
      };

      animate();

      return () => {
        setVolume(TYPICAL_MINIMUM);
        active = false;
        analyzer.disconnect();
        source.disconnect();
        context.close();
      };
    }
    return () => {};
  }, [
    audioMuted,
    stream,
    numAudioTracks,
    throttlePeriod,
    lowThrottle,
    highThrottle,
    volumeThreshold,
  ]);

  return !audioMuted && stream ? getRelativeVolume(volume) : 0;
}
