import { TextInput } from "@ameelio/ui";
import { Stack } from "@mui/material";
import { sub } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateInput from "../../lib/DateInput";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { isDateOfBirth, isRequired, MAX_USER_AGE } from "../../lib/validate";
import ScreenHeader from "./ScreenHeader";

export type ConfirmStepData = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
};

type Props = {
  defaultValues: ConfirmStepData;
  stepper: StepperType;
  onNext: (data: ConfirmStepData) => void;
};

export default function ConfirmDataStep({
  defaultValues,
  stepper,
  onNext,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<ConfirmStepData>({ defaultValues, mode: "onTouched" });

  return (
    <StepperFormWrapper
      stepper={stepper({ loading: isSubmitting, disabled: !isValid })}
      handleSubmit={handleSubmit(onNext)}
    >
      <ScreenHeader
        title={t("Does everything look good?")}
        description={t(
          "Your name and date of birth must match the ID that you provided. If it doesn’t, ID approval will be delayed."
        )}
      />
      <Stack mt={6} spacing={4} mb={11}>
        <TextInput
          name="firstName"
          label={t("First name")}
          control={control}
          rules={isRequired(t("Please enter your first name."))}
        />
        <TextInput name="lastName" label={t("Last name")} control={control} />
        <DateInput
          control={control}
          name="dateOfBirth"
          label={t("Date of birth")}
          disableFuture
          disableHighlightToday
          minDate={sub(new Date(), { years: MAX_USER_AGE, days: 364 })}
          rules={isDateOfBirth()}
        />
      </Stack>
    </StepperFormWrapper>
  );
}
