import { ConnectionStateQuality } from "@ameelio/connect-call-client";
import { Avatar } from "@ameelio/ui";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { Box, Icon, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import AudioIndicatingAvatar from "../AudioIndicatingAvatar";
import ParticipantPlaceholder from "../ParticipantPlaceholder";
import { colorLUT } from "./ConnectionStrength";

type AvatarProps = {
  user: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  stream: MediaStream | undefined;
  sx?: SxProps<Theme>;
};

export function AvatarVideoPlaceholder({
  user,
}: {
  user: { firstName: string; lastName: string; fullName: string };
}) {
  return (
    <ParticipantPlaceholder>
      <Avatar
        contact={user}
        sx={{
          width: 100,
          height: 100,
          boxShadow: `0px #fff`,
        }}
      />
    </ParticipantPlaceholder>
  );
}

export function AvatarVideoPlaceholderWithAudio({
  user,
  stream,
  sx,
}: AvatarProps) {
  return (
    <ParticipantPlaceholder styleOverrides={sx}>
      <AudioIndicatingAvatar user={user} stream={stream} />
    </ParticipantPlaceholder>
  );
}

export function TextVideoPlaceholder({ message }: { message: string }) {
  return (
    <ParticipantPlaceholder>
      <Typography variant="body2">{message}</Typography>
    </ParticipantPlaceholder>
  );
}

export function FruxVideoPlaceholder({
  message,
  quality = ConnectionStateQuality.unknown,
}: {
  message: string;
  quality?: ConnectionStateQuality;
}) {
  return (
    <ParticipantPlaceholder>
      <Box>
        <Icon
          sx={{
            color: colorLUT[quality],
          }}
        >
          <SignalCellularAltIcon />
        </Icon>
      </Box>
      <Box>
        <Typography variant="body2">{message}</Typography>
      </Box>
    </ParticipantPlaceholder>
  );
}
