import { Button, ButtonProps } from "@ameelio/ui";
import React from "react";

type VariantButtonProps = Omit<ButtonProps, "variant"> & {
  children: React.ReactNode;
};

export function SecondaryButton({ children, ...props }: VariantButtonProps) {
  return (
    <Button variant="outlined" {...props}>
      {children}
    </Button>
  );
}

export function PrimaryButton({ children, ...props }: VariantButtonProps) {
  return (
    <Button variant="contained" {...props}>
      {children}
    </Button>
  );
}
