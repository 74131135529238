import { formatTimer } from "./timeFormats";
import useTimer from "./useTimer";

export type Props = {
  startAt: number | null;
  endAt: number | null;
  countDownAt?: number;
};

export function formatTimeLeft({
  elapsed,
  remaining,
  countDownAt,
}: {
  elapsed: number;
  remaining: number;
  countDownAt: number;
}) {
  return formatTimer(remaining < countDownAt ? remaining : elapsed);
}

export default function useTimeLeft({
  startAt,
  endAt,
  countDownAt = 0,
}: Props) {
  const { elapsed, remaining } = useTimer({
    startAt: startAt || 0,
    endAt: endAt || 0,
  });

  if (!startAt || !endAt) return "unknown";

  return formatTimeLeft({ elapsed, remaining, countDownAt });
}
