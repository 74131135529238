import { IconButton } from "@ameelio/ui";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import blurIcon from "../../images/BlurIcon.svg";
import customBackgroundUrls from "../../images/customBackgroundUrls";
import CustomBackground from "./types/CustomBackground";

type Props = {
  value: CustomBackground;
  onChange: (b: CustomBackground) => void;
  onClose: () => void;
  canClearCustomBackground: boolean;
  canBlurBackground: boolean;
};

type SelectableImageProps = {
  onClick: () => void;
  children: React.ReactNode;
  selected: boolean;
};

function SelectableImage({
  children,
  onClick,
  selected,
}: SelectableImageProps) {
  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: "12px",
        aspectRatio: "1.4",
        border: (theme) =>
          selected ? `4px solid ${theme.palette.primary.main}` : "none",
        backgroundColor: "black",
        color: "white",
        cursor: "pointer",
      }}
      alignItems="center"
      justifyContent="center"
      display="flex"
      onClick={() => onClick()}
    >
      {children}
    </Box>
  );
}

type SelectableImageWithFallbackProps = {
  large: string;
  small: string;
  alt: string;
  selected: boolean;
  onChange: (b: CustomBackground) => void;
};

function SelectableImageWithFallback({
  large,
  small,
  alt,
  selected,
  onChange,
}: SelectableImageWithFallbackProps) {
  // We will provide a low-res fallback image that is
  // already loaded by the time the button is clicked.
  // The most convenient way to do this is to use
  // the thumbnail itself.
  const image = useRef<HTMLImageElement>(null);

  return (
    <SelectableImage
      onClick={() =>
        onChange(new CustomBackground("url", large, image.current ?? undefined))
      }
      selected={selected}
    >
      <img
        style={{
          height: "100%",
          objectFit: "cover",
        }}
        ref={image}
        crossOrigin="anonymous"
        src={small}
        alt={alt}
      />
    </SelectableImage>
  );
}
export default function CallBackgroundSelector({
  value,
  onChange,
  onClose,
  canClearCustomBackground,
  canBlurBackground,
}: Props) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <Box sx={{ display: "flex", padding: 2 }}>
        <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
          {t("Background")}
        </Typography>
        <IconButton size="small" onClick={onClose} ariaLabel={t("Close")}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={1.5}>
          {canClearCustomBackground && (
            <Grid item xs={6}>
              <SelectableImage
                onClick={() => onChange(CustomBackground.NONE)}
                selected={value.kind === "none"}
              >
                {t("None")}
              </SelectableImage>
            </Grid>
          )}

          {canBlurBackground && (
            <Grid item xs={6}>
              <SelectableImage
                onClick={() => onChange(CustomBackground.BLUR)}
                selected={value.kind === "blur"}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: `url(${blurIcon})`,
                    backgroundSize: "cover",
                  }}
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                >
                  {t("Blur")}
                </Box>
              </SelectableImage>
            </Grid>
          )}

          {customBackgroundUrls.map(({ large, small, alt }) => (
            <Grid item xs={6} key={large}>
              <SelectableImageWithFallback
                large={large}
                small={small}
                alt={alt}
                selected={value.kind === "url" && value.url === large}
                onChange={onChange}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
