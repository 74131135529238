import { Tooltip } from "@ameelio/ui";
import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import addStylesForDevices from "./addStylesForDevices";
import { belowLargeTablet } from "./responsiveHelpers";

type LinkButton = {
  label: string;
  to: string;
  state?: unknown;
};
type ClickButton = { label: string; onClick: () => void };

type Props = {
  id: string;
  buttons: (LinkButton | ClickButton)[];
  disabled?: boolean;
  tooltipTitle?: string;
  sx?: SxProps<Theme>;
};

function ButtonItem({
  sx,
  button,
}: {
  sx?: SxProps<Theme>;
  button: LinkButton | ClickButton;
}) {
  if ("to" in button) {
    return (
      <Button component={RouterLink} to={button.to} sx={sx}>
        {button.label}
      </Button>
    );
  }
  return (
    <Button onClick={button.onClick} sx={sx}>
      {button.label}
    </Button>
  );
}

export default function SplitButton({
  id,
  buttons,
  disabled,
  tooltipTitle,
  sx,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  const isOpen = !!anchorEl;
  const handleOpen = (e: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // when our buttons are rendered as links with an <a> tag, they
  // do not match other <button> tags for MUI's nth-of-type CSS
  // selectors. this may be fixed with newer versions of MUI. in
  // the meantime, we can specify the missing styles.
  // NOTE: these are specific to variant=outlined.
  const firstButtonSx: SxProps<Theme> =
    buttons.length > 1
      ? {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRightColor: "transparent",
          "&:hover": {
            borderRightColor: "currentColor",
          },
          ...addStylesForDevices(isMobileOrSmallTablet, {}, { width: 1 }),
        }
      : addStylesForDevices(isMobileOrSmallTablet, {}, { width: 1 });
  const lastButtonSx: SxProps<Theme> = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: "-1px",
  };
  // The tooltip is only intended to explain why the button group is disabled
  const title = disabled && tooltipTitle ? tooltipTitle : "";
  const buttonId = `${id}-button`;

  return (
    <ButtonGroup
      variant="contained"
      disableElevation
      disabled={disabled}
      sx={sx}
    >
      <Tooltip title={title}>
        <span style={{ width: "100%" }}>
          <Box display="flex" flex={1}>
            <ButtonItem button={buttons[0]} sx={firstButtonSx} />
            {buttons.length > 1 && (
              <>
                <Button
                  size="small"
                  aria-controls={isOpen ? "split-button-menu" : undefined}
                  aria-expanded={isOpen ? "true" : undefined}
                  aria-haspopup="menu"
                  aria-label="more options"
                  onClick={isOpen ? handleClose : handleOpen}
                  sx={lastButtonSx}
                >
                  <ArrowDropDown />
                </Button>
                <Menu
                  MenuListProps={{ "aria-labelledby": buttonId }}
                  anchorEl={anchorEl}
                  open={isOpen}
                  onClick={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {buttons.slice(1).map((b) =>
                    "to" in b ? (
                      <MenuItem key={b.label} component={RouterLink} to={b.to}>
                        {b.label}
                      </MenuItem>
                    ) : (
                      <MenuItem key={b.label} onClick={b.onClick}>
                        {b.label}
                      </MenuItem>
                    )
                  )}
                </Menu>
              </>
            )}
          </Box>
        </span>
      </Tooltip>
    </ButtonGroup>
  );
}
