import { useLazyQuery, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { ConnectionStatus } from "../api/graphql";
import useApolloErrorHandler from "../lib/handleApolloError";
import { useServerEvent } from "../lib/serverEventsProvider";
import { GetConnectionActivityUpdateDocument } from "./GetConnectionActivityUpdate.generated";
import { GetConnectionsListDocument } from "./GetConnectionsList.generated";
import { useMessageBadges } from "./MessageBadgeProvider";

export default function useConnectionsList() {
  const badges = useMessageBadges();

  // fetch and refresh contacts list on navigation
  const onError = useApolloErrorHandler();
  const { data } = useQuery(GetConnectionsListDocument, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  // grab updates when notified
  const [updateConnectionActivity] = useLazyQuery(
    GetConnectionActivityUpdateDocument,
    { fetchPolicy: "network-only", onError }
  );
  useServerEvent("updateConnectionTimeline", ({ id }) => {
    updateConnectionActivity({
      variables: { connectionId: id },
    });
  });

  return useMemo(
    () =>
      data?.currentCorrespondent?.connections
        .filter((c) => c.status === ConnectionStatus.Active)
        .sort(
          (a, b) =>
            b.lastMessageCenterActivityAt - a.lastMessageCenterActivityAt
        )
        .map((c) => ({
          ...c,
          messages: {
            unreadMessageCount: badges.unread(c.id),
          },
        })),
    [data, badges]
  );
}
