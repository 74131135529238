import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../api/MeetingFragment.generated';
import { CorrespondentFragmentFragmentDoc } from '../api/CorrespondentFragment.generated';
export type GetVoiceCallInfoQueryVariables = Types.Exact<{
  meetingId: Types.Scalars['ID'];
}>;


export type GetVoiceCallInfoQuery = { __typename: 'Query', meeting: { __typename: 'Meeting', id: string, title: string | null, meetingType: Types.MeetingType, status: Types.MeetingStatus, statusDetails: string | null, privacyLevel: Types.PrivacyLevel, contacts: Array<{ __typename: 'Inmate', id: string, firstName: string, lastName: string, fullName: string } | { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string }>, interval: { __typename: 'Interval', startAt: number, endAt: number, seconds: number | null } } };


export const GetVoiceCallInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVoiceCallInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"contacts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CorrespondentFragment"}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions,...CorrespondentFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetVoiceCallInfoQuery, GetVoiceCallInfoQueryVariables>;