import { useEffect, useRef, useState } from "react";

export default function useTimer(
  baseInterval: {
    startAt: number;
    endAt: number;
  } | null
) {
  const startAt = baseInterval?.startAt;
  const endAt = baseInterval?.endAt;

  const [elapsed, setElapsed] = useState(0);
  const [remaining, setRemaining] = useState(
    baseInterval ? baseInterval.endAt - baseInterval.startAt : Infinity
  );

  const initialStart = useRef(Date.now());

  useEffect(() => {
    // Otherwise, count down
    const interval = setInterval(() => {
      const now = Date.now();
      setElapsed(Math.max(now - (startAt ?? initialStart.current) + 1000, 0));
      setRemaining(Math.max((endAt ?? Infinity) - now, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [startAt, endAt, setElapsed, setRemaining]);

  return { elapsed, remaining };
}
