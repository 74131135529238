import { useEffect, useState } from "react";
import useMediaAcquisitionError from "./alertMediaAcquisitionError";

export default function useLocalVideo() {
  const alertMediaAcquisitionError = useMediaAcquisitionError();
  const [userMedia, setUserMedia] = useState<MediaStream | undefined>(
    undefined
  );
  useEffect(() => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        setUserMedia(stream);
      } catch (e) {
        alertMediaAcquisitionError(e, "camera");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      userMedia?.getTracks().forEach((track) => {
        track.stop();
      });
    },
    [userMedia]
  );
  return userMedia;
}
