import React, { createContext, useContext, useMemo, useState } from "react";
/**
 * The tracking of dropped streams uses bitwise operations. https://www.w3schools.com/js/js_bitwise.asp
 * We leverage the effect of redundant bitwise ORs (|) to track all 4 states,
 * even though we never explicity set "localAndPeer". This eliminates the need for
 * conditional checks on each update, and provides explicit values for each state.
 * Setting local and peer repeatedly throughout a session always produces "localAndPeer".
 * e.g.: 0x00 | 0x01 | 0x02 | 0x01 | 0x01 | 0x02 | 0x02 | 0x02 == 0x03
 * usage: setDroppedStreams(droppedStreams | DroppedStreams["local" ...or "peer"]);
 */
const STREAM_DROP_TYPES = ["none", "local", "peer", "localAndPeer"] as const;

type StreamDropType = (typeof STREAM_DROP_TYPES)[number];

export const DroppedStreams: Record<StreamDropType, number> = {
  [STREAM_DROP_TYPES[0]]: 0x00,
  [STREAM_DROP_TYPES[1]]: 0x01,
  [STREAM_DROP_TYPES[2]]: 0x02,
  [STREAM_DROP_TYPES[3]]: 0x03,
};

type DroppedStreamType = (typeof DroppedStreams)[StreamDropType];

type StreamTrackerContextType = {
  droppedStreamsType: number;
  getStreamDropType: (value: DroppedStreamType) => StreamDropType;
  handleDrop: (value: DroppedStreamType) => void;
};

/**
 * Creates the StreamTrackerContext
 */
const StreamTrackerContext = createContext<StreamTrackerContextType>(
  {} as StreamTrackerContextType
);

/**
 * Creates a new StreamTrackerContext instance
 */
export function useStreamTrackerContext() {
  return useContext(StreamTrackerContext);
}

export default function StreamTrackerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [droppedStreamsType, setDroppedStreamsType] =
    useState<DroppedStreamType>(DroppedStreams.none);
  const streamTrackerContext = useMemo(
    (): StreamTrackerContextType => ({
      droppedStreamsType,
      getStreamDropType: (value: DroppedStreamType): StreamDropType =>
        STREAM_DROP_TYPES.find((key) => DroppedStreams[key] === value) ||
        "none",
      handleDrop: (value: DroppedStreamType): void =>
        setDroppedStreamsType(droppedStreamsType | value), // eslint-disable-line no-bitwise
    }),
    [droppedStreamsType]
  );

  return (
    <StreamTrackerContext.Provider value={streamTrackerContext}>
      {children}
    </StreamTrackerContext.Provider>
  );
}
