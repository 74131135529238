import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationMembershipFragmentFragmentDoc } from '../api/OrganizationMembershipFragment.generated';
import { VisitorFragmentFragmentDoc } from '../api/VisitorFragment.generated';
import { ConnectionFragmentFragmentDoc } from '../api/ConnectionFragment.generated';
import { InmateFragmentFragmentDoc } from '../api/InmateFragment.generated';
export type GetColleagueMembershipsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type GetColleagueMembershipsQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, memberships: Array<{ __typename: 'OrganizationMembership', id: string, role: Types.OrganizationMembershipRole, status: Types.OrganizationMembershipStatus, visitor: { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, email: string, connections: Array<{ __typename: 'Connection', id: string, status: Types.ConnectionStatus, relationship: string, confidential: boolean, createdAt: number, inmate: { __typename: 'Inmate', id: string, inmateIdentification: string, firstName: string, lastName: string, fullName: string } }> } }> } };


export const GetColleagueMembershipsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetColleagueMemberships"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"memberships"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationMembershipFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"inmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InmateFragment"}}]}}]}}]}}]}}]}}]}},...OrganizationMembershipFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions,...ConnectionFragmentFragmentDoc.definitions,...InmateFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetColleagueMembershipsQuery, GetColleagueMembershipsQueryVariables>;