import React from "react";
import NotFoundScreen from "./404";

export default function EnvBoundary({
  children,
  environment,
}: {
  children: JSX.Element;
  environment: "development" | "staging" | "production";
}) {
  if (import.meta.env.VITE_APP_ENV !== environment) return <NotFoundScreen />;
  return children;
}
