import {
  Button,
  SelectInputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextInput,
  TextInputBase,
  useSnackbarContext,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useApolloErrorHandler from "../../lib/handleApolloError";
import Screen from "../../lib/Screen";
import { isRequired } from "../../lib/validate";
import { GetCallInfoDocument } from "./GetCallInfo.generated";

type FormData = {
  meetingId: string;
};

type LogEntry = {
  timestamp: string;
  level: string;
  message: string;
  details?: string;
  socketId?: string;
  eventName?: string;
  userType?: string;
};

enum UserFilterTypes {
  DOC = "doc",
  USER = "user",
  INMATE = "inmate",
  ALL = "all",
}

const downloadFile = (url: string) => {
  const downloadLink = document.createElement("a");
  downloadLink.target = "_blank";
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const readFile = async (url: string) => {
  const response = await fetch(url);
  const text = await response.text();
  return text;
};

const parseLog = (log: string): LogEntry[] => {
  const logEntries: LogEntry[] = [];
  const logLines = log.split("\n");
  const logRegex =
    // eslint-disable-next-line no-control-regex
    /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z) \[\u001b\[\d{2}m(\w+)\u001b\[\d{2}m\] (\[.*?\])?(.*)$/;

  const socketIdToUserTypeMap: Record<string, string> = {};

  logLines.forEach((line) => {
    const match = logRegex.exec(line);
    if (match) {
      const [, timestamp, level, socketId, message] = match;
      const eventNameMatch = message?.match(/[><]\[(\w+)\]/);
      const eventName = eventNameMatch ? eventNameMatch[1] : undefined;
      const cleanSocketId = socketId ? socketId.slice(1, -1) : undefined;
      const userTypeMatch = message?.match(
        /(?:type|userType)=\s*(doc|user|inmate)/
      );
      const userType = userTypeMatch ? userTypeMatch[1] : undefined;
      // If we find the userType in the message, we store it in the map with its socketId
      if (cleanSocketId && userType && !socketIdToUserTypeMap[cleanSocketId]) {
        socketIdToUserTypeMap[cleanSocketId] = userType;
      }

      logEntries.push({
        timestamp,
        level,
        message,
        socketId: cleanSocketId,
        eventName,
        userType,
      });
    }
  });

  return logEntries.map((entry) => ({
    ...entry,
    userType: entry.socketId
      ? socketIdToUserTypeMap[entry.socketId]
      : entry.userType || "",
  }));
};

const sanitizeIndexKey = (key: string) =>
  key.replace(/[^a-zA-Z0-9]/g, "_").substring(0, 40);

// TODO: add link to grafana
//

const inferRowColor = (entry: LogEntry) => {
  if (entry.level === "error") {
    return "error.main";
  }
  if (entry.level === "warn") {
    return "orange";
  }
  return "text.primary";
};

export default function DebugCallsScreen() {
  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();
  const [logData, setLogData] = useState<string | null>(null);
  const [parsedLogData, setParsedLogData] = useState<LogEntry[]>([]);
  const [filteredLogData, setFilteredLogData] = useState<LogEntry[]>([]);
  const [selectedMeetingId, setSelectedMeetingId] = useState<string>("");
  const [activeTab, setActiveTab] = useState<"raw" | "parsed">("parsed");
  const [textFilter, setTextFilter] = useState<string>("");
  const [userFilter, setUserFilter] = useState<UserFilterTypes>(
    UserFilterTypes.ALL
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: "onTouched",
  });

  const { data: callInfoData, loading } = useQuery(GetCallInfoDocument, {
    variables: { meetingId: selectedMeetingId },
    skip: !selectedMeetingId || !isValid,
    onError: handleApolloError,
  });

  useEffect(() => {
    if (!callInfoData) return;
    const url = callInfoData?.meeting.call?.callLogUrl;
    if (url) {
      setLogData(null);
      setParsedLogData([]);
      readFile(url).then((logs) => {
        setLogData(logs);
        setParsedLogData(parseLog(logs));
      });
    } else {
      snackbarContext.alert("error", "No logs found for this meeting");
    }
  }, [callInfoData, snackbarContext]);

  const callLogUrl = callInfoData?.meeting.call?.callLogUrl;

  useEffect(() => {
    const userFilteredData =
      userFilter === UserFilterTypes.ALL
        ? parsedLogData
        : parsedLogData.filter((entry) => entry.userType === userFilter);
    if (!textFilter) {
      setFilteredLogData(userFilteredData);
      return;
    }
    const filteredData = userFilteredData.filter(
      (entry) =>
        entry.eventName?.toLowerCase().includes(textFilter.toLowerCase())
    );
    setFilteredLogData(filteredData);
  }, [parsedLogData, textFilter, userFilter]);

  return (
    <Screen title="Debug calls" maxWidth="xl">
      <Stack spacing={3}>
        <TextInput
          control={control}
          name="meetingId"
          label="Meeting Id"
          rules={isRequired("Please provide a valid meeting id")}
        />
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={2}
        >
          <Button
            variant="contained"
            disabled={isSubmitting || loading}
            onClick={handleSubmit(({ meetingId: mId }) =>
              setSelectedMeetingId(mId)
            )}
          >
            View Logs
          </Button>
          {callLogUrl && (
            <Button variant="outlined" onClick={() => downloadFile(callLogUrl)}>
              Download Logs
            </Button>
          )}
        </Stack>
      </Stack>
      {logData && filteredLogData.length > 0 ? (
        <>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            aria-label="Debug tabs"
            sx={{ my: 2 }}
          >
            <Tab label="Parsed log" value="parsed" />
            <Tab label="Raw log" value="raw" />
          </Tabs>
          {activeTab === "raw" && (
            <Box mt={4}>
              <Box
                component="pre"
                p={2}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: 1,
                  overflow: "auto",
                  maxHeight: 550,
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                {logData}
              </Box>
            </Box>
          )}
          {activeTab === "parsed" && (
            <>
              <Box mb={2}>
                <Stack direction="row" spacing={2}>
                  <TextInputBase
                    size="small"
                    sx={{ maxWidth: "250px", width: "100%" }}
                    autoComplete="off"
                    value={textFilter}
                    label="Event name"
                    onChange={(e) => {
                      setTextFilter(e.target.value);
                    }}
                    addClearIcon
                    onClear={() => {
                      setTextFilter("");
                    }}
                  />
                  <SelectInputBase
                    size="small"
                    sx={{ maxWidth: "250px", width: "100%" }}
                    onChange={(e) => {
                      setUserFilter(e.target.value as UserFilterTypes);
                    }}
                    value={userFilter}
                    items={Object.values(UserFilterTypes).map((r) => ({
                      name: r,
                      value: r,
                    }))}
                    label="User type"
                  />
                </Stack>
              </Box>
              <Box mt={4} maxHeight={550} overflow="auto">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>User type</TableCell>
                        <TableCell>Socket ID</TableCell>
                        <TableCell>Event Name</TableCell>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLogData.map((entry, index) => (
                        <TableRow
                          key={sanitizeIndexKey(
                            entry.timestamp + index + entry.message
                          )}
                        >
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.timestamp}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.userType}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.socketId}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.eventName}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.message}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </>
      ) : null}
    </Screen>
  );
}
