import { useSnackbarContext } from "@ameelio/ui";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { track } from "../../analytics";
import {
  ConnectionFeature,
  MeetingStatus,
  MeetingType,
} from "../../api/graphql";
import FullscreenFlow from "../../FullscreenFlow";
import buildPageTitle from "../../lib/buildPageTitle";
import ResetScroll from "../../lib/ResetScroll";
import StepperWrapper from "../../lib/StepperWrapper";
import useOnLoad from "../../lib/useOnLoad";
import { currentMembership, useCurrentUser } from "../../SessionBoundary";
import ConfirmStep from "./ConfirmStep";
import OrganizationAddGuestListStep from "./GuestList/OrganizationAddGuestListStep";
import PersonalAddGuestListStep from "./GuestList/PersonalAddGuestListStep";
import SelectConnectionStep from "./SelectConnectionStep";
import SelectEventTypeStep from "./SelectEventTypeStep";
import SelectTimeStep from "./SelectTimeStep";
import SelectWebinarStep from "./SelectWebinarStep";
import SelectWebinarTimeStep from "./SelectWebinarTimeStep";
import { InmateConnection, MeetingData, WebinarData } from "./types";
import useWorkflowSteps, { Step, WorkflowAction } from "./useWorkflowSteps";
import WebinarDetailsStep from "./WebinarDetailsStep";

export type { WorkflowAction };

type Props = {
  backTo: string;
  parentName: string;
  defaultValues: MeetingData | WebinarData;
  connections: InmateConnection[];
  action: WorkflowAction;
  editMeetingId?: string;
};

export default function SchedulingFlow({
  backTo,
  parentName,
  defaultValues,
  connections,
  editMeetingId,
  action,
}: Props) {
  const { t } = useTranslation();
  const isEdit = action === "edit";
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const snackbarContext = useSnackbarContext();
  const membership = currentMembership(currentUser);

  useOnLoad(() => {
    track("Meeting request - Started", {
      action: action === "edit" ? "update" : "create",
    });
  });

  const [data, setData] = useState<WebinarData | MeetingData>(defaultValues);

  // workflow control
  const steps = useWorkflowSteps({
    isWebinar: data.meetingType === MeetingType.Webinar,
    stepsToRemove: isEdit ? ["selectEventType"] : undefined,
  });
  const [step, setStep] = useState<Step>(
    isEdit && !membership // F&F visitor editing an event
      ? "addGuests"
      : // Provider editing an event, or a duplication
        (isEdit && membership) || action === "duplicate"
        ? "selectWhen"
        : data.meetingType !== MeetingType.Webinar &&
            data.connections &&
            data.connections[0]
          ? "selectEventType"
          : steps[0]
  );
  const stepBack = useCallback(() => {
    const i = steps.indexOf(step);
    if (i === 0) navigate(backTo);
    else setStep(steps[i - 1]);
  }, [steps, step, backTo, navigate]);
  const stepForward = useCallback(() => {
    const i = steps.indexOf(step);
    setStep(steps[i + 1]);
  }, [steps, step]);

  const [needsApproval, setNeedsApproval] = useState<boolean>(false);
  if (step === "finished") {
    const facility = data.connections && data.connections[0].inmate.facility;
    const message =
      needsApproval && data.meetingType !== MeetingType.Webinar
        ? t(
            "Your event is being reviewed by {{facilityName}}. You’ll receive an email once they review your request.",
            {
              facilityName: `${facility?.name} (${facility?.publicId})`,
            }
          )
        : isEdit
          ? t("Your event has been modified.")
          : t("Your event has been scheduled.");
    snackbarContext.alert("success", message);
    navigate("/");
  }

  const headerTitle = isEdit ? t("Modify event") : t("Schedule event");
  const pageTitle = buildPageTitle(headerTitle);

  // Used for determining if the meeting will be confidential
  const willBeConfidential =
    (data.meetingType === MeetingType.VideoCall &&
      data.connections?.every(
        (c) =>
          c.features?.includes(ConnectionFeature.ConfidentialVideoCall) ||
          // The check for === CISCO is for cisco demo purposes;
          // in the long term we will have other systems
          // for determining where cisco gets used
          c.inmate.facility.publicId === "CISCO"
      )) ||
    (data.meetingType === MeetingType.InPersonVisit &&
      data.connections?.every(
        (c) => c.features?.includes(ConnectionFeature.ConfidentialInPersonVisit)
      ));

  return (
    <FullscreenFlow backTo={backTo} breadcrumbs={[parentName, headerTitle]}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ResetScroll key={step} />

      {step === "selectEventType" &&
        (data.meetingType === MeetingType.Webinar ? (
          <SelectWebinarStep
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={stepForward}
          />
        ) : (
          <SelectEventTypeStep
            defaultValues={data}
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(meetingType) => {
              // only reset state when necessary
              if (meetingType !== data.meetingType)
                setData({
                  ...data,
                  meetingType,
                  availability: undefined,
                });
              stepForward();
            }}
            willBeConfidential={willBeConfidential}
          />
        ))}

      {step === "selectWho" &&
        (data.meetingType === MeetingType.Webinar ? (
          <WebinarDetailsStep
            connections={connections}
            defaultValues={data}
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(stepData) => {
              setData({ ...data, ...stepData });
              stepForward();
            }}
          />
        ) : (
          <SelectConnectionStep
            connections={connections}
            defaultValues={data}
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(stepData) => {
              setData({ ...data, ...stepData });
              stepForward();
            }}
          />
        ))}
      {/* Organization admins/members */}
      {step === "addGuests" &&
        membership &&
        data.connections &&
        data.meetingType !== MeetingType.Webinar && (
          <OrganizationAddGuestListStep
            action={action}
            defaultValues={data}
            inmate={data.connections[0].inmate}
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(stepData) => {
              setData({ ...data, ...stepData });
              stepForward();
            }}
          />
        )}
      {/* Personal visitor */}
      {step === "addGuests" &&
        !membership &&
        data.connections &&
        data.meetingType !== MeetingType.Webinar && (
          <PersonalAddGuestListStep
            action={action}
            defaultValues={data}
            inmate={data.connections[0].inmate}
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(stepData) => {
              setData({ ...data, ...stepData });
              stepForward();
            }}
          />
        )}

      {step === "selectWhen" &&
        data.connections &&
        (data.meetingType === MeetingType.Webinar ? (
          <SelectWebinarTimeStep
            title={data.title || ""}
            defaultValues={data}
            facilityId={data.connections[0].inmate.facility?.id || ""}
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(stepData) => {
              setData({ ...data, ...stepData });
              stepForward();
            }}
          />
        ) : (
          <SelectTimeStep
            editMeetingId={editMeetingId}
            action={action}
            defaultValues={data}
            connection={
              // Must be the scheduler connection, so filter out registered guest
              // connections
              data.connections.filter((c) => c.visitor.id === currentUser.id)[0]
            }
            stepper={({ disabled }) =>
              StepperWrapper({
                onBack: stepBack,
                buttonType: "next",
                disabled,
                loading: false,
                steps,
                step,
              })
            }
            onSubmit={(stepData) => {
              setData({ ...data, ...stepData });
              stepForward();
            }}
          />
        ))}

      {step === "confirm" && data.availability && data.connections && (
        <ConfirmStep
          meetingId={editMeetingId}
          stepper={({ loading = false }) =>
            StepperWrapper({
              onBack: stepBack,
              buttonType: "finish",
              disabled: false,
              loading,
              steps,
              step,
            })
          }
          onEdit={(newStep: Step) => setStep(newStep)}
          onSubmit={(meetingStatus) => {
            setNeedsApproval(meetingStatus === MeetingStatus.PendingApproval);
            stepForward();
          }}
          title={"title" in data ? data.title : ""}
          meetingType={data.meetingType}
          connections={data.connections}
          kiosk={"kiosk" in data ? data.kiosk : undefined}
          registeredGuests={
            "registeredGuests" in data ? data.registeredGuests : undefined
          }
          unregisteredGuests={
            "unregisteredGuests" in data ? data.unregisteredGuests : undefined
          }
          availability={data.availability}
          willBeConfidential={willBeConfidential}
        />
      )}
    </FullscreenFlow>
  );
}
