import { Dialog, DialogActions } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { EndVoiceCallDocument } from "../Call/EndVoiceCall.generated";
import { useServerEvent } from "../lib/serverEventsProvider";
import { useCurrentCorrespondent, useCurrentUser } from "../SessionBoundary";
import { GetVoiceCallInfoDocument } from "./GetVoiceCallInfo.generated";

function AuthenticatedVoiceCallListener() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalShown, setModalShown] = useState(false);
  const [latestMeetingId, setLatestMeetingId] = useState<string>();

  const { data } = useQuery(GetVoiceCallInfoDocument, {
    variables: {
      meetingId: latestMeetingId || "",
    },
    skip: !latestMeetingId,
  });

  const [decline] = useMutation(EndVoiceCallDocument, {
    variables: {
      input: {
        meetingId: latestMeetingId || "",
      },
    },
  });

  useServerEvent("newVoiceCall", ({ id }) => {
    setLatestMeetingId(id);
    setModalShown(true);
  });

  const currentCorrespondent = useCurrentCorrespondent();

  const contact = data?.meeting?.contacts
    ? data.meeting.contacts.filter(
        (x) =>
          !(
            x.id === currentCorrespondent.id &&
            x.__typename === currentCorrespondent.__typename
          )
      )[0]
    : null;

  if (
    modalShown &&
    latestMeetingId &&
    data &&
    data.meeting.id === latestMeetingId &&
    contact
  ) {
    return (
      <Dialog
        title={t("Incoming voice call from {{name}}", {
          name: contact.fullName,
        })}
        onClose={() => {
          decline();
          setModalShown(false);
        }}
      >
        <DialogActions>
          <Button
            onClick={() => {
              decline();
              setModalShown(false);
            }}
          >
            {t("Decline")}
          </Button>
          <Button onClick={() => navigate(`/voice-call/${latestMeetingId}`)}>
            {t("Answer")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
}

export default function VoiceCallListener() {
  const { isLoggedIn } = useAuthContext();
  const user = useCurrentUser();
  return isLoggedIn && user.__typename === "Visitor" ? (
    <AuthenticatedVoiceCallListener />
  ) : null;
}
