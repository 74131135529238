import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { browserToI18n } from "./Language";
import getLanguageDirection from "./languageDir";

export default function useBrowserLanguage() {
  const { i18n } = useTranslation();
  const browserLanguage = browserToI18n(navigator.language);

  const adoptBrowserLanguage = useCallback(() => {
    if (i18n.language !== browserLanguage) {
      i18n.changeLanguage(browserLanguage);
      i18n.dir(getLanguageDirection(browserLanguage));
      document.documentElement.lang = browserLanguage;
    }
  }, [i18n, browserLanguage]);

  return { adoptBrowserLanguage };
}
