import React from "react";
import { PrivacyLevel } from "../../api/graphql";
import retryImport from "../../lib/retryImport";
import { useCurrentCorrespondent } from "../../SessionBoundary";
import VoiceCallInterface from "./VoiceCallInterface";

const TwilioCallConnection = React.lazy(() =>
  retryImport(() => import("./TwilioCallConnection"))
);

type Props = {
  call: {
    jwt: string;
    contactName: string;
    number: string;
    privacyLevel: PrivacyLevel;
  };
  onLeave: () => void;
  beginMuted: boolean;
  onTerminated: () => void;
  onEnded: () => void;
  alerts: boolean;
  onToggleAlerts: () => void;
  onPeerJoined: () => void;
};

export default function TwilioLiveCall({
  call,
  beginMuted,
  onEnded,
  ...rest
}: Props) {
  const user = useCurrentCorrespondent();

  return (
    <TwilioCallConnection call={call} muteAudio={beginMuted} onEnded={onEnded}>
      {(connectionInfo) => (
        <VoiceCallInterface
          privacyLevel={call.privacyLevel}
          correspondents={[
            {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName,
            },
            {
              id: "twilio-ad-hoc-correspondent",
              firstName: call.contactName,
              lastName: "",
              fullName: call.contactName,
            },
          ]}
          connections={[]}
          connectionInfo={connectionInfo}
          user={user}
          {...rest}
        />
      )}
    </TwilioCallConnection>
  );
}
