import { Stack } from "@mui/material";
import React from "react";
import FullscreenLayout from "./FullscreenLayout";
import { CardSkeleton, TitleSkeleton } from "./lib/closet";

export default function FlowSkeleton() {
  return (
    <FullscreenLayout corner={<TitleSkeleton width={400} />}>
      <Stack spacing={6}>
        <TitleSkeleton width={400} />
        <CardSkeleton />
        <CardSkeleton height={56} />
      </Stack>
    </FullscreenLayout>
  );
}
