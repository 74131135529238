import i18n from "i18next";
import { IdDocumentType } from "../api/graphql";

export enum AvailableCountry {
  PK = "PK",
  US = "US",
}

export const getCountryLabel = (country: AvailableCountry) => {
  switch (country) {
    case AvailableCountry.US:
      return i18n.t("Country.US");
    case AvailableCountry.PK:
      return i18n.t("Country.PK");
    default:
      return i18n.t(country);
  }
};

export const availableDocumentTypesByCountry = {
  [AvailableCountry.US]: [
    IdDocumentType.IdCard,
    IdDocumentType.DriversLicense,
    IdDocumentType.Passport,
    IdDocumentType.Other,
  ],
  [AvailableCountry.PK]: [
    IdDocumentType.Cnic,
    IdDocumentType.Other,
    IdDocumentType.Undocumented,
  ],
};

export const getDocumentTypeLabel = (documentType?: IdDocumentType | null) => {
  if (!documentType) {
    return "-";
  }
  switch (documentType) {
    case IdDocumentType.Cnic:
      return i18n.t("lib.DocumentType.CNIC");
    case IdDocumentType.DriversLicense:
      return i18n.t("lib.DocumentType.DRIVERS_LICENSE");
    case IdDocumentType.IdCard:
      return i18n.t("lib.DocumentType.ID_CARD");
    case IdDocumentType.Other:
      return i18n.t("lib.DocumentType.OTHER");
    case IdDocumentType.Passport:
      return i18n.t("lib.DocumentType.PASSPORT");
    case IdDocumentType.Undocumented:
      return i18n.t("lib.DocumentType.UNDOCUMENTED");
    default:
      return i18n.t(documentType);
  }
};
