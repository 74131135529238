// src/Events/EventsScreen.tsx
import { notMissing } from "@ameelio/core";
import { Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CorrespondentFeature, MeetingStatus } from "../api/graphql";
import buildPageTitle from "../lib/buildPageTitle";
import { BaseSkeleton, ListSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import SplitButton from "../lib/SplitButton";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useHasActiveContact from "../lib/useHasActiveContact";
import useHasScheduling from "../lib/useHasScheduling";
import useMayScheduleWebinars from "../lib/useMayScheduleWebinars";
import { useCurrentCorrespondent } from "../SessionBoundary";
import CancelMeetingDialog from "./CancelMeetingDialog";
import EventList from "./EventList";
import LeaveMeetingDialog from "./LeaveMeetingDialog";
import useEventsQuery from "./useEventsQuery";
import UserAlerts from "./UserAlerts";
import { unaccompaniedVisitAllowed } from "./utils";

enum EventTabs {
  Upcoming = "upcoming",
  Past = "past",
  NotApproved = "notApproved",
}

const itemsPerPage = 20;

export default function EventsScreen() {
  const { t } = useTranslation();
  const headerTitle = t("Events");
  const pageTitle = buildPageTitle(headerTitle);
  const user = useCurrentCorrespondent();

  const [meetingToCancel, setMeetingToCancel] = useState<
    { description: string; meetingId: string } | undefined
  >();
  const [meetingToLeave, setMeetingToLeave] = useState<
    { description: string; meetingId: string } | undefined
  >();

  const schedulingIsSupported = useHasScheduling();
  const shouldShowUpcomingEvents = schedulingIsSupported;
  const shouldShowNotApprovedEvents = schedulingIsSupported;
  const shouldShowPastEvents = true;
  const visibleTabs = [
    shouldShowUpcomingEvents ? EventTabs.Upcoming : undefined,
    shouldShowPastEvents ? EventTabs.Past : undefined,
    shouldShowNotApprovedEvents ? EventTabs.NotApproved : undefined,
  ].filter(notMissing);

  const [activeTab, setActiveTab] = useState<EventTabs>(
    visibleTabs[0] || EventTabs.Past
  );

  const meetingStatus = useMemo(() => {
    switch (activeTab) {
      case EventTabs.Upcoming:
        return [
          MeetingStatus.PendingApproval,
          MeetingStatus.Scheduled,
          MeetingStatus.Live,
        ];
      case EventTabs.Past:
        return [
          MeetingStatus.Ended,
          MeetingStatus.Terminated,
          MeetingStatus.NoShow,
        ];
      case EventTabs.NotApproved:
        return [MeetingStatus.Cancelled, MeetingStatus.Rejected];
      default:
        return [];
    }
  }, [activeTab]);

  const { data, error, refetch, loading, fetchMore } = useEventsQuery(
    meetingStatus,
    itemsPerPage
  );

  const allMeetings = useMemo(
    () => data?.currentCorrespondent?.meetings.edges.map((e) => e.node) || [],
    [data]
  );

  const processedMeetings = useMemo(
    () =>
      activeTab === EventTabs.Upcoming
        ? allMeetings
            .filter((m) => m.interval.endAt > Date.now())
            .sort(
              (a, b) =>
                new Date(a.interval.startAt).getTime() -
                new Date(b.interval.startAt).getTime()
            )
        : allMeetings.sort(
            (a, b) =>
              new Date(b.interval.startAt).getTime() -
              new Date(a.interval.startAt).getTime()
          ),
    [allMeetings, activeTab]
  );

  const fetchOlder = useMemo(() => {
    const pageInfo = data?.currentCorrespondent?.meetings.pageInfo;
    return pageInfo?.hasPreviousPage
      ? () =>
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
            },
          })
      : undefined;
  }, [data, fetchMore]);

  const mayScheduleEvents =
    useFeaturePermitted(CorrespondentFeature.ScheduleMeetings) &&
    unaccompaniedVisitAllowed(user);
  const mayRequestConnections = useFeaturePermitted(
    CorrespondentFeature.RequestConnections
  );
  const mayScheduleWebinars = useMayScheduleWebinars();
  const hasActiveContact = useHasActiveContact();

  if (error) throw error;
  if (!data?.currentCorrespondent) return <ListSkeleton />;

  return (
    <Screen
      title={headerTitle}
      showNotifications
      headerAction={
        ((mayScheduleEvents || mayScheduleWebinars) &&
          hasActiveContact &&
          schedulingIsSupported && (
            <SplitButton
              id="schedule-event-type"
              tooltipTitle={
                !hasActiveContact
                  ? t(
                      "Once you have an approved contact you may be able to schedule events"
                    )
                  : ""
              }
              buttons={[
                mayScheduleEvents
                  ? {
                      label: t("Schedule event"),
                      to: "/events/new",
                      state: { fromPath: "/events", fromName: t("Events") },
                    }
                  : undefined,
                mayScheduleWebinars
                  ? {
                      label: t("Schedule webinar"),
                      to: "/events/new/webinar",
                      state: { fromPath: "/events", fromName: t("Events") },
                    }
                  : undefined,
              ].filter(notMissing)}
              sx={{ flexGrow: 1 }}
            />
          )) ||
        (!hasActiveContact && mayRequestConnections && (
          <Link button variant="contained" to="/contacts" sx={{ flexGrow: 1 }}>
            {t("Manage contacts")}
          </Link>
        ))
      }
    >
      {meetingToCancel && (
        <CancelMeetingDialog
          meetingToCancel={meetingToCancel}
          onClose={() => setMeetingToCancel(undefined)}
        />
      )}
      {meetingToLeave && (
        <LeaveMeetingDialog
          meetingToLeave={meetingToLeave}
          onClose={async () => {
            refetch();
            setMeetingToLeave(undefined);
          }}
        />
      )}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UserAlerts />
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        aria-label={t("Event tabs")}
        sx={{ mb: 4 }}
      >
        {shouldShowUpcomingEvents && (
          <Tab label={t("Upcoming")} value={EventTabs.Upcoming} />
        )}
        {shouldShowPastEvents && (
          <Tab label={t("Past")} value={EventTabs.Past} />
        )}
        {shouldShowNotApprovedEvents && (
          <Tab label={t("Not approved")} value={EventTabs.NotApproved} />
        )}
      </Tabs>
      {loading ? (
        <BaseSkeleton variant="rectangular" height={150} />
      ) : (
        <>
          {activeTab === EventTabs.Upcoming && (
            <EventList
              meetings={processedMeetings}
              onCancel={setMeetingToCancel}
              onLeaveEvent={setMeetingToLeave}
              showDateInfo
            />
          )}
          {activeTab === EventTabs.Past && (
            <EventList
              meetings={processedMeetings}
              onCancel={setMeetingToCancel}
              onLeaveEvent={setMeetingToLeave}
              fetchOlder={fetchOlder}
            />
          )}
          {activeTab === EventTabs.NotApproved && (
            <EventList
              meetings={processedMeetings}
              onCancel={setMeetingToCancel}
              onLeaveEvent={setMeetingToLeave}
              fetchOlder={fetchOlder}
            />
          )}
        </>
      )}
    </Screen>
  );
}
