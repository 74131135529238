import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";
import { alpha, Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { darkPalette } from "../theme";

type Props = {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  onPrevious: (currentPage: number) => void;
  onNext: (currentPage: number) => void;
  children: React.ReactNode;
  styleOverrides?: SxProps<Theme>;
};

const pageButtonStyle: SxProps<Theme> = {
  cursor: "pointer",
  mx: 1,
};

const pageButtonStyleEnabled: SxProps<Theme> = [
  pageButtonStyle,
  {
    color: alpha(darkPalette.action.active, 0.56),
  },
];

const pageButtonStyleDisabled: SxProps<Theme> = [
  pageButtonStyle,
  {
    color: darkPalette.action.disabled,
  },
];

export const getTotalPages = (itemsPerPage: number, numItems: number) =>
  numItems % itemsPerPage === 0
    ? numItems / itemsPerPage
    : Math.floor(numItems / itemsPerPage) + 1; // Needs one more page to account for overflow

function Pager({
  itemsPerPage,
  currentPage,
  totalItems,
  onNext,
  onPrevious,
  children,
  styleOverrides,
}: Props) {
  const totalPages = getTotalPages(itemsPerPage, totalItems);

  // Compute first/last page position
  const onFirstPage = currentPage === 1;
  const onLastPage = currentPage === totalPages;

  const handlePrevious = () => {
    const previousPage = currentPage - 1;
    // Return updated page number and new items
    onPrevious(previousPage);
  };

  const handleNext = () => {
    const nextPage = currentPage + 1;
    // Return updated page number and new items
    onNext(nextPage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        ...(styleOverrides || {}),
      }}
    >
      {totalPages > 1 ? (
        onFirstPage ? (
          <ArrowCircleLeftOutlined sx={pageButtonStyleDisabled} />
        ) : (
          <ArrowCircleLeftOutlined
            onClick={handlePrevious}
            sx={pageButtonStyleEnabled}
          />
        )
      ) : null}
      {children}
      {totalPages > 1 ? (
        onLastPage ? (
          <ArrowCircleRightOutlined sx={pageButtonStyleDisabled} />
        ) : (
          <ArrowCircleRightOutlined
            onClick={handleNext}
            sx={pageButtonStyleEnabled}
          />
        )
      ) : null}
    </Box>
  );
}

export default Pager;
