import { ErrorOutline } from "@mui/icons-material";
import { Alert, AlertTitle, Box, List, ListItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectionRestriction, User } from "../api/graphql";
import serviceTypeToHumanReadable from "./serviceTypeToHumanReadable";

type Props = {
  contact: Pick<User, "__typename" | "firstName">;
  restrictions: Pick<ConnectionRestriction, "id" | "service">[];
};

export default function ContactRestrictionsAlert({
  contact,
  restrictions,
}: Props) {
  const { t } = useTranslation();
  const subHeader =
    contact.__typename && contact.__typename === "Inmate"
      ? t("{{firstName}} is currently restricted from using:", {
          firstName: contact.firstName,
        })
      : t("You are currently blocked from doing this with {{firstName}}:", {
          firstName: contact.firstName,
        });

  return (
    <Alert
      severity="error"
      sx={{ mb: 3 }}
      icon={
        <ErrorOutline sx={{ color: "error.main", alignSelf: "baseline" }} />
      }
    >
      <AlertTitle>{t("Ongoing restriction")}</AlertTitle>
      <Box>{subHeader}</Box>
      <Box>
        <List sx={{ listStyleType: "disc", ml: 3 }} disablePadding>
          {restrictions.map(
            (res: Pick<ConnectionRestriction, "id" | "service">) => (
              <ListItem
                key={res.id}
                sx={{ display: "list-item" }}
                disablePadding
              >
                {serviceTypeToHumanReadable(res.service)}
              </ListItem>
            )
          )}
        </List>
      </Box>
      <Box sx={{ mt: 3 }}>
        <>
          {restrictions.length > 1
            ? t("You will be notified when these restrictions are lifted")
            : t("You will be notified when this restriction is lifted")}
          .
        </>
      </Box>
    </Alert>
  );
}
