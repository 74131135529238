import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Typography } from "@mui/material";
import React from "react";
import { formatTime } from "../lib/timeFormats";

type Props = {
  alignment: "left" | "right";
  status?: "unread" | "sent";
  timestamp: Date | number;
  children: React.ReactNode;
  isAvailable?: boolean;
};

export default function TimelineEventWrapper({
  alignment,
  status,
  timestamp,
  children,
  isAvailable,
}: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: alignment === "right" ? "row-reverse" : "row",
        " .reveal": {
          visibility: "hidden",
        },
        "&:hover .reveal": {
          visibility: "visible",
        },
      }}
    >
      <Box sx={{ flexGrow: "1 1 auto", maxWidth: 600 }}>{children}</Box>

      <Box
        sx={{
          flexGrow: 0,
          minWidth: 100,
          maxWidth: 100,
          justifyContent: alignment === "right" ? "flex-end" : "flex-start",
          mx: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box mr={1}>
          {status === "unread" && isAvailable && (
            <CircleIcon
              color="primary"
              sx={{
                width: 14,
                height: 14,
              }}
            />
          )}
          {status === "sent" && isAvailable && (
            <CheckCircleIcon
              className="reveal"
              sx={{
                color: "text.secondary",
                width: 14,
                height: 14,
              }}
            />
          )}
        </Box>
        <Typography
          variant="caption"
          color="text.secondary"
          whiteSpace="nowrap"
          className="reveal"
        >
          {formatTime(timestamp)}
        </Typography>
      </Box>
    </Box>
  );
}
