import React from "react";
import { Navigate } from "react-router-dom";
import AppSkeleton from "../AppSkeleton";
import { useCurrentUserData } from "../CurrentUserProvider";

export default function InmateHomeScreen() {
  const data = useCurrentUserData();
  if (data.loading) return <AppSkeleton />;
  if (data?.me) return <Navigate to="/home" />;
  return <Navigate to="/login" />;
}
