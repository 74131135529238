import { Box, SxProps, Theme } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import React from "react";
import { ltrTheme } from "../../theme";

export default function ParticipantPlaceholder({
  children,
  styleOverrides,
}: {
  children: JSX.Element | JSX.Element[];
  styleOverrides?: SxProps<Theme>;
}) {
  const style: SxProps<Theme> = {
    position: "absolute",
    width: 1,
    height: 1,
    top: 0,
    left: 0,
    background: grey[900],
    borderRadius: "16px",
    color: common.white,
    textAlign: "center",
  };
  return (
    <Box sx={{ ...style, ...(styleOverrides || {}) }}>
      <Box
        sx={{ display: "flex", justifyContent: "center", width: 1, height: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: 1,
            maxWidth: ltrTheme.spacing(28),
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
