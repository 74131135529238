import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider as Base } from "@mui/material";
import React, { useMemo } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { ltrTheme, rtlTheme } from "../theme";
import getLanguageDirection from "./languageDir";

// https://mui.com/material-ui/customization/right-to-left/#3-configure-rtl-style-plugin
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: "muiltr",
  stylisPlugins: [prefixer],
});

type Props = {
  children: React.ReactNode;
};

export default function StyleProvider({ children }: Props) {
  const languageDir = getLanguageDirection();
  const theme = useMemo(
    () => (languageDir === "rtl" ? rtlTheme : ltrTheme),
    [languageDir]
  );
  const cache = languageDir === "rtl" ? cacheRtl : cacheLtr;

  return (
    <Base theme={theme}>
      <CacheProvider value={cache}>
        <CssBaseline />
        {children}
      </CacheProvider>
    </Base>
  );
}
