import { t } from "i18next";
import { FacilityService } from "../api/graphql";

const serviceTypeToHumanReadable = (service: FacilityService) => {
  const serviceTypes: Record<FacilityService, string> = {
    [FacilityService.Emessaging]: t("messaging"),
    [FacilityService.InPersonVisit]: t("in-person visits"),
    [FacilityService.VideoCall]: t("video calls"),
    [FacilityService.VoiceCall]: t("voice calls"),
    [FacilityService.Webinar]: t("webinar"),
  };
  return serviceTypes[service];
};

export default serviceTypeToHumanReadable;
