import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionTimelineEventWithObjectFragmentFragmentDoc } from '../api/ConnectionTimelineEventWithObjectFragment.generated';
import { ConnectionTimelineEventFragmentFragmentDoc } from '../api/ConnectionTimelineEventFragment.generated';
import { MessageFragmentFragmentDoc } from '../api/MessageFragment.generated';
import { VisitorFragmentFragmentDoc } from '../api/VisitorFragment.generated';
import { MeetingFragmentFragmentDoc } from '../api/MeetingFragment.generated';
export type GetConnectionActivityUpdateQueryVariables = Types.Exact<{
  connectionId: Types.Scalars['ID'];
}>;


export type GetConnectionActivityUpdateQuery = { __typename: 'Query', connection: { __typename: 'Connection', id: string, lastMessageCenterActivityAt: number, timelineEvents: { __typename: 'ConnectionTimelineEventConnection', edges: Array<{ __typename: 'ConnectionTimelineEventEdge', node: { __typename: 'ConnectionTimelineEvent', id: string, verb: Types.ConnectionTimelineEventVerb, createdAt: number, object: { __typename: 'Connection', id: string, createdAt: number, contact: { __typename: 'Inmate', id: string, firstName: string } | { __typename: 'Visitor', id: string, firstName: string } } | { __typename: 'ConnectionRestriction' } | { __typename: 'Meeting', id: string, title: string | null, meetingType: Types.MeetingType, status: Types.MeetingStatus, statusDetails: string | null, privacyLevel: Types.PrivacyLevel, call: { __typename: 'Call', id: string, duration: number | null } | null, interval: { __typename: 'Interval', startAt: number, endAt: number, seconds: number | null } } | { __typename: 'Message', content: string, id: string, isRead: boolean, createdAt: number, availability: Types.MessageAvailability, statusDetails: string | null, isInstantReply: boolean, fileUrl: string | null, type: Types.MessageType, sender: { __typename: 'Inmate', id: string } | { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, email: string }, connection: { __typename: 'Connection', id: string, inmate: { __typename: 'Inmate', id: string, facility: { __typename: 'Facility', id: string, publicId: string } } } } } }> } } };


export const GetConnectionActivityUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConnectionActivityUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"connectionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastMessageCenterActivityAt"}},{"kind":"Field","name":{"kind":"Name","value":"timelineEvents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"last"},"value":{"kind":"IntValue","value":"1"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionTimelineEventWithObjectFragment"}}]}}]}}]}}]}}]}},...ConnectionTimelineEventWithObjectFragmentFragmentDoc.definitions,...ConnectionTimelineEventFragmentFragmentDoc.definitions,...MessageFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions,...MeetingFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetConnectionActivityUpdateQuery, GetConnectionActivityUpdateQueryVariables>;