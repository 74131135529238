import { Box } from "@mui/material";
import React from "react";
import { BaseSkeleton, IconSkeleton, TitleSkeleton } from "../lib/closet";
import withSx from "../lib/withSx";
import {
  ConnectionsColumn,
  ConnectionsFilter,
  ConnectionsList,
  MessageCenterPaper,
  TimelineColumn,
} from "./ui";

const NoTransformSkele = withSx(BaseSkeleton, { transform: "none" });

function ConnectionsListItemSkeleton() {
  return (
    <Box sx={{ display: "flex", mb: 2, ml: 2, mr: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <IconSkeleton />
      </Box>
      <Box sx={{ flex: 1, pl: 2 }}>
        <NoTransformSkele width="100%" height={59} />
      </Box>
    </Box>
  );
}

function TimelineAreaSkeleton() {
  return (
    <>
      <Box sx={{ m: 2 }}>
        <Box sx={{ display: "inline-block", verticalAlign: "middle" }}>
          <IconSkeleton />
        </Box>
        <Box
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            pl: 2,
          }}
        >
          <TitleSkeleton />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flex: 1, m: 2 }}>
        <NoTransformSkele width="100%" height={55} sx={{ marginTop: "auto" }} />
      </Box>
    </>
  );
}

export default function MessageCenterScreenSkeleton() {
  return (
    <Box sx={{ height: 1, display: "flex", flexDirection: "column" }}>
      <Box mb={4}>
        <TitleSkeleton />
      </Box>
      <MessageCenterPaper>
        <ConnectionsColumn
          sx={{
            borderRightStyle: "solid",
            borderRightWidth: 1,
          }}
        >
          <ConnectionsFilter>
            <NoTransformSkele width="100%" height={56} />
          </ConnectionsFilter>
          <ConnectionsList>
            <ConnectionsListItemSkeleton />
            <ConnectionsListItemSkeleton />
            <ConnectionsListItemSkeleton />
          </ConnectionsList>
        </ConnectionsColumn>
        <TimelineColumn>
          <TimelineAreaSkeleton />
        </TimelineColumn>
      </MessageCenterPaper>
    </Box>
  );
}
