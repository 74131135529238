import {
  addYears,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  subDays,
} from "date-fns";
import { ConnectionStatus, Inmate, Staff, Visitor } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { MINOR_AGE_YEARS_CUTOFF } from "./isMinor";
import { shortMonthDate } from "./timeFormats";

export const REMINDER_DAYS_BEFORE_LEGAL_AGE = 30;

type Props = {
  user:
    | Pick<Visitor, "__typename" | "dateOfBirth">
    | Pick<Inmate | Staff, "__typename">;
};

type Response = {
  showProvideIdReminder: boolean;
  reminderDueDate: string;
};

function isEligibleVisitor(
  user: Props["user"]
): user is Visitor & { dateOfBirth: string } {
  return user.__typename === "Visitor" && !!user.dateOfBirth;
}

const useRemindMinorToProvideId = ({ user }: Props): Response => {
  const currentUser = useCurrentCorrespondent();
  if (!isEligibleVisitor(user) || user.identity?.id) {
    return {
      showProvideIdReminder: false,
      reminderDueDate: "",
    };
  }

  const today = new Date();
  const dob = parseISO(user.dateOfBirth);
  const legalAdultBirthday = addYears(dob, MINOR_AGE_YEARS_CUTOFF);
  // Calculate the reminder start date by subtracting
  // `REMINDER_DAYS_BEFORE_LEGAL_AGE` days from the 18th birthday
  const reminderStartDate = subDays(
    legalAdultBirthday,
    REMINDER_DAYS_BEFORE_LEGAL_AGE
  );
  const isWithinReminderInterval =
    (isSameDay(today, reminderStartDate) ||
      isAfter(today, reminderStartDate)) &&
    isBefore(today, legalAdultBirthday);

  if (
    isWithinReminderInterval &&
    currentUser.connections.some((c) => c.status === ConnectionStatus.Active)
  ) {
    return {
      showProvideIdReminder: true,
      reminderDueDate: shortMonthDate(legalAdultBirthday),
    };
  }

  return {
    showProvideIdReminder: false,
    reminderDueDate: "",
  };
};

export default useRemindMinorToProvideId;
