import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRoutes,
} from "react-router-dom";
import "./App.css";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    release: import.meta.env.VITE_SENTRY_RELEASE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
}

// eslint-disable-next-line import/prefer-default-export
export const useInstrumentedRoutes = Sentry.wrapUseRoutes(useRoutes);
