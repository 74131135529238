import { useQuery } from "@apollo/client";
import { PresentToAllOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { PickersDay } from "@mui/x-date-pickers";
import { isSameDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateInputBase } from "../../lib/DateInput";
import StepperFormWrapper from "../../lib/StepperFormWrapper";
import { StepperType } from "../../lib/StepperWrapper";
import { formatTimeRange } from "../../lib/timeFormats";
import { ScreenTitle } from "../../lib/typography";
import AvailabilityTile from "./AvailabilityTile";
import { GetFacilityWebinarAvailabilityDocument } from "./GetFacilityWebinarAvailability.generated";
import {
  SelectWebinarTimeData,
  WebinarAvailability,
  WebinarData,
} from "./types";

type Props = {
  title: string;
  defaultValues: WebinarData;
  facilityId: string;
  stepper: StepperType;
  onSubmit: (data: SelectWebinarTimeData) => void;
};

export default function SelectWebinarTimeStep({
  title,
  defaultValues,
  facilityId,
  stepper,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const [date, setDate] = useState<Date | null>(
    defaultValues.availability?.interval?.startAt
      ? new Date(defaultValues.availability?.interval?.startAt)
      : null
  );

  const {
    data: availabilityData,
    loading: availabilityLoading,
    error: availabilityError,
  } = useQuery(GetFacilityWebinarAvailabilityDocument, {
    fetchPolicy: "network-only",
    variables: { facilityId },
  });
  if (availabilityError) throw availabilityError;

  const availabilitiesOnDate: WebinarAvailability[] = useMemo(() => {
    if (availabilityData && date) {
      return availabilityData?.facility.webinarAvailability.filter((a) =>
        isSameDay(date, a.interval.startAt)
      );
    }
    return [];
  }, [availabilityData, date]);

  const [availability, setAvailability] = useState<
    WebinarAvailability | undefined | null
  >(defaultValues.availability);

  // clear the selected availability when date changes
  useEffect(() => {
    // This guards against an initial case when date is initialized with
    // a default value
    if (
      date &&
      defaultValues.availability &&
      date.toISOString() ===
        new Date(defaultValues.availability.interval.startAt).toISOString()
    ) {
      return;
    }

    // Only set to undefined if the date doesn't exist or
    // if it has changed from a previously-selected date
    setAvailability(undefined);
  }, [setAvailability, date, defaultValues]);

  const dayHasAvailableMeetings = (day: Date) =>
    availabilityData?.facility.webinarAvailability.some((a) =>
      isSameDay(day, a.interval.startAt)
    );

  const meetingDayIsUnavailable = (day: Date) =>
    availabilityData?.facility.webinarAvailability.filter((a) =>
      isSameDay(day, a.interval.startAt)
    );

  const dayHasNoMeetings = (day: Date) =>
    !availabilityData?.facility.webinarAvailability.some((a) =>
      isSameDay(day, a.interval.startAt)
    );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!availability) return;
    onSubmit({ availability });
  };

  const now = Date.now();
  return (
    <StepperFormWrapper
      stepper={stepper({ disabled: !availability || availabilityLoading })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("When would you like to meet?")}</ScreenTitle>
      <Typography variant="body1" color="text.primary" marginTop={2}>
        {t(
          "Availability is limited by the webinar schedules at each facility where a selected student resides."
        )}
      </Typography>
      <Box
        mt={4.5}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PresentToAllOutlined />
        <Typography variant="body2" color="text.primary" ml={1}>
          {title}
        </Typography>
      </Box>
      <Stack spacing={6} marginY={6}>
        <DateInputBase
          value={date}
          onChange={(d) => setDate(d)}
          disablePast
          loading={availabilityLoading}
          renderLoading={() => <CircularProgress />}
          disableHighlightToday
          label={t("Select a date")}
          shouldDisableDate={(d) => dayHasNoMeetings(d)}
          renderDay={(day, _selectedDays, pickersDayProps) => (
            <PickersDay
              {...pickersDayProps}
              sx={{
                backgroundColor:
                  pickersDayProps.disabled || !dayHasAvailableMeetings(day)
                    ? undefined
                    : dayHasAvailableMeetings(day)
                      ? blue[50]
                      : meetingDayIsUnavailable(day)
                        ? grey[200]
                        : undefined,
              }}
            />
          )}
        />

        {date && (
          <>
            {!availabilityLoading && !availabilitiesOnDate.length && (
              <Alert severity="error">
                {t(
                  "There are no time slots on this day. Please select a different day."
                )}
              </Alert>
            )}

            {/** the div fixes a Stack vs Grid conflict */}
            <div>
              <Grid container spacing={1}>
                {availabilitiesOnDate.map((a) => (
                  <Grid
                    key={`${date}-${a.interval.startAt}-${a.interval.endAt}`}
                    item
                    xs={4}
                  >
                    <AvailabilityTile
                      disabled={a.interval.endAt < now}
                      unavailableReason={
                        a.interval.endAt < now
                          ? t("This time slot has already passed")
                          : ""
                      }
                      selected={
                        availability?.interval.startAt === a.interval.startAt &&
                        availability.interval.endAt === a.interval.endAt
                      }
                      onClick={() => setAvailability(a)}
                    >
                      {formatTimeRange(a.interval.startAt, a.interval.endAt)}
                    </AvailabilityTile>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
      </Stack>
    </StepperFormWrapper>
  );
}
