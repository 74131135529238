/* eslint-disable import/prefer-default-export */
import { differenceInYears, parseISO } from "date-fns";
import { ConnectionStatus } from "../api/graphql";
import { GetCurrentUserQuery } from "../GetCurrentUser.generated";

function isOldEnoughToSchedule(
  dateOfBirth: string,
  identificationAge: number
): boolean {
  const age = differenceInYears(new Date(), parseISO(dateOfBirth));
  return age >= identificationAge;
}

export function unaccompaniedVisitAllowed(
  user: GetCurrentUserQuery["me"],
  connectionId?: string
) {
  // Skip check if the user is not a visitor
  if (user?.__typename !== "Visitor") return true;

  const { dateOfBirth } = user;

  // Skip check if date of birth unknown or no connections
  if (!dateOfBirth || user.connections.length === 0) return true;

  if (connectionId) {
    // Get `unaccompaniedVisitAge` for specific connection
    const currentConnection = user.connections.find(
      (c) => c.id === connectionId
    );
    if (currentConnection) {
      return isOldEnoughToSchedule(
        dateOfBirth,
        currentConnection.inmate.facility.unaccompaniedVisitAge
      );
    }
    // Skip if currentConnection not found
    return true;
  }

  // Check if user is of age in some facilities
  return user.connections.some(
    ({ status, inmate: { facility } }) =>
      status === ConnectionStatus.Active &&
      isOldEnoughToSchedule(dateOfBirth, facility.unaccompaniedVisitAge)
  );
}
