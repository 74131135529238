import React from "react";
import { useTranslation } from "react-i18next";
import { PrivacyLevel } from "../api/graphql";
import { FAQListItem } from "../lib/FAQListDialog";

type Props = {
  meetingDurationMins: number;
  privacyLevel: PrivacyLevel;
};

export default function VideoCallFAQList({
  meetingDurationMins,
  privacyLevel,
}: Props) {
  const { t } = useTranslation();
  const faqItems = [
    {
      question: t("When can I access the call?"),
      answer: t(
        "You may enter the video call Waiting Room up to 5 minutes before your scheduled start time. While you are waiting, you can test the in-call features as well as check your video and audio connection. (We recommend checking your video and audio connection at least once prior to a call.)"
      ),
    },
    {
      question: t("How long is my call?"),
      answer: t(
        "This video call is {{meetingDurationMins}} minutes long, and you may check how much time has elapsed with the in-call clock. When two minutes are remaining, the clock will start counting down. You may leave and rejoin the call anytime within this {{meetingDurationMins}} minute time frame.",
        {
          meetingDurationMins,
        }
      ),
    },
    {
      question: t("Who can listen to my call?"),
      answer:
        privacyLevel === PrivacyLevel.Monitored
          ? t(
              "Facility staff will be able to monitor and review recordings of this video call to ensure that it complies with all relevant policies."
            )
          : t(
              "This call is confidential and may not be monitored or recorded. Facility staff will only see a log of who was on the call and when it happened."
            ),
    },
  ];
  return (
    <>
      {faqItems.map((faq) => (
        <FAQListItem key={faq.question} {...faq} />
      ))}
    </>
  );
}
