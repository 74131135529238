import { Button, IconButton, TextInputBase } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Close, Edit } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Visitor } from "../api/graphql";
import { DetailsStackItem } from "../lib/DetailsStack";
import useApolloErrorHandler from "../lib/handleApolloError";
import { UpdateVisitorEmailDocument } from "./UpdateVisitorEmail.generated";

type Props = {
  visitor: Pick<Visitor, "id" | "email">;
};

export default function EditVisitorEmail({ visitor }: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const [updatedEmail, setUpdatedEmail] = useState<string>();
  const [editingEmail, setEditingEmail] = useState<boolean>(false);

  const [updateVisitorEmail, { loading: submitting }] = useMutation(
    UpdateVisitorEmailDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: () => setEditingEmail(false),
    }
  );

  return (
    <DetailsStackItem label={t("Email address")}>
      <>
        {editingEmail ? (
          <TextInputBase
            sx={{
              maxWidth: "100%",
              minWidth: { md: "250px", lg: "400px" },
              borderRadius: "8px",
            }}
            autoComplete="off"
            value={updatedEmail}
            label={t("Email address")}
            onChange={(e) => {
              setUpdatedEmail(e.target.value);
            }}
            addClearIcon
            onClear={() => setUpdatedEmail(undefined)}
          />
        ) : (
          visitor.email
        )}
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          {editingEmail ? (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  if (!updatedEmail?.trim().length) return;
                  updateVisitorEmail({
                    variables: {
                      input: {
                        id: visitor.id,
                        email: updatedEmail.trim(),
                      },
                    },
                  });
                }}
                disabled={
                  submitting || !(updatedEmail && updatedEmail.trim().length)
                }
              >
                {t("Save")}
              </Button>
              <IconButton
                ariaLabel={t("Close")}
                onClick={() => setEditingEmail(false)}
                sx={{
                  marginLeft: 1,
                  padding: 2,
                  width: 24,
                  height: 24,
                }}
              >
                <Close />
              </IconButton>
            </>
          ) : (
            <IconButton
              ariaLabel={t("Edit")}
              onClick={() => setEditingEmail(true)}
              sx={{
                padding: 2,
                width: 24,
                height: 24,
              }}
            >
              <Edit />
            </IconButton>
          )}
        </Box>
      </>
    </DetailsStackItem>
  );
}
