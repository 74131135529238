import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import VoiceCallScreen from "../Call/VoiceCallScreen";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";

const VoiceCallRoutes: RouteObject = {
  path: "/voice-call/:id",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <ValidateUUID>
          <VoiceCallScreen />
        </ValidateUUID>
      ),
    },
  ],
};

export default VoiceCallRoutes;
