import React from "react";
import { useTranslation } from "react-i18next";
import FAQListDialog, { FAQDialogProps } from "../lib/FAQListDialog";

type Props = Omit<FAQDialogProps, "title" | "faqs">;

export default function SettingsFAQListDialog({ isOpen, onClose }: Props) {
  const { t } = useTranslation();
  return (
    <FAQListDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("Frequently Asked Questions")}
      faqs={[
        {
          question: t("How do I add new contacts?"),
          answer: t(
            "In this version of Ameelio, you can receive but not send contact requests. Anyone on your currently approved call list can request you as a contact once they download the Ameelio Connect app on their smartphone. When someone requests you as a contact, their status will appear as pending until their photo ID is verified by a facility staff member. Once verified, they will be added to your active contact list and may be able to schedule calls with you."
          ),
        },
        {
          question: t("How are calls scheduled?"),
          answer: t(
            "In this version of Ameelio, your contacts can schedule calls with you, but you cannot schedule calls with them. When logged into your account, you can see all of the upcoming calls that your contacts have scheduled with you. Call times are shown in each person’s respective time zones."
          ),
        },
        {
          question: t("What happens if I can’t make a scheduled call?"),
          answer: t(
            "If you can’t make a scheduled call, you can cancel it whenever you’re logged into your account by selecting ‘View details’ on a given call. You may send a cancellation reason to help your contact reschedule for a time that works better for you. When you cancel a call, your contact will receive a notification and be given the immediate option to reschedule."
          ),
        },
        {
          question: t(
            "If an unexpected event happens that prevents me from participating in a scheduled call (i.e. lockdown, storm, etc.), will my contact be notified?"
          ),
          answer: t(
            "Unfortunately, not at this time. We understand the importance of this notification though and are working to make sure it can happen in the future."
          ),
        },
      ]}
    />
  );
}
