import { cacheConfiguration as useSurveyCacheConfig } from "@ameelio/use-survey";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Reference,
  StoreObject,
} from "@apollo/client";
import { Modifier } from "@apollo/client/cache/core/types/common";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { mergeDeep, relayStylePagination } from "@apollo/client/utilities";
import fragmentMatcher from "./api/fragment-matcher.json";
import { getToken } from "./lib/authToken";

const { possibleTypes } = fragmentMatcher;

type Logout = (tokenError?: boolean) => void;

const errorLink = (logout: Logout) =>
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const tokenError = graphQLErrors
        // according to types, `message` should exist. however we've seen
        // sentry reports that clearly demonstrate it can be undefined.
        .filter((e) => e.message)
        .find(
          (err) =>
            err.message.includes("API token is expired or revoked") ||
            err.message.includes("API token is invalid or missing")
        );

      if (tokenError) {
        logout(true);
      }
    }

    return forward(operation);
  });

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const enhancedFetch = (_: string, options: RequestInit): Promise<Response> => {
  const { operationName } = JSON.parse(options.body as string);
  return fetch(
    `${import.meta.env.VITE_API_ORIGIN}/api/graphql?op=${operationName}`,
    options
  );
};

const httpLink = createHttpLink({ fetch: enhancedFetch });

const customNotificationPaginationPolicy = () => {
  const rsp = relayStylePagination();
  // Necessary type narrowing.
  if (typeof rsp.merge === "boolean")
    throw new Error(`relayStylePagination merge prop was set to bool somehow`);
  const defaultMerge = rsp.merge;
  const defaultRead = rsp.read;
  /**
   * We want to prevent requests that don't return any 'edges' from caching an empty edge set.
   * This occurs when we query for unreadNotificationCount (GetUnreadNotificationCount).
   * To prevent caching empty sets we merely update the existing cache with unreadNotificationCount
   * when 'edges' is missing.  Since 'edges' can be missing sometimes, we need to add an empty array
   * back when reading the cache as to not bork relayStylePagination.  It expects 'edges' to always exist.
   */
  return {
    ...rsp,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    read: (existing: any, opts: any) =>
      defaultRead?.(
        {
          ...existing,
          edges: existing?.edges || [],
        },
        opts
      ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: (existing: any, incoming: any, opts: any) => {
      if (incoming && !incoming.edges) {
        return {
          ...existing,
          unreadNotificationCount:
            incoming?.unreadNotificationCount ??
            existing?.unreadNotificationCount,
        };
      }
      return defaultMerge?.(existing, incoming, opts);
    },
  };
};

const existingCacheConfiguration = {
  possibleTypes,
  typePolicies: {
    Visitor: {
      fields: {
        meetings: relayStylePagination(["dayStart"]),
        notificationEvents: customNotificationPaginationPolicy(),
      },
    },
    Inmate: {
      fields: {
        meetings: relayStylePagination(["dayStart"]),
        notificationEvents: customNotificationPaginationPolicy(),
      },
    },
    Connection: {
      fields: {
        timelineEvents: relayStylePagination(["@connection"]),
        fileGallery: relayStylePagination(["@connection"]),
      },
    },
  },
};

export const cacheConfiguration = mergeDeep(
  existingCacheConfiguration,
  useSurveyCacheConfig
);

const cache = new InMemoryCache(cacheConfiguration);

export const client = new ApolloClient({
  connectToDevTools: import.meta.env.DEV,
  link: ApolloLink.from([httpLink]),
  cache,
});

const getAuthenticatedClient = (logout: Logout) =>
  new ApolloClient({
    connectToDevTools: import.meta.env.DEV,
    link: ApolloLink.from([errorLink(logout), authLink, httpLink]),
    cache,
  });

export default getAuthenticatedClient;

/**
 * appendItem will return a cache modifier that adds an object to the end
 * of a list.
 */
export function appendItem<T extends { id: string }>(
  object: T
): Modifier<Reference[]> {
  return (existing, { toReference, readField }) => {
    const objectRef = toReference(object, true);
    if (!objectRef)
      throw new Error(
        "Attempted to insert an object with a faulty or missing id"
      );
    return [
      ...existing.filter((e) => object.id !== readField("id", e)),
      objectRef,
    ];
  };
}

export function evictItem<T extends StoreObject & { id: string }>(object: T) {
  cache.evict({ id: cache.identify(object) });
}
