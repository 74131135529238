import { Box, Container, ContainerProps } from "@mui/material";
import React from "react";
import ScreenHeader from "./ScreenHeader";

type ScreenContentsProps = ContainerProps;

export function ScreenContents(props: ScreenContentsProps) {
  return <Container maxWidth="sm" sx={{ p: 0 }} {...props} />;
}

type Props = {
  title: string;
  children: React.ReactNode;
  headerAction?: React.ReactNode;
  showBack?: boolean;
  showNotifications?: boolean;
  maxWidth?: ContainerProps["maxWidth"];
};

export default function Screen({
  title,
  children,
  headerAction,
  showBack,
  showNotifications,
  maxWidth,
}: Props) {
  return (
    <Box sx={{ pb: 4 }}>
      <ScreenHeader
        action={headerAction}
        enableBackNavigation={showBack}
        hideNotifications={!showNotifications}
      >
        {title}
      </ScreenHeader>
      <ScreenContents maxWidth={maxWidth || "sm"}>{children}</ScreenContents>
    </Box>
  );
}
