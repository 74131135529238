import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from '../api/ConnectionFragment.generated';
export type UpdateConnectionRelationshipMutationVariables = Types.Exact<{
  input: Types.UpdateConnectionRelationshipInput;
}>;


export type UpdateConnectionRelationshipMutation = { __typename: 'Mutation', updateConnectionRelationship: { __typename: 'UpdateConnectionRelationshipResponse', connection: { __typename: 'Connection', id: string, status: Types.ConnectionStatus, relationship: string, confidential: boolean, createdAt: number } } };


export const UpdateConnectionRelationshipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateConnectionRelationship"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateConnectionRelationshipInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateConnectionRelationship"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateConnectionRelationshipMutation, UpdateConnectionRelationshipMutationVariables>;