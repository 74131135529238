import { SelectInput, TextInput } from "@ameelio/ui";
import { Stack, Typography } from "@mui/material";
import { sub } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateInput from "../lib/DateInput";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { ScreenTitle } from "../lib/typography";
import {
  isMinorDateOfBirth,
  isRequired,
  MAX_USER_AGE,
  mergeRules,
} from "../lib/validate";
import { MinorSearchCriteria } from "./types";

type Props = {
  stepper: StepperType;
  facilityOptions: { name: string; value: string }[];
  defaultValues: Partial<MinorSearchCriteria>;
  onSubmit: (data: MinorSearchCriteria) => void;
};

export default function ConnectionsSearchMinorsForm({
  stepper,
  facilityOptions,
  defaultValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<MinorSearchCriteria>({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      ...defaultValues,
    },
  });

  return (
    <StepperFormWrapper
      stepper={stepper({
        loading: isSubmitting,
        disabled: !isValid,
      })}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <ScreenTitle>{t("Search for a minor")}</ScreenTitle>
      <Typography marginTop={2}>
        {t("Enter all of the information for the minor you are searching for.")}
      </Typography>
      <Stack marginTop={3} marginBottom={6} spacing={3}>
        <TextInput
          control={control}
          name="firstName"
          label={t("First Name")}
          sx={{ width: 1 }}
          rules={isRequired(t("Please enter a first name."))}
        />
        <TextInput
          control={control}
          name="lastName"
          label={t("Last Name")}
          sx={{ width: 1 }}
          rules={isRequired(t("Please enter a last name."))}
        />
        <DateInput
          control={control}
          name="dateOfBirth"
          label={t("Date of birth")}
          disableFuture
          disableHighlightToday
          minDate={sub(new Date(), { years: MAX_USER_AGE, days: 364 })}
          rules={mergeRules(
            isRequired(t("Please enter a date of birth.")),
            isMinorDateOfBirth()
          )}
        />
        <SelectInput
          control={control}
          name="facilityId"
          label={t("Facility")}
          items={facilityOptions}
          rules={isRequired(t("Please select a facility."))}
        />
      </Stack>
    </StepperFormWrapper>
  );
}
