import { IconButton } from "@ameelio/ui";
import { Clear } from "@mui/icons-material";
import { Box, Card, Typography } from "@mui/material";
import React from "react";

export default function GuestCard({
  title,
  subtitle,
  onRemove,
}: {
  title: string;
  subtitle: string;
  onRemove: () => void;
}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        py: 3,
        px: 4,
        my: 2,
      }}
      variant="outlined"
    >
      <Box>
        <Typography variant="body1" color="text.primary">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </Box>
      <IconButton onClick={onRemove} ariaLabel="remove">
        <Clear />
      </IconButton>
    </Card>
  );
}
