import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { BaseSkeleton, CardSkeleton, TitleSkeleton } from "../lib/closet";

export default function CallSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 1,
        width: 1,
      }}
    >
      <Grid container p={5}>
        <Grid item xs={7}>
          <BaseSkeleton variant="rectangular" height={450} />
        </Grid>
        <Grid item xs={5} pl={5} sx={{ display: "flex", alignItems: "center" }}>
          <Stack spacing={4} sx={{ width: 1 }}>
            <TitleSkeleton />
            <CardSkeleton />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
