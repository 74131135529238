import { OrganizationMembershipStatus, OrganizationType } from "../api/graphql";
import { useCurrentUser } from "../SessionBoundary";

export default function useMayScheduleWebinars() {
  const visitor = useCurrentUser();
  return (
    "organizationMemberships" in visitor &&
    visitor.organizationMemberships.some(
      (m) =>
        m.status === OrganizationMembershipStatus.Active &&
        m.organization.type === OrganizationType.Educational
    )
  );
}
