import { Button, Tooltip } from "@ameelio/ui";
import { Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  OrganizationMembership,
  OrganizationMembershipRole,
} from "../api/graphql";
import useApolloErrorHandler from "../lib/handleApolloError";
import { useCurrentMembership } from "../SessionBoundary";
import RemoveOrganizationMembershipModal from "./RemoveOrganizationMembershipModal";

type Props = {
  organizationMembership: Pick<OrganizationMembership, "id" | "role">;
};

export default function OrganizationMembershipActionsMenu({
  organizationMembership,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const navigate = useNavigate();

  const { role } = useCurrentMembership();
  const isAdmin = role === OrganizationMembershipRole.Admin;

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(optionsMenuAnchorEl);
  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };
  const handleOptionsClose = () => {
    setOptionsMenuAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOptionsClick}
        sx={{ flexGrow: 1 }}
      >
        {t("More options")}
      </Button>
      <Menu
        anchorEl={optionsMenuAnchorEl}
        open={open}
        onClose={handleOptionsClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Tooltip
          disabled={isAdmin}
          title={t("Only organization admins may remove members")}
        >
          <div>
            <MenuItem
              disabled={
                !isAdmin ||
                organizationMembership.role === OrganizationMembershipRole.Admin
              }
              onClick={() => {
                handleOptionsClose();
                setShowRemoveModal(true);
              }}
            >
              <Typography variant="body2" sx={{ color: "error.main" }}>
                {t("Remove team member")}
              </Typography>
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
      {showRemoveModal && (
        <RemoveOrganizationMembershipModal
          organizationMembershipId={organizationMembership.id}
          onSuccess={() => navigate(-1)}
          onCancel={() => setShowRemoveModal(false)}
          onFail={(e) => {
            setShowRemoveModal(false);
            handleApolloError(e);
          }}
        />
      )}
    </>
  );
}
