import React, { useCallback } from "react";

export default function ResetScroll() {
  const reset = useCallback((el: HTMLDivElement | null) => {
    if (!el) return;

    let parent = el.parentElement;
    while (parent) {
      if (parent.scrollHeight > parent.clientHeight) {
        parent.scrollTo(0, 0);
      }
      parent = parent.parentElement;
    }
  }, []);

  return <div ref={reset} />;
}
