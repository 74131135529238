import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useMemo, useState } from "react";
import { ConnectionStatus } from "../api/graphql";
import { useAuthContext } from "../AuthProvider";
import useApolloErrorHandler from "../lib/handleApolloError";
import { useServerEvent } from "../lib/serverEventsProvider";
import { GetMessageDocument } from "./GetMessage.generated";
import { GetUnreadMessageCountsDocument } from "./GetUnreadMessageCounts.generated";

type Context = {
  totalUnread: number;
  unread: (connectionId: string) => number;
  setCurrentTimeline: (connectionId: string | undefined) => void;
};

const context = React.createContext<Context>({
  totalUnread: 0,
  unread: () => 0,
  setCurrentTimeline: () => null,
});

type Props = {
  children: React.ReactNode;
};

export default function MessageBadgeProvider({ children }: Props) {
  const { isLoggedIn } = useAuthContext();
  const [currentTimeline, setCurrentTimeline] = useState<string | undefined>(
    undefined
  );

  const onError = useApolloErrorHandler();
  const { data, refetch } = useQuery(GetUnreadMessageCountsDocument, {
    onError,
    skip: !isLoggedIn,
  });
  const [updateMessageStatus] = useLazyQuery(GetMessageDocument, {
    onError,
    fetchPolicy: "network-only",
  });

  useServerEvent("updateConnectionTimeline", () => refetch());

  useServerEvent("updateMessageStatus", ({ id }) => {
    refetch();
    updateMessageStatus({
      variables: { id },
    });
  });

  const unreadById = useMemo(
    () =>
      Object.fromEntries(
        data?.currentCorrespondent?.connections
          .filter((c) => c.status === ConnectionStatus.Active)
          .map((c) => [c.id, c.messages.unreadMessageCount]) || []
      ),
    [data]
  );

  const value = useMemo(
    (): Context => ({
      totalUnread: Object.entries(unreadById).reduce(
        (total, a) => total + (a[0] === currentTimeline ? 0 : a[1]),
        0
      ),
      unread: (connectionId) =>
        connectionId === currentTimeline ? 0 : unreadById[connectionId] || 0,
      setCurrentTimeline,
    }),
    [unreadById, currentTimeline]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useMessageBadges() {
  return useContext(context);
}
