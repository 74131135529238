import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";

const key = import.meta.env.VITE_AMPLITUDE_API_KEY;
if (key) amplitude.init(key);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function track(name: string, props?: Record<string, any>) {
  // eslint-disable-next-line no-console
  if (import.meta.env.DEV) console.log(`track: ${name}`, props);
  amplitude.track(name, { ...props, host: window.location.host });
}

/**
 * identify will only set a user id.
 * we are not allowed to identify inmates with personal information.
 */
export function identify(userId: string) {
  amplitude.setUserId(userId);
  Sentry.setUser({ id: userId });
}

export function unidentify() {
  amplitude.setUserId(undefined);
  Sentry.setUser(null);
}
