import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { shortMonthDateTime } from "../lib/timeFormats";

type Props = {
  expiresAt?: number | null;
};

export default function MessagingRestrictedAlert({ expiresAt }: Props) {
  const { t } = useTranslation();

  return (
    <Alert severity="error">
      <AlertTitle sx={{ ...(!expiresAt ? { mb: 0 } : {}) }}>
        {t("Communication with this person is currently blocked")}
      </AlertTitle>
      {expiresAt &&
        t("Messaging access will return on {{restrictionExpireDate}}.", {
          restrictionExpireDate: shortMonthDateTime(expiresAt),
        })}
    </Alert>
  );
}
