import { uniq } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import i18next from "i18next";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import { ConnectionStatus, OrganizationMembershipRole } from "../api/graphql";
import buildPageTitle from "../lib/buildPageTitle";
import { ContentSkeleton } from "../lib/closet";
import ScreenHeader from "../lib/ScreenHeader";
import { useCurrentMembership, useCurrentVisitor } from "../SessionBoundary";
import {
  GetOrganizationDocument,
  GetOrganizationQuery,
} from "./GetOrganization.generated";
import InviteTeamMemberButton from "./InviteTeamMemberButton";
import ActiveConnectionsTable from "./OrganizationContacts/ActiveConnectionsTable";
import ContactsFilter from "./OrganizationContacts/ContactsFilter";
import OrganizationInfo from "./OrganizationInfo";
import OrganizationMembers from "./OrganizationMemberships";
import OrganizationPartners from "./OrganizationPartners";

const paramTabs = ["team", "partners", "info"] as const;
export type ParamTab = (typeof paramTabs)[number];

export enum OrganizationTab {
  team,
  contacts,
  partners,
  info,
}

type Props = {
  tab: OrganizationTab;
};

type Connection = GetOrganizationQuery["organization"]["allConnections"][0];

function inferProfileTitle(tab: OrganizationTab) {
  switch (tab) {
    case OrganizationTab.team:
      return i18next.t("Team members");
    case OrganizationTab.contacts:
      return i18next.t("Team contacts");
    case OrganizationTab.partners:
      return i18next.t("Partners");
    case OrganizationTab.info:
      return i18next.t("Info");
    default:
      throw new Error(`invalid tab ${tab}`);
  }
}

export default function OrganizationScreen({ tab }: Props) {
  const { t } = useTranslation();
  const user = useCurrentVisitor();
  const { role } = useCurrentMembership();
  const isAdmin = role === OrganizationMembershipRole.Admin;
  const [connections, setConnections] = useState<Connection[]>([]);
  const [noConnectionsFound, setNoConnectionsFound] = useState<boolean>(false);
  const { organizationId } = useParams<{ organizationId: string }>();
  if (!organizationId) throw new Error("missing id param");

  const { data, error } = useQuery(GetOrganizationDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationId },
  });

  const facilities = useMemo(
    () =>
      data?.organization.allConnections
        ? uniq(
            data?.organization.allConnections.map((c) => c.inmate.facility.name)
          )
        : [],
    [data]
  );

  if (error) throw error;
  if (!data) return <ContentSkeleton />;
  let action;
  if (tab === OrganizationTab.team) {
    action = (
      <InviteTeamMemberButton
        isAdmin={isAdmin}
        organizationId={organizationId}
        sx={{ flexGrow: 1 }}
      />
    );
  }

  const pageTitle = buildPageTitle(inferProfileTitle(tab), t("Organization"));

  return (
    <Box sx={{ width: "100%", pb: 5 }}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ScreenHeader action={action}>{data.organization.name}</ScreenHeader>
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          width: "100%",
          mb: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Tabs value={tab}>
            <Tab
              label={inferProfileTitle(OrganizationTab.team)}
              component={RouterLink}
              to={`/organization/${organizationId}/team`}
            />
            <Tab
              label={inferProfileTitle(OrganizationTab.contacts)}
              component={RouterLink}
              to={`/organization/${organizationId}/contacts`}
            />
            <Tab
              label={inferProfileTitle(OrganizationTab.partners)}
              component={RouterLink}
              to={`/organization/${organizationId}/partners`}
            />
            <Tab
              label={inferProfileTitle(OrganizationTab.info)}
              component={RouterLink}
              to={`/organization/${organizationId}/info`}
            />
          </Tabs>
        </Box>
      </Box>
      <Box hidden={tab !== OrganizationTab.team}>
        <OrganizationMembers organizationId={organizationId} />
      </Box>
      <Box hidden={tab !== OrganizationTab.contacts}>
        <ContactsFilter
          key={tab}
          connections={data.organization.allConnections}
          facilities={facilities}
          onSearch={(isSearchNonempty: boolean, conns: Connection[]) => {
            const activeConns = conns.filter(
              (c) => c.status === ConnectionStatus.Active
            );
            setNoConnectionsFound(isSearchNonempty && activeConns.length === 0);
            setConnections(activeConns);
          }}
        />
        <ActiveConnectionsTable
          visitorId={user.id}
          noConnectionsFound={noConnectionsFound}
          organizationId={organizationId}
          connections={connections}
        />
      </Box>
      <Box hidden={tab !== OrganizationTab.partners}>
        <OrganizationPartners organizationId={organizationId} />
      </Box>
      <Box hidden={tab !== OrganizationTab.info} sx={{ width: 1 }}>
        <Card variant="outlined">
          <CardContent>
            <OrganizationInfo organizationId={organizationId} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
