import i18n from "i18next";
import featureEnabled from "./featureEnabled";

export default function getLanguageDirection(language = i18n.language) {
  if (!featureEnabled("RTL_SUPPORT")) {
    return "ltr";
  }
  // To test it, we used this: language === "es" ? "rtl" : i18n.dir(language)
  return i18n.dir(language);
}
