import { useCallback } from "react";
import { ConnectionStatus } from "../api/graphql";
import { useCurrentVisitor } from "../SessionBoundary";

export default function useCurrentConnectionStatus() {
  const me = useCurrentVisitor();
  return useCallback(
    (inmateId: string) =>
      me.connections.find(
        (c) =>
          c.inmate.id === inmateId &&
          [ConnectionStatus.Active, ConnectionStatus.Pending].includes(c.status)
      )?.status || ConnectionStatus.Inactive,
    [me.connections]
  );
}
