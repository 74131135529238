import { Avatar } from "@ameelio/ui";
import { AvatarGroup, SxProps, Theme } from "@mui/material";
import React from "react";
import { Connection, Visitor } from "../../api/graphql";

type Props = {
  max: number;
  connections: (Pick<Connection, "id"> & {
    visitor: Pick<Visitor, "firstName" | "lastName" | "fullName">;
  })[];
  spacing?: "small" | "medium" | number;
  sx?: SxProps<Theme>;
};

export default function ConnectionsAvatarGroup({
  max,
  spacing,
  connections,
  sx,
}: Props) {
  const surplus = connections.length - max;
  const last = connections[max - 1];

  return (
    <AvatarGroup spacing={spacing || "medium"} sx={{ ...(sx || {}) }}>
      {connections.slice(0, max - 1).map((c) => (
        <Avatar key={c.id} contact={c.visitor} tooltip />
      ))}
      {surplus === 0 && <Avatar key={last.id} contact={last.visitor} tooltip />}
      {surplus > 0 && <Avatar>+{surplus + 1}</Avatar>}
    </AvatarGroup>
  );
}
