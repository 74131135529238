import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import { SearchQuery } from "./types";

type Props = {
  visitors: {
    __typename: "Visitor";
    id: string;
    fullName: string;
    email: string;
  }[];
  filters: SearchQuery;
  loading: boolean;
};

export default function VisitorsTable({ visitors, filters, loading }: Props) {
  const { t } = useTranslation();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: visitors,
    resetOnChange: [filters],
  });
  if (loading) return <ContentSkeleton />;

  return (
    <>
      <TableContainer>
        <Table aria-label={t("Visitors table")}>
          {visitors.length === 0 && (
            <TableBody>
              <TableNoData />
            </TableBody>
          )}
          {visitors.length > 0 && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Full Name")}</TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.map((visitor) => (
                  <TableRow key={visitor.id}>
                    <TableCell>{visitor.fullName}</TableCell>
                    <TableCell>{visitor.email}</TableCell>
                    <TableCell>
                      <Link to={`/toolbox/visitors/${visitor.id}`}>
                        {t("View profile")}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
        <TablePagination
          key="visitorsTable-pagination"
          totalCount={visitors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
        />
      </TableContainer>
      {visitors.length > 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          {t(
            "Only the newest {{count}} results are shown. Please search to find a specific visitor.",
            { count: 100 } // Needs to match the limit seen in `src/services/searchVisitors.ts` in Connect API
          )}
        </Alert>
      )}
    </>
  );
}
