import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "../api/graphql";

export default function useConnectionStatusTooltipCopy() {
  const { t } = useTranslation();
  const getCopy = useCallback(
    (status: ConnectionStatus | undefined) => {
      switch (status) {
        case ConnectionStatus.Active:
          return t("You're already connected with this person.");
        case ConnectionStatus.Pending:
          return t("You have a request currently pending for this person.");
        default:
          return "";
      }
    },
    [t]
  );
  return getCopy;
}
