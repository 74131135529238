import { useSnackbarContext } from "@ameelio/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";
import {
  Call,
  Correspondent,
  Facility,
  Interval,
  Meeting,
  MeetingType,
} from "../../api/graphql";
import retryImport from "../../lib/retryImport";
import { useCurrentCorrespondent } from "../../SessionBoundary";
import { GetMeetingInfoQuery } from "../GetMeetingInfo.generated";
import peerEnterSound from "./sounds/enterCall.mp3";
import peerExitSound from "./sounds/exitCall.mp3";
import { ConnectionInfo } from "./types/ConnectionInfo";
import CustomBackground from "./types/CustomBackground";
import VideoCallInterface from "./VideoCallInterface";
import VoiceCallInterface from "./VoiceCallInterface";
import WebinarCallInterface from "./WebinarCallInterface";

const CiscoCallConnection = React.lazy(() =>
  retryImport(() => import("./CiscoCallConnection"))
);
const ConnectCallConnection = React.lazy(() =>
  retryImport(() => import("./ConnectCallConnection"))
);

type Props = {
  meeting: Pick<Meeting, "id" | "isCisco" | "meetingType" | "privacyLevel"> & {
    interval: Pick<Interval, "startAt" | "endAt">;
    call?: Pick<Call, "url" | "token" | "ciscoJWT"> | null;
    correspondents: Pick<
      Correspondent,
      "id" | "firstName" | "lastName" | "fullName"
    >[];
    connections?: GetMeetingInfoQuery["meeting"]["connections"];
    facility: Pick<Facility, "id" | "publicId">;
  };
  onLeave: () => void;
  beginMuted: boolean;
  beginHidden: boolean;
  alerts: boolean;
  onToggleAlerts: () => void;
  onPeerJoined: () => void;
  onTerminated: () => void;
  onConnectedElsewhere: () => void;
  onAudioReady: () => void;
  onEnded: () => void;
};

export default function LiveCall({
  meeting,
  beginMuted,
  beginHidden,
  onTerminated,
  onConnectedElsewhere,
  onAudioReady,
  onEnded,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const [playPeerEnter] = useSound(peerEnterSound, { volume: 0.5 });
  const [playPeerExit] = useSound(peerExitSound, { volume: 0.5 });

  const [customBackground, setCustomBackground] = useState<CustomBackground>(
    CustomBackground.NONE
  );

  const user = useCurrentCorrespondent();

  const { isCisco, interval, call, privacyLevel } = meeting;

  if (!call) throw new Error(`invalid call data`);

  const onMonitorJoined = () => {
    // Webinar hosts should be alerted with a toast
    // when a staff monitor joins
    if (
      meeting.meetingType === MeetingType.Webinar &&
      user.__typename === "Visitor"
    ) {
      snackbarContext.alert(
        "info",
        t("A staff monitor joined and is observing your webinar.")
      );
      playPeerEnter();
    }
  };

  const onPeerConnected = () => {
    if (meeting.meetingType !== MeetingType.Webinar) playPeerEnter();
  };

  const onPeerDisconnected = () => {
    if (meeting.meetingType !== MeetingType.Webinar) playPeerExit();
  };

  if (isCisco) {
    if (!call.ciscoJWT) throw new Error(`invalid call data`);

    return (
      <CiscoCallConnection
        call={{
          id: meeting.id,
          ciscoJWT: call.ciscoJWT,
        }}
        muteAudio={beginMuted}
        muteVideo={beginHidden}
        onPeerConnected={onPeerConnected}
        onPeerDisconnected={onPeerDisconnected}
      >
        {(connectionInfo: ConnectionInfo) => (
          <VideoCallInterface
            facilityName={meeting.facility.publicId}
            connectionInfo={connectionInfo}
            user={user}
            correspondents={meeting.correspondents}
            privacyLevel={privacyLevel}
            interval={interval}
            beginMuted={beginMuted}
            beginHidden={beginHidden}
            {...rest}
          />
        )}
      </CiscoCallConnection>
    );
  }

  if (!call.url || !call.token) throw new Error(`invalid call data`);

  return (
    <ConnectCallConnection
      call={{
        id: meeting.id,
        url: call.url,
        token: call.token,
      }}
      meetingType={meeting.meetingType}
      muteAudio={beginMuted}
      muteVideo={beginHidden}
      voiceCall={meeting.meetingType === MeetingType.VoiceCall}
      user={{ id: user.id }}
      onMonitorJoined={onMonitorJoined}
      onPeerConnected={onPeerConnected}
      onPeerDisconnected={onPeerDisconnected}
      onTerminated={onTerminated}
      onEnded={onEnded}
      onConnectedElsewhere={onConnectedElsewhere}
      onAudioReady={onAudioReady}
      customBackground={customBackground}
    >
      {(connectionInfo: ConnectionInfo) =>
        meeting.meetingType === MeetingType.VoiceCall ? (
          <VoiceCallInterface
            privacyLevel={meeting.privacyLevel}
            correspondents={meeting.correspondents}
            connections={meeting.connections}
            connectionInfo={connectionInfo}
            user={user}
            interval={interval}
            {...rest}
          />
        ) : meeting.meetingType === MeetingType.Webinar ? (
          <WebinarCallInterface
            facilityName={meeting.facility.publicId}
            connectionInfo={connectionInfo}
            user={user}
            correspondents={meeting.correspondents}
            privacyLevel={privacyLevel}
            interval={interval}
            customBackground={customBackground}
            onSetCustomBackground={setCustomBackground}
            {...rest}
          />
        ) : (
          <VideoCallInterface
            facilityName={meeting.facility.publicId}
            connectionInfo={connectionInfo}
            user={user}
            correspondents={meeting.correspondents}
            privacyLevel={privacyLevel}
            interval={interval}
            beginMuted={beginMuted}
            beginHidden={beginHidden}
            customBackground={customBackground}
            onSetCustomBackground={(c: CustomBackground) =>
              setCustomBackground(c)
            }
            {...rest}
          />
        )
      }
    </ConnectCallConnection>
  );
}
