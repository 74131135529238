import React from "react";
import { useTranslation } from "react-i18next";
import { Meeting, MeetingType } from "../api/graphql";
import Link from "../lib/Link";
import { useCurrentCorrespondent } from "../SessionBoundary";

type Props = {
  meeting: Pick<Meeting, "id" | "status" | "meetingType">;
};

export default function JoinCallButton({ meeting }: Props) {
  const { t } = useTranslation();
  const currentUser = useCurrentCorrespondent();
  return (
    <Link
      to={`/call/${meeting.id}`}
      button
      variant="contained"
      color="primary"
      sx={{ width: "100%" }}
    >
      {meeting.meetingType === MeetingType.Webinar
        ? currentUser.__typename === "Visitor"
          ? t("Start class")
          : t("Join class")
        : t("Join call")}
    </Link>
  );
}
