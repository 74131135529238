/**
 * videoStreamDimensions only works for locally acquired media streams. the
 * defining feature is that it works around a missing aspectRatio property in
 * Firefox.
 */
export default function videoStreamDimensions(stream: MediaStream | undefined) {
  const vt = stream?.getVideoTracks()[0];
  if (!vt) return { height: 0, width: 0, aspectRatio: 1 };
  const { width, height } = vt.getSettings();
  if (!width || !height) return { height: 0, width: 0, aspectRatio: 1 };
  return {
    width,
    height,
    aspectRatio: width / height,
  };
}
