const features = [
  "RECEIVE_VOICE_CALLS",
  "MEDIA_SHARING",
  "DOCUMENT_SHARING",
  "SIMULATE_PING_LATENCY",
  "RTL_SUPPORT",
] as const;
type Feature = (typeof features)[number];

export default function featureEnabled(name: Feature): boolean {
  const feature = import.meta.env[`VITE_ENABLE_${name}`];
  // If there was a string value provided, it MUST be 'true' when lowered and trimmed
  return feature ? feature.toLowerCase().trim() === "true" : !!feature;
}
