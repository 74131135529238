import { t } from "i18next";
import { MeetingType, PrivacyLevel } from "../api/graphql";

export default function privacyLevelDescription(
  meetingType: MeetingType,
  privacyLevel: PrivacyLevel
): string {
  if (privacyLevel === PrivacyLevel.Monitored) {
    return meetingType === MeetingType.InPersonVisit
      ? t("This visit is monitored.")
      : t("This call may be monitored and recorded.");
  }
  if (privacyLevel === PrivacyLevel.UnmonitoredLogged) {
    return meetingType === MeetingType.InPersonVisit
      ? t("This visit is not monitored.")
      : meetingType === MeetingType.VoiceCall
        ? t(
            "This call cannot be monitored or recorded. Staff will be able to see who you talked with, but not what you said."
          )
        : // remarking on staff being able to see who you talked with isn't useful for
          // scheduled events that were approved by staff.
          t(
            "This call cannot be monitored or recorded, but low-quality screenshots are taken at random times for staff to review."
          );
  }
  if (privacyLevel === PrivacyLevel.Hidden) {
    return meetingType === MeetingType.InPersonVisit
      ? t("This visit is off the record.")
      : t("This call is off the record.");
  }
  // in case the backend sends a new privacyLevel
  return "";
}

export function privacyLevelDisclaimer(
  meetingType: MeetingType,
  privacyLevel: PrivacyLevel
): string {
  if (meetingType === MeetingType.InPersonVisit)
    return privacyLevelDescription(meetingType, privacyLevel);

  if (privacyLevel === PrivacyLevel.Monitored) {
    return t(
      "By participating in this call, I consent to the potential monitoring and recording of my video, audio, and in-call chat messages by the affiliated correctional facility. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
    );
  }
  if (privacyLevel === PrivacyLevel.UnmonitoredLogged)
    return t(
      "This call is confidential and will not be recorded. For the purpose of institutional security, to ensure that only approved visitors are present during the call, several low-quality screenshots will be taken at random times during the call. Select facility staff members will be able to view and download these screenshots for participant verification purposes only. You will also be able to view these screenshots on this event’s details page."
    );
  if (privacyLevel === PrivacyLevel.Hidden)
    return t(
      "This call is off the record and will not be monitored or recorded. Staff will not be able to see that you made a call."
    );

  // in case the backend sends a new privacyLevel
  return privacyLevelDescription(meetingType, privacyLevel);
}
