import {
  Box,
  Grid,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React from "react";
import addStylesForDevices from "../lib/addStylesForDevices";
import { belowLargeTablet } from "../lib/responsiveHelpers";
import LogIn from "./LogIn.svg";

type Props = {
  corner?: React.ReactNode;
  children: React.ReactNode;
};

const cornerBaseStyles: SxProps<Theme> = {
  position: "absolute",
  top: (theme: Theme) => theme.spacing(4),
  display: "flex",
  justifyContent: "center",
};

export default function AuthenticationLayout({ corner, children }: Props) {
  const isBelowLargeTablet = belowLargeTablet(measureScreenWidth);
  const gridContainerStyles = addStylesForDevices(
    isBelowLargeTablet,
    { backgroundColor: "#E8EBF7" },
    { display: "none" }
  );
  return (
    <Grid container sx={{ height: 1 }}>
      {corner && !isBelowLargeTablet && (
        <Box
          sx={{
            ...cornerBaseStyles,
            left: (theme: Theme) => theme.spacing(4),
          }}
        >
          {corner}
        </Box>
      )}
      <Grid item xs={11} sm={10} md={6} sx={{ marginX: "auto" }}>
        {corner && isBelowLargeTablet && (
          <Grid item xs={11} sx={cornerBaseStyles}>
            {corner}
          </Grid>
        )}
        <Grid sx={{ marginTop: { xs: 8, sm: 10 }, paddingY: 10 }}>
          {children}
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={3}
        sx={gridContainerStyles}
        justifyContent="center"
        alignContent="center"
        minHeight={{ md: 766, xl: 700 }}
        maxHeight={1}
        overflow="hidden"
      >
        <img src={LogIn} alt="Ameelio Connect" width="100%" />
      </Grid>
    </Grid>
  );
}
