import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FacilityApprovedPhoneFragmentFragmentDoc } from '../api/FacilityApprovedPhoneFragment.generated';
import { GroupFragmentFragmentDoc } from '../api/GroupFragment.generated';
export type GetContactsScreenDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetContactsScreenDataQuery = { __typename: 'Query', currentInmate: { __typename: 'Inmate', id: string, facility: { __typename: 'Facility', id: string, name: string, timezone: string, approvedPhones: Array<{ __typename: 'FacilityApprovedPhone', id: string, name: string, description: string, number: string, privacyLevel: Types.PrivacyLevel, active: boolean }> }, group: { __typename: 'Group', hasVoiceCallSlots: boolean, id: string, name: string, currentVoiceCallSlot: { __typename: 'Interval', startAt: number, endAt: number } | null, nextVoiceCallSlot: { __typename: 'Interval', startAt: number, endAt: number } | null } } | null };


export const GetContactsScreenDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetContactsScreenData"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentInmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"timezone"}},{"kind":"Field","name":{"kind":"Name","value":"approvedPhones"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityApprovedPhoneFragment"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"group"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"GroupFragment"}},{"kind":"Field","name":{"kind":"Name","value":"hasVoiceCallSlots"}},{"kind":"Field","name":{"kind":"Name","value":"currentVoiceCallSlot"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startAt"}},{"kind":"Field","name":{"kind":"Name","value":"endAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextVoiceCallSlot"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startAt"}},{"kind":"Field","name":{"kind":"Name","value":"endAt"}}]}}]}}]}}]}},...FacilityApprovedPhoneFragmentFragmentDoc.definitions,...GroupFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetContactsScreenDataQuery, GetContactsScreenDataQueryVariables>;