import { groupBy } from "@ameelio/core";
import { SelectInput, SubmitButton } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import buildPageTitle from "../lib/buildPageTitle";
import getFrom from "../lib/getFrom";
import useApolloErrorHandler from "../lib/handleApolloError";
import { ScreenTitle } from "../lib/typography";
import uniqBy from "../lib/uniqBy";
import { isRequired } from "../lib/validate";
import {
  GetFacilitiesDocument,
  GetFacilitiesQuery,
} from "./GetFacilities.generated";

export type SelectFacilityData = {
  system: string;
};

const getSystemFacilitiesLabel = (
  facilities?: GetFacilitiesQuery["facilities"]
) => (facilities || []).map((f) => f.name).join(", ");

function FacilitySelectScreen() {
  const { t } = useTranslation();

  const handleApolloError = useApolloErrorHandler();

  const navigate = useNavigate();
  const location = useLocation();
  const from = getFrom(location);

  const { data, error } = useQuery(GetFacilitiesDocument, {
    onError: (e) => handleApolloError(e),
  });

  if (error) throw error;

  const systems = uniqBy(
    data?.facilities.map((facility) => facility.system) || [],
    "code"
  );
  const systemsWithFacilities = groupBy(
    data?.facilities || [],
    (r) => r.system.id
  );

  const {
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<SelectFacilityData>({
    mode: "onChange",
  });

  const headerTitle = t("Choose your system");
  const pageTitle = buildPageTitle(headerTitle);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ScreenTitle>{headerTitle}</ScreenTitle>
      <Typography sx={{ marginTop: 2 }}>
        {t("Connect directly with people through video, messaging, and more.")}
      </Typography>
      <form
        onSubmit={handleFormSubmit((formData) =>
          navigate(`/login/${formData.system.toLowerCase()}`, {
            state: { from },
          })
        )}
      >
        <Stack spacing={2} sx={{ marginY: 6 }}>
          <SelectInput
            control={control}
            name="system"
            label={t("System")}
            defaultValue=""
            rules={isRequired(t("Please select a system."))}
            items={systems.map((system) => ({
              value: system.code,
              name: system.name,
              helperText:
                getSystemFacilitiesLabel(systemsWithFacilities[system.id]) ||
                "",
            }))}
          />
        </Stack>
        <Grid container justifyContent="flex-end">
          <SubmitButton submitting={isSubmitting} disabled={!isValid}>
            {t("Continue")}
          </SubmitButton>
        </Grid>
      </form>
    </>
  );
}

export default FacilitySelectScreen;
