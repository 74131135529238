import {
  darkPalette as uiDarkPalette,
  getBGColor,
  lightPalette as uiLightPalette,
  ltrTheme as uiLtrTheme,
  rtlTheme as uiRtlTheme,
  shade,
} from "@ameelio/ui";

// default values: https://mui.com/material-ui/customization/default-theme
export const ltrTheme = uiLtrTheme;
export const rtlTheme = uiRtlTheme;

export const lightPalette = uiLightPalette;
export const darkPalette = uiDarkPalette;

export { shade, getBGColor };
