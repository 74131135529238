import React from "react";
import { RouteObject } from "react-router-dom";
import SupportScreen from "./SupportScreen";

const SupportRoutes: RouteObject = {
  path: "/support",
  element: <SupportScreen />,
};

export default SupportRoutes;
