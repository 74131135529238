import { OrganizationMembershipStatus } from "../api/graphql";
import { GetCurrentUserQuery } from "../GetCurrentUser.generated";

function activeOrgMembership(user: GetCurrentUserQuery["me"]) {
  if (!user) return null;
  if (user.__typename !== "Visitor") return null;
  if (!user.organizationMemberships) return null;
  return user.organizationMemberships.find(
    (membership) => membership.status === OrganizationMembershipStatus.Active
  );
}

export default activeOrgMembership;
