import { useEffect, useState } from "react";

type DeviceInfo = InputDeviceInfo | MediaDeviceInfo;

export default function useLocalMedia() {
  const [userDevices, setUserDevices] = useState<{
    audio: DeviceInfo[];
    video: DeviceInfo[];
  }>({
    audio: [],
    video: [],
  });
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((info) =>
      setUserDevices({
        audio: info.filter((r) => r.kind === "audioinput"),
        video: info.filter(
          (r) => r.kind === "videoinput" && !r.label.match(/virtual/gi) // Filter out any virtual cameras
        ),
      })
    );
  }, []);
  return userDevices;
}
