export const Steps = [
  "searchInmates",
  "results",
  "searchMinors",
  "minorsResults",
  "relationship",
  "visitationForms",
  "documentType",
  "uploadIDPhotos",
  "confirmIDData",
  "finished",
] as const;
export type Step = (typeof Steps)[number];

const defaultSteps: Step[] = ["searchInmates", "results"];
const searchMinorsSteps: Step[] = ["searchMinors", "minorsResults"];
const relationshipSteps: Step[] = ["relationship"];
const visitationFormsSteps: Step[] = ["visitationForms"];
const uploadIDSteps: Step[] = [
  "documentType",
  "uploadIDPhotos",
  "confirmIDData",
];

/*
There are three main variables that determine the step list that is 
shown to the user:
1 - Possible missing requirement to upload an ID (in the case of adults)
2 - Searching for minors
3 - Showing visitation form links for search results at facilities requiring a
separate visitor application
*/
export const getSteps = ({
  satisfiedIDRequirement,
  relationship,
  searchMinors,
  visitationForms,
}: {
  satisfiedIDRequirement: boolean;
  relationship: boolean;
  searchMinors: boolean;
  visitationForms: boolean;
}): Step[] => [
  ...defaultSteps,
  ...(searchMinors ? searchMinorsSteps : []),
  ...(relationship ? relationshipSteps : []),
  ...(visitationForms ? visitationFormsSteps : []),
  ...(!satisfiedIDRequirement ? uploadIDSteps : []),
  "finished",
];
