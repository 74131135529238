import { Box, BoxProps } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import React, { useRef } from "react";

type Props = BoxProps & {
  /**
   * ratio of width over height, e.g. 3 / 2
   */
  ratio: number;

  /**
   * object-fit strategy. "cover" will effectively ignore
   * "ratio".
   */
  fit?: "contain" | "cover";
};

// from: https://www.npmjs.com/package/@react-hook/resize-observer
function useSize(target: React.RefObject<HTMLElement>) {
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size || { width: 0, height: 0 };
}

/**
 * ContainedAspectRatio will measure the maximum available space, compare
 * it to the desired aspect ratio, and create a container that behaves
 * like object-fit: contain while also shrinkwrapping to the children.
 *
 * At present, the children are always centered in the available space.
 */
export default function ContainedAspectRatio({
  ratio,
  fit,
  sx,
  ...boxProps
}: Props) {
  const ref = useRef<HTMLElement>(null);
  const max = useSize(ref);

  const containerAspectRatio = max.height ? max.width / max.height : 1;
  // when the container is taller than needed, use the max width
  const scaledWidth =
    containerAspectRatio > ratio ? max.height * ratio : max.width;
  // when the container is wider than needed, use the max height
  const scaledHeight =
    containerAspectRatio > ratio ? max.height : max.width / ratio;

  return (
    <Box
      ref={ref}
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        {...boxProps}
        sx={{
          width: fit === "cover" ? 1 : scaledWidth,
          height: fit === "cover" ? 1 : scaledHeight,
          overflow: "hidden",
          ...(sx || {}),
        }}
      />
    </Box>
  );
}
