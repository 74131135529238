import { Dot } from "@ameelio/ui";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PhoneMissedOutlinedIcon from "@mui/icons-material/PhoneMissedOutlined";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Call,
  Interval,
  Meeting,
  MeetingStatus,
  MeetingType,
} from "../api/graphql";
import Link from "../lib/Link";
import { approximateDuration } from "../lib/timeFormats";
import EventBubble from "./EventBubble";
import TimelineEventWrapper from "./TimelineEventWrapper";

export type MeetingEventObject = Pick<
  Meeting,
  "id" | "meetingType" | "status"
> & {
  call?: Pick<Call, "duration"> | null; // optional graphql fields can be null while optional args will be undefined if not given. This type union seems awkward and is there a better approach?
  interval: Pick<Interval, "startAt" | "seconds">;
};

type Props = {
  object: MeetingEventObject;
};

export default function MeetingEventItem({ object }: Props) {
  const { t } = useTranslation();

  if (
    object.meetingType === MeetingType.VoiceCall &&
    [MeetingStatus.Scheduled, MeetingStatus.Live].includes(object.status)
  )
    return <div />;

  const label =
    object.meetingType === MeetingType.VideoCall
      ? t("Video call")
      : object.meetingType === MeetingType.Webinar
        ? t("Webinar")
        : object.meetingType === MeetingType.VoiceCall
          ? object.status === MeetingStatus.NoShow
            ? t("Missed voice call")
            : t("Voice call")
          : t("Visit");

  const Icon =
    object.meetingType === MeetingType.VideoCall
      ? VideocamOutlinedIcon
      : object.meetingType === MeetingType.Webinar
        ? ScreenShareOutlinedIcon
        : object.meetingType === MeetingType.VoiceCall
          ? object.status === MeetingStatus.NoShow
            ? PhoneMissedOutlinedIcon
            : LocalPhoneOutlinedIcon
          : PeopleOutlinedIcon;

  const duration =
    object.call?.duration !== undefined && object.call?.duration !== null // 0 is falsey, but we should accept zero here so we need to check against only the other falsey values.
      ? object.call?.duration
      : (object.interval.seconds || 0) * 1000;

  return (
    <TimelineEventWrapper
      alignment="right"
      timestamp={object.interval.startAt}
      isAvailable
    >
      <EventBubble color={grey[50]}>
        <Dot size={32} color={grey[200]}>
          <Icon sx={{ color: grey[600] }} />
        </Dot>
        <Box ml={1}>
          <Typography variant="subtitle2">
            <Link
              to={`/events/${object.id}`}
              color="text.primary"
              sx={{ textDecoration: "underline" }}
            >
              {label}
            </Link>
          </Typography>
          {!(
            object.meetingType === MeetingType.VoiceCall &&
            object.status === MeetingStatus.NoShow
          ) && (
            <Typography variant="caption" color="text.secondary">
              {approximateDuration(duration)}
            </Typography>
          )}
        </Box>
      </EventBubble>
    </TimelineEventWrapper>
  );
}
