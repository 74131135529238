import { IconButton } from "@ameelio/ui";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {
  alpha,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { common } from "@mui/material/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { belowLargeTablet } from "../lib/responsiveHelpers";
import { darkPalette, lightPalette } from "../theme";

const offStyle: SxProps<Theme> = {
  "&:hover": {
    backgroundColor: lightPalette.primary.dark,
    color: common.white,
  },
};

const controlBkgLightHoverStyle: SxProps<Theme> = {
  "&:hover": {
    backgroundColor: common.white,
  },
};

export type Props = {
  muted: boolean;
  hidden: boolean;
  micDisabled?: boolean;
  micDisabledHelperText?: string;
  onToggleAudio: () => void;
  onToggleVideo?: () => void;
  controlBkgLight?: boolean | false;
};

export default function MediaControls({
  muted,
  hidden,
  micDisabled,
  micDisabledHelperText,
  onToggleAudio,
  onToggleVideo,
  controlBkgLight,
}: Props) {
  const { t } = useTranslation();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const micDisabledStyles = {
    backgroundColor:
      isMobileOrSmallTablet && controlBkgLight
        ? darkPalette.action.disabledBackground
        : lightPalette.action.disabledBackground,
    color:
      isMobileOrSmallTablet && controlBkgLight
        ? darkPalette.action.disabled
        : lightPalette.action.disabled,
  };
  return (
    <>
      {micDisabled ? (
        <IconButton
          tooltip
          ariaLabel={micDisabledHelperText || t("Your microphone is disabled")}
          sx={{
            ...micDisabledStyles,
            "&:hover": micDisabledStyles,
          }}
        >
          <MicOffOutlinedIcon />
        </IconButton>
      ) : (
        <IconButton
          tooltip
          onClick={onToggleAudio}
          ariaLabel={muted ? t("Turn on microphone") : t("Mute microphone")}
          sx={{
            backgroundColor: (th) =>
              muted
                ? lightPalette.primary.main
                : isMobileOrSmallTablet && controlBkgLight
                  ? alpha(th.palette.background.default, 0.8)
                  : "auto",
            color: muted ? common.white : "auto",
            ...(muted
              ? offStyle
              : isMobileOrSmallTablet && controlBkgLight
                ? controlBkgLightHoverStyle
                : {}),
            "&:hover": {
              backgroundColor: lightPalette.primary.main,
              color: common.white,
            },
          }}
        >
          {muted ? <MicOffOutlinedIcon /> : <MicNoneIcon fontSize="medium" />}
        </IconButton>
      )}
      {onToggleVideo && (
        <IconButton
          tooltip
          onClick={onToggleVideo}
          ariaLabel={hidden ? t("Turn on camera") : t("Turn off camera")}
          sx={{
            backgroundColor: (th) =>
              hidden
                ? lightPalette.primary.main
                : isMobileOrSmallTablet && controlBkgLight
                  ? alpha(th.palette.background.default, 0.8)
                  : "auto",
            color: hidden ? common.white : "auto",
            ...(hidden
              ? offStyle
              : isMobileOrSmallTablet && controlBkgLight
                ? controlBkgLightHoverStyle
                : {}),
            "&:hover": {
              backgroundColor: lightPalette.primary.main,
              color: common.white,
            },
          }}
        >
          {hidden ? <VideocamOffOutlinedIcon /> : <VideocamOutlinedIcon />}
        </IconButton>
      )}
    </>
  );
}
