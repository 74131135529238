import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from '../api/ConnectionFragment.generated';
import { VisitorFragmentFragmentDoc } from '../api/VisitorFragment.generated';
import { InmateFragmentFragmentDoc } from '../api/InmateFragment.generated';
import { FacilityFragmentFragmentDoc } from '../api/FacilityFragment.generated';
export type GetConnectionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConnectionsQuery = { __typename: 'Query', currentVisitor: { __typename: 'Visitor', id: string, connections: Array<{ __typename: 'Connection', features: Array<Types.ConnectionFeature>, id: string, status: Types.ConnectionStatus, relationship: string, confidential: boolean, createdAt: number, visitor: { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, email: string }, inmate: { __typename: 'Inmate', id: string, inmateIdentification: string, firstName: string, lastName: string, fullName: string, facility: { __typename: 'Facility', features: Array<Types.FacilityFeature>, unaccompaniedVisitAge: number, visitGuestRegistrationRequired: boolean, id: string, name: string, publicId: string } }, activeRestrictions: Array<{ __typename: 'ConnectionRestriction', id: string, service: Types.FacilityService }> }> } | null };


export const GetConnectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConnections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentVisitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"inmate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InmateFragment"}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacilityFragment"}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"unaccompaniedVisitAge"}},{"kind":"Field","name":{"kind":"Name","value":"visitGuestRegistrationRequired"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"activeRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"service"}}]}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions,...InmateFragmentFragmentDoc.definitions,...FacilityFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetConnectionsQuery, GetConnectionsQueryVariables>;