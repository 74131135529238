import { ConfirmDialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { evictItem } from "../client";
import { DeactivateOrganizationMembershipDocument } from "./DeactivateOrganizationMembership.generated";

type Props = {
  organizationMembershipId?: string;
  onSuccess: () => void;
  onCancel: () => void;
  onFail: (error: unknown) => void;
};

export default function RemoveOrganizationMembershipModal({
  organizationMembershipId,
  onSuccess,
  onCancel,
  onFail,
}: Props) {
  const { t } = useTranslation();
  const [removeMemberMutateFunction] = useMutation(
    DeactivateOrganizationMembershipDocument,
    {
      update: (_, { data: d }) => {
        if (!d) return;
        evictItem(d.deactivateOrganizationMembership.organizationMembership);
      },
    }
  );
  const removeMember = async () => {
    if (organizationMembershipId) {
      try {
        await removeMemberMutateFunction({
          variables: {
            input: {
              organizationMembershipId,
            },
          },
        });
      } catch (error) {
        onFail(error);
        return;
      }
      onSuccess();
    }
  };

  if (!organizationMembershipId) return null;

  return (
    <ConfirmDialog
      title={t("Remove team member?")}
      description={t(
        "Removing this team member will remove their access from Ameelio and delete any connections they have."
      )}
      variant="remove"
      onCancel={() => onCancel()}
      onConfirm={() => removeMember()}
    />
  );
}
