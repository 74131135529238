import { OrganizationType } from "../api/graphql";
import i18n from "../i18n/i18nConfig";

export default function organizationTypeLabel(value: OrganizationType) {
  const Labels: Record<OrganizationType, string> = {
    [OrganizationType.Legal]: i18n.t("Legal"),
    [OrganizationType.Educational]: i18n.t("Educational"),
    [OrganizationType.Healthcare]: i18n.t("Healthcare"),
    [OrganizationType.Other]: i18n.t("Other"),
  };
  return Labels[value];
}
