import { InformationDialog } from "@ameelio/ui";
import { List } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { MeetingType } from "../../../api/graphql";
import { ltrTheme } from "../../../theme";

type DialogVisitorType = "personal" | "organization";

type Props = {
  meetingType: MeetingType;
  visitorType: DialogVisitorType;
  onClose: () => void;
};

function ListItem({ children }: { children: string }) {
  return (
    <li
      style={{
        marginTop: ltrTheme.spacing(1.5),
        marginBottom: ltrTheme.spacing(1.5),
      }}
    >
      {children}
    </li>
  );
}

function CommonContent({ visitorType }: { visitorType: DialogVisitorType }) {
  const { t } = useTranslation();
  return (
    <>
      <ListItem>
        {t(
          "Some time slots have a limit to how many guests they can accommodate."
        )}
      </ListItem>
      {visitorType === "personal" && (
        <>
          <ListItem>
            {t(
              "Guests aged 13 and older must have registered Connect accounts and be approved to visit."
            )}
          </ListItem>
          <ListItem>
            {t(
              "Children 12 and under don’t need an account but must still be on the guest list."
            )}
          </ListItem>
        </>
      )}
    </>
  );
}

function VideoCallDialogContent({
  visitorType,
}: {
  visitorType: DialogVisitorType;
}) {
  const { t } = useTranslation();
  return (
    <List sx={{ listStyleType: "disc", px: 2 }}>
      <ListItem>
        {t("Only the organizer's device may join the video call.")}
      </ListItem>
      <ListItem>
        {t("Everyone in the video must be added to the guest list.")}
      </ListItem>
      <CommonContent visitorType={visitorType} />
    </List>
  );
}

function InPersonVisitDialogContent({
  visitorType,
}: {
  visitorType: DialogVisitorType;
}) {
  const { t } = useTranslation();
  return (
    <List sx={{ listStyleType: "disc", px: 2 }}>
      <ListItem>
        {t("Everyone in your group must be added to the guest list.")}
      </ListItem>
      <CommonContent visitorType={visitorType} />
    </List>
  );
}

export default function LearnMoreDialog({
  meetingType,
  visitorType,
  onClose,
}: Props) {
  const { t } = useTranslation();
  return (
    <InformationDialog title={t("Guests policy")} onClose={onClose}>
      {meetingType === MeetingType.VideoCall && (
        <VideoCallDialogContent visitorType={visitorType} />
      )}
      {meetingType === MeetingType.InPersonVisit && (
        <InPersonVisitDialogContent visitorType={visitorType} />
      )}
    </InformationDialog>
  );
}
