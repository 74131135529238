/**
 * uniqBy returns de-duplicated items from an array using a specific key
 * https://gist.github.com/lednhatkhanh/af41ad599568a776e09332b8cd716db6
 */
export default function uniqBy<T>(arr: T[], key: keyof T): T[] {
  return Object.values(
    arr.reduce(
      (map, item) => ({
        ...map,
        [`${item[key]}`]: item,
      }),
      {}
    )
  );
}
