import { Button } from "@ameelio/ui";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MeetingType } from "../api/graphql";
import { useCurrentVisitor } from "../SessionBoundary";
import { GetEventDetailsQuery } from "./GetEventDetails.generated";

type Props = {
  meeting: GetEventDetailsQuery["meeting"];
};

export default function EditMeetingButton({ meeting }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentVisitor = useCurrentVisitor();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Button
        variant="outlined"
        sx={{ width: 1 }}
        onClick={() =>
          navigate("/events/new", {
            state: {
              fromPath: "/events",
              fromName: t("Events"),
              action: "edit",
              meetingId: meeting.id,
              connectionId:
                meeting.meetingType !== MeetingType.Webinar
                  ? meeting.connections.find(
                      (c) => c.visitor.id === currentVisitor.id
                    )?.id
                  : undefined,
            },
          })
        }
      >
        {t("Modify event")}
      </Button>
    </Box>
  );
}
