import { Dialog } from "@ameelio/ui";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FacilityFeature } from "../api/graphql";
import featureTypeToHumanReadable from "../lib/featureTypeToHumanReadable";
import { ContactFeatures } from "../lib/permittedFeaturesList";

export type Props = {
  onClose: () => void;
  data?: {
    name: string;
    features: FacilityFeature[];
  };
};

export default function FacilityFeaturesDialog({ onClose, data }: Props) {
  const { t } = useTranslation();

  const features = ContactFeatures.filter(
    (f) => data?.features.includes(f)
  ).map(featureTypeToHumanReadable);

  return (
    <Dialog
      title={t("Services available at {{facilityName}}", {
        facilityName: data?.name,
      })}
      onClose={onClose}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Stack spacing={2} sx={{ marginTop: 2 }}>
          {features?.map((feature) => (
            <Typography key={feature} variant="body2" color="text.primary">
              {feature}
            </Typography>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
