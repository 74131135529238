import { partition } from "@ameelio/core";
import { FacilityFeature, FacilityService } from "../api/graphql";
import featureTypeToHumanReadable from "./featureTypeToHumanReadable";

type Props = {
  features: FacilityFeature[];
  activeRestrictions: FacilityService[];
};

export const ContactFeatures = [
  FacilityFeature.VideoCall,
  FacilityFeature.ConfidentialVideoCall,
  FacilityFeature.ConfidentialInPersonVisit,
  FacilityFeature.Webinar,
  FacilityFeature.InPersonVisit,
  FacilityFeature.VoiceCall,
  FacilityFeature.Emessaging,
] as const;
type ContactFeature = (typeof ContactFeatures)[number];

// some features can be temporarily disabled by connection restrictions
const FeatureToService: Record<ContactFeature, FacilityService | null> = {
  [FacilityFeature.VideoCall]: FacilityService.VideoCall,
  [FacilityFeature.ConfidentialVideoCall]: null,
  [FacilityFeature.Webinar]: null,
  [FacilityFeature.InPersonVisit]: FacilityService.InPersonVisit,
  [FacilityFeature.ConfidentialInPersonVisit]: null,
  [FacilityFeature.VoiceCall]: FacilityService.VoiceCall,
  [FacilityFeature.Emessaging]: FacilityService.Emessaging,
};

export default function permittedFeaturesList({
  features,
  activeRestrictions,
}: Props) {
  const availableFeatures = ContactFeatures.filter((f) => features.includes(f));

  // TODO: also begin showing restricted features, as in CMob
  const [, unrestrictedFeatures] = partition(availableFeatures, (f) =>
    activeRestrictions.some((service) => service === FeatureToService[f])
  );

  return unrestrictedFeatures.map(featureTypeToHumanReadable).join(", ");
}
