import { Chip } from "@ameelio/ui";
import * as Sentry from "@sentry/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrganizationMembershipStatus } from "../api/graphql";

export default function OrganizationMembershipStatusChip({
  status,
}: {
  status: OrganizationMembershipStatus;
}) {
  const { t } = useTranslation();
  switch (status) {
    case OrganizationMembershipStatus.Active:
      return <Chip color="green" label={t("Active")} />;
    case OrganizationMembershipStatus.Inactive:
      return <Chip color="grey" label={t("Inactive")} />;
    default:
      Sentry.captureException(
        `unexpected organization membership status: ${status}`
      );
      return <Chip color="grey" label={status} />;
  }
}
