import { IconButton } from "@ameelio/ui";
import CancelIconOutlined from "@mui/icons-material/CancelOutlined";
import { Alert, Box, Stack, Switch, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FullscreenBox from "../../FullscreenBox";
import { BaseSkeleton } from "../../lib/closet";
import { PrimaryButton, SecondaryButton } from "../../lib/ModalButtons";
import { FacingMode, getVideoStream } from "../../lib/Stream";
import useLocalMedia from "../../lib/useLocalMedia";
import Video from "../../lib/Video";

type Props = {
  title: string;
  description: string;
  onConfirm: (file: File) => void;
  onCancel: () => void;
};

export default function ImageCapture({
  title,
  description,
  onConfirm,
  onCancel,
}: Props) {
  const { t } = useTranslation();
  const [image, setImage] = useState<Blob | null>(null);
  const { video: videoDevices } = useLocalMedia();
  const [facingMode, setFacingMode] = useState<FacingMode>(FacingMode.USER);
  const [stream, setStream] = useState<MediaStream>();
  const [loading, setLoading] = useState<boolean>(false);
  const [mediaError, setMediaError] = useState<string>("");
  const refVideo = useRef<HTMLVideoElement>(null);
  const refCanvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (videoDevices.length) {
      setLoading(true);
      setMediaError("");
      getVideoStream({
        facingMode,
      })
        .then((sr: MediaStream) => {
          setStream(sr);
          setLoading(false);
          setMediaError("");
        })
        .catch((err) => {
          setMediaError(err.toString());
          setLoading(false);
        });
    }
  }, [videoDevices, facingMode]);

  const stopStream = useCallback(() => {
    stream?.getTracks().forEach((track) => {
      track.stop();
    });
  }, [stream]);

  useEffect(
    () => () => {
      stopStream();
    },
    [stopStream]
  );

  const captureImage = () => {
    if (refCanvas.current && refVideo.current) {
      refCanvas.current.width = refVideo.current.videoWidth;
      refCanvas.current.height = refVideo.current.videoHeight;
      refCanvas.current.getContext("2d")?.drawImage(refVideo.current, 0, 0);
      refCanvas.current.toBlob((blob) => {
        setImage(blob);
      });
    }
  };

  // Camera selector is only available with multiple hardware devices and when there is no image.
  const showCameraSelector =
    stream && videoDevices.length > 1 && !image && !loading && !mediaError;
  const showCapture = stream && !image && !loading && !mediaError;

  return (
    <FullscreenBox
      sx={{
        backgroundColor: "#FAFAFA",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          gap: { xs: 2, md: 3 },
          maxWidth: { xs: 1, sm: 0.9, lg: 0.5, xl: 0.4 },
          margin: "auto",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          maxHeight={1}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h1" color="text.primary">
              {title}
            </Typography>
            <Typography variant="h3" color="text.secondary">
              {description}
            </Typography>
          </Box>
          <IconButton size="small" onClick={onCancel} ariaLabel={t("Cancel")}>
            <CancelIconOutlined color="action" fontSize="medium" />
          </IconButton>
        </Box>
        {loading && (
          <BaseSkeleton
            sx={{
              height: "70vh",
              width: "100%",
              transform: "unset",
            }}
          />
        )}
        {mediaError && (
          <div
            style={{
              borderRadius: "8px",
              maxWidth: "100%",
              maxHeight: "100%",
              width: "100%",
            }}
          >
            <Alert severity="warning">
              {t(
                "Error opening the camera. Please verify you have granted access from your browser."
              )}
            </Alert>
          </div>
        )}
        {!loading && !mediaError && stream && (
          <Video refVideo={refVideo} mediaStream={stream} hide={!!image} />
        )}
        <canvas
          ref={refCanvas}
          style={{
            borderRadius: "8px",
            display: image ? "block" : "none",
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        />
        {showCameraSelector && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="black" variant="body1">
              {t("Rear camera")}
            </Typography>
            <Switch
              checked={facingMode === FacingMode.USER}
              onChange={() => {
                stopStream();
                setFacingMode(
                  facingMode === FacingMode.USER
                    ? FacingMode.ENVIRONMENT
                    : FacingMode.USER
                );
              }}
            />
            <Typography color="black" variant="body1">
              {t("Front camera")}
            </Typography>
          </Stack>
        )}
        <Stack gap={2}>
          {image ? (
            <Box display="flex" flexDirection="row" gap={2}>
              <SecondaryButton onClick={() => setImage(null)}>
                {t("Retake")}
              </SecondaryButton>
              <PrimaryButton onClick={() => onConfirm(image as File)}>
                {t("Select")}
              </PrimaryButton>
            </Box>
          ) : (
            showCapture && (
              <PrimaryButton onClick={captureImage}>
                {t("Capture")}
              </PrimaryButton>
            )
          )}
        </Stack>
      </Box>
    </FullscreenBox>
  );
}
