import { MeetingType } from "../api/graphql";
import featureEnabled from "./featureEnabled";

export default function isMultimediaMeeting(meetingType: MeetingType) {
  return [
    MeetingType.VideoCall,
    MeetingType.Webinar,
    featureEnabled("RECEIVE_VOICE_CALLS") ? MeetingType.VoiceCall : undefined,
  ]
    .filter(Boolean)
    .includes(meetingType);
}
