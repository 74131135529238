import {
  Avatar,
  Media,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@ameelio/ui";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "../../api/graphql";
import Link from "../../lib/Link";
import ConnectionsAvatarGroup from "./ConnectionsAvatarGroup";
import { GetConnectionsQuery } from "./GetConnections.generated";
import pivotConnectionsOnInmate from "./pivotConnectionsOnInmate";

type Props = {
  visitorId: string;
  organizationId: string;
  caption?: string;
  connections: Exclude<
    GetConnectionsQuery["currentVisitor"],
    null
  >["connections"];
  noConnectionsFound: boolean;
};

export default function ActiveConnectionsTable({
  visitorId,
  caption,
  organizationId,
  connections,
  noConnectionsFound,
}: Props) {
  const { t } = useTranslation();
  const inmates = pivotConnectionsOnInmate(connections);

  return (
    <TableContainer>
      <Table>
        {caption && <caption>{caption}</caption>}
        <TableHead>
          <TableRow>
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("Location")}</TableCell>
            <TableCell>{t("ID")}</TableCell>
            <TableCell>{t("Team contacts")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {inmates.map((i) => {
            const myConnection = i.connections.find(
              (c) => c.visitor.id === visitorId
            );
            return (
              <TableRow key={i.id}>
                <TableCell>
                  <Link
                    to={`/organization/${organizationId}/contacts/${i.id}`}
                    sx={{ textDecoration: "none" }}
                  >
                    <Media image={<Avatar contact={i} />}>
                      <Typography
                        variant="body2"
                        sx={{ textDecoration: "underline" }}
                      >
                        {i.fullName}
                      </Typography>
                    </Media>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {i.facility.publicId} / {i.group.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {i.inmateIdentification}
                  </Typography>
                </TableCell>
                <TableCell>
                  <ConnectionsAvatarGroup
                    sx={{ justifyContent: "start" }}
                    max={3}
                    connections={i.connections.filter(
                      (c) => c.status === ConnectionStatus.Active
                    )}
                  />
                </TableCell>
                <TableCell>
                  {myConnection && (
                    <Link
                      button
                      variant="outlined"
                      to="/events/new"
                      state={{
                        connectionId: myConnection.id,
                        fromPath: `/organization/${organizationId}/contacts`,
                        fromName: t("Contacts"),
                      }}
                    >
                      {t("Schedule")}
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {noConnectionsFound && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: 200,
          }}
        >
          <Box>
            <Typography color="text.secondary">{t("No results")}</Typography>
          </Box>
        </Box>
      )}
    </TableContainer>
  );
}
