import retry from "retry-as-promised";

export default async function retryImport<T>(
  importer: () => Promise<T>
): Promise<T> {
  return retry(importer, {
    max: 4,
    backoffBase: 500,
    backoffExponent: 1,
  });
}
