import * as Sentry from "@sentry/react";

export default function getHostname(url: string) {
  try {
    return new URL(url).hostname;
  } catch (e) {
    // If we have invalid URLs in the system
    // we should be alerted so we can investigate
    // and clean
    Sentry.captureMessage(`Invalid URL found: ${url}`, "error");
  }
  return null;
}
