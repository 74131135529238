/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import React from "react";
import FullscreenLayout from "./FullscreenLayout";
import Link from "./lib/Link";

type Props = {
  backTo: string;
  breadcrumbs: [string, string];
  children: React.ReactNode;
};

export default function FullscreenFlow({
  backTo,
  breadcrumbs,
  children,
}: Props) {
  return (
    <FullscreenLayout
      corner={
        <>
          <Link
            to={backTo}
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            color="text.secondary"
          >
            <ArrowBackIcon sx={{ marginRight: 3 }} /> {breadcrumbs[0]}
          </Link>
          &nbsp;
          <Typography>
            /&nbsp;
            {breadcrumbs[1]}
          </Typography>
        </>
      }
    >
      {children}
    </FullscreenLayout>
  );
}
