import { useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import isObjectWithKey from "../lib/isObjectWithKey";
import { GetVisitorsDocument } from "./GetVisitors.generated";
import { SearchQuery } from "./types";
import Visitors from "./Visitors";

const { history } = window;

export default function VisitorsScreen() {
  // search filters are restored from page state for back navigation
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    name:
      isObjectWithKey(history.state, "name") &&
      typeof history.state.name === "string" &&
      !!history.state.name // check for truthiness to avoid empty strings
        ? history.state.name
        : undefined,
    email:
      isObjectWithKey(history.state, "email") &&
      typeof history.state.email === "string" &&
      !!history.state.email // check for truthiness to avoid empty strings
        ? history.state.email
        : undefined,
  });

  // used to store the search variables in history
  // in order to restore page state from back navigation
  useEffect(() => {
    history.replaceState(
      { name: searchQuery.name, email: searchQuery.email },
      ""
    );
  }, [searchQuery]);

  const { data, error, loading } = useQuery(GetVisitorsDocument, {
    fetchPolicy: "cache-and-network",
    variables: { name: searchQuery.name, email: searchQuery.email },
  });
  if (error) throw error;

  const visitors = useMemo(
    () =>
      [...(data?.visitors.results || [])].sort((a, b) =>
        `${a.fullName}`.localeCompare(`${b.fullName}`)
      ),
    [data]
  );

  return (
    <Visitors
      visitors={visitors}
      defaultSearch={searchQuery}
      loading={loading}
      onUpdateSearch={(value) => setSearchQuery(value)}
    />
  );
}
