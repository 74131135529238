import { Button } from "@ameelio/ui";
import { useMediaQuery as measureScreenWidth } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "../api/graphql";
import addStylesForDevices from "../lib/addStylesForDevices";
import { onlyMobile } from "../lib/responsiveHelpers";
import ConfirmArchiveConnectionDialog from "./ConfirmArchiveConnectionDialog";

type Props = {
  firstName: string;
  status: ConnectionStatus;
  connectionId: string;
};

export default function ArchiveConnectionButton({
  firstName,
  status,
  connectionId,
}: Props) {
  const { t } = useTranslation();
  const [showArchiveDialog, setShowArchiveDialog] = useState<boolean>(false);
  const isMobile = onlyMobile(measureScreenWidth);
  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => setShowArchiveDialog(true)}
        sx={addStylesForDevices(isMobile, {}, { width: 1 })}
      >
        {t("Archive")}
      </Button>
      {showArchiveDialog && (
        <ConfirmArchiveConnectionDialog
          firstName={firstName}
          status={status}
          connectionId={connectionId}
          onClose={() => setShowArchiveDialog(false)}
        />
      )}
    </>
  );
}
