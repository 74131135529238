import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import CreateFacilityScreen from "./CreateFacilityScreen";
import CreateSystemScreen from "./CreateSystemScreen";
import DebugCallsScreen from "./debug/DebugCallsScreen";
import OrganizationMembersScreen from "./OrganizationMembersScreen";
import OrganizationPartnershipsScreen from "./OrganizationPartnersScreen";
import OrganizationsScreen from "./OrganizationsScreen";
import SystemScreen from "./SystemScreen";
import SystemsScreen from "./SystemsScreen";
import VisitorScreen from "./VisitorScreen";
import VisitorsScreen from "./VisitorsScreen";

const ToolboxRoutes: RouteObject = {
  path: "/toolbox",
  element: (
    <SessionBoundary userType="Staff">
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: <MenuLayout currentTab="none" />,
    },
    {
      path: "systems",
      element: (
        <MenuLayout currentTab="systems-tools">
          <SystemsScreen />
        </MenuLayout>
      ),
    },
    {
      path: "systems/new",
      element: (
        <MenuLayout currentTab="systems-tools">
          <CreateSystemScreen />
        </MenuLayout>
      ),
    },
    {
      path: "debug/calls",
      element: (
        <MenuLayout currentTab="debug-calls">
          <DebugCallsScreen />
        </MenuLayout>
      ),
    },
    {
      path: "systems/:systemId",
      element: (
        <MenuLayout currentTab="systems-tools">
          <ValidateUUID id="systemId">
            <SystemScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
    {
      path: "systems/:systemId/facilities/new",
      element: (
        <MenuLayout currentTab="systems-tools">
          <ValidateUUID id="systemId">
            <CreateFacilityScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
    {
      path: "organizations",
      element: (
        <MenuLayout currentTab="organizations-tools">
          <OrganizationsScreen />
        </MenuLayout>
      ),
    },
    {
      path: "organizations/:id/members",
      element: (
        <MenuLayout currentTab="organizations-tools">
          <ValidateUUID>
            <OrganizationMembersScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
    {
      path: "organizations/:id/partnerships",
      element: (
        <MenuLayout currentTab="organizations-tools">
          <ValidateUUID>
            <OrganizationPartnershipsScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
    {
      path: "visitors",
      element: (
        <MenuLayout currentTab="visitors-tools">
          <VisitorsScreen />
        </MenuLayout>
      ),
    },
    {
      path: "visitors/:visitorId",
      element: (
        <MenuLayout currentTab="visitors-tools">
          <ValidateUUID id="visitorId">
            <VisitorScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
  ],
};

export default ToolboxRoutes;
