import { Button } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomAlertWithButton from "../lib/CustomAlertWithButton";

type Props = {
  statusDetails?: string | null;
  submitting?: boolean;
  onSubmit: () => void;
};

export default function ContactRejectedAlert({
  statusDetails,
  submitting,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  return (
    <CustomAlertWithButton
      severity="error"
      sx={{
        alignItems: "flex-start",
      }}
    >
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <Stack
          direction="column"
          spacing={0.5}
          sx={{ wordBreak: "break-word" }}
        >
          <Typography variant="body1" color="text.primary">
            {t("Your request has been rejected")}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {statusDetails || ""}
          </Typography>
        </Stack>
        <Box display="flex" alignItems="center">
          <Button variant="contained" disabled={submitting} onClick={onSubmit}>
            {t("Resubmit")}
          </Button>
        </Box>
      </Box>
    </CustomAlertWithButton>
  );
}
