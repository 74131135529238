import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../../api/MeetingFragment.generated';
export type GetCallInfoQueryVariables = Types.Exact<{
  meetingId: Types.Scalars['ID'];
}>;


export type GetCallInfoQuery = { __typename: 'Query', meeting: { __typename: 'Meeting', id: string, title: string | null, meetingType: Types.MeetingType, status: Types.MeetingStatus, statusDetails: string | null, privacyLevel: Types.PrivacyLevel, call: { __typename: 'Call', id: string, status: Types.CallStatus, rating: number | null, callLogUrl: string | null } | null, interval: { __typename: 'Interval', startAt: number, endAt: number, seconds: number | null } } };


export const GetCallInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCallInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"meetingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"call"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"callLogUrl"}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetCallInfoQuery, GetCallInfoQueryVariables>;