import {
  Dialog,
  DialogActions,
  DialogContent,
  MultiSelectChipInput,
  SubmitButton,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { Facility, FacilityFeature } from "../api/graphql";
import featureTypeToHumanReadable from "../lib/featureTypeToHumanReadable";
import useApolloErrorHandler from "../lib/handleApolloError";
import { UpdateFacilityFeaturesDocument } from "./UpdateFacilityFeatures.generated";

type Props = {
  facility: Pick<Facility, "id" | "features">;
  onClose: () => void;
};

type FormData = {
  features: FacilityFeature[];
};

const ToggledFeatures = Object.values(FacilityFeature);

export default function UpdateFacilityFeaturesDialog({
  facility,
  onClose,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      features: facility.features.filter((f) => ToggledFeatures.includes(f)),
    },
  });

  const handleApolloError = useApolloErrorHandler();
  const [updateFeatures] = useMutation(UpdateFacilityFeaturesDocument, {
    onError: (e) => handleApolloError(e),
  });

  const onSubmit = async ({ features }: FormData) => {
    await updateFeatures({
      variables: {
        input: {
          facilityId: facility.id,
          hasPhoneCalls: features.includes(FacilityFeature.PhoneCall),
          hasMessaging: features.includes(FacilityFeature.Emessaging),
          hasProviders: features.includes(FacilityFeature.Providers),
          hasVideoCalls: features.includes(FacilityFeature.VideoCall),
          hasInPersonVisits: features.includes(FacilityFeature.InPersonVisit),
          hasVoiceCalls: features.includes(FacilityFeature.VoiceCall),
        },
      },
      onCompleted: onClose,
    });
  };
  return (
    <Dialog title="Update features" onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ width: 500 }}>
          <Stack spacing={2}>
            <MultiSelectChipInput
              control={control}
              name="features"
              label="Features"
              items={ToggledFeatures.map((f) => ({
                value: f,
                name: featureTypeToHumanReadable(f),
              }))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <SubmitButton submitting={isSubmitting}>Submit</SubmitButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
