import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { privacyPolicy, termsOfUse } from "../lib/staticUrls";

const links = {
  termsLink: (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
    <a href={termsOfUse} target="_blank" rel="noreferrer" />
  ),
  policyLink: (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
    <a href={privacyPolicy} target="_blank" rel="noreferrer" />
  ),
};

export function TermsPrivacySignUp() {
  return (
    <Trans
      parent="section"
      t={t}
      defaults={
        "By signing up, I accept Ameelio's <termsLink>Terms</termsLink> and <policyLink>Privacy Policy</policyLink>."
      }
      components={links}
    />
  );
}

export function TermsPrivacySignIn() {
  return (
    <Trans
      parent="section"
      t={t}
      defaults={
        "By signing in and using Ameelio, I agree to Ameelio's <termsLink>Terms</termsLink> and <policyLink>Privacy Policy</policyLink>."
      }
      components={links}
    />
  );
}
