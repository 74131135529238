import { InformationDialog, Media } from "@ameelio/ui";
import { DoNotDisturbAlt, ScheduleOutlined } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Facility,
  Message,
  MessageAvailability,
  MessageType,
} from "../api/graphql";

type Props = {
  myMessage: boolean;
  facility: Pick<Facility, "publicId">;
  message: Pick<
    Message,
    | "id"
    | "availability"
    | "statusDetails"
    | "isInstantReply"
    | "fileUrl"
    | "type"
  >;
};

export default function MessageStatus({ myMessage, facility, message }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (message.availability === MessageAvailability.Available) return null;

  const subtitle = (() => {
    if (myMessage && message.statusDetails) {
      return message.type === MessageType.Photo
        ? t(
            "This photo violated the communication policies of {{facilityName}}. The message from {{facilityName}} was:",
            { facilityName: facility.publicId }
          )
        : t(
            "This message violated the communication policies of {{facilityName}}. The message from {{facilityName}} was:",
            { facilityName: facility.publicId }
          );
    }
    return message.type === MessageType.Photo
      ? t(
          "This photo violated the communication policies of {{facilityName}}.",
          { facilityName: facility.publicId }
        )
      : t(
          "This message violated the communication policies of {{facilityName}}.",
          { facilityName: facility.publicId }
        );
  })();
  return (
    <>
      {open && message.availability === MessageAvailability.Rejected && (
        <InformationDialog
          title={t("Message rejected")}
          onClose={() => setOpen(false)}
        >
          <Typography variant="body1">{subtitle}</Typography>
          <br />
          {message.statusDetails && (
            <Typography variant="body1">{message.statusDetails}</Typography>
          )}
        </InformationDialog>
      )}
      {open && message.availability === MessageAvailability.Pending && (
        <InformationDialog
          title={t("Message under review")}
          onClose={() => setOpen(false)}
        >
          <Typography variant="body1">
            {message.type === MessageType.Photo
              ? t(
                  "{{facilityName}} reviews every photo that is sent. This can take up to 1 business day.",
                  { facilityName: facility.publicId }
                )
              : t(
                  "{{facilityName}} reviews every message that is sent. This can take up to 1 business day.",
                  { facilityName: facility.publicId }
                )}
          </Typography>
        </InformationDialog>
      )}

      {message.availability === MessageAvailability.Rejected && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component="button"
          underline="hover"
          onClick={() => setOpen(true)}
          sx={{
            color: "error.main",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Media gap={0.5} image={<DoNotDisturbAlt fontSize="inherit" />}>
            <Typography variant="caption">
              {message.type === MessageType.Photo
                ? t("Photo rejected")
                : t("Message rejected")}
            </Typography>
          </Media>
        </Link>
      )}
      {message.availability === MessageAvailability.Pending && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component="button"
          underline="hover"
          onClick={() => setOpen(true)}
          sx={{ color: "text.disabled", display: "flex", alignItems: "center" }}
        >
          <Media gap={0.5} image={<ScheduleOutlined fontSize="inherit" />}>
            <Typography variant="caption">{t("Under review")}</Typography>
          </Media>
        </Link>
      )}
    </>
  );
}
