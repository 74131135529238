import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateVisitorIdentityMutationVariables = Types.Exact<{
  input: Types.UpdateVisitorIdentityInput;
}>;


export type UpdateVisitorIdentityMutation = { __typename: 'Mutation', updateVisitorIdentity: { __typename: 'UpdateVisitorIdentityResponse', visitor: { __typename: 'Visitor', id: string, fullName: string, dateOfBirth: string | null, identity: { __typename: 'VisitorIdentity', id: string, documentType: Types.IdDocumentType, lastUploaded: number } | null, systemRelationships: Array<{ __typename: 'SystemUser', id: string, status: Types.SystemUserStatus }> } } };


export const UpdateVisitorIdentityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateVisitorIdentity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateVisitorIdentityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateVisitorIdentity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"dateOfBirth"}},{"kind":"Field","name":{"kind":"Name","value":"identity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"documentType"}},{"kind":"Field","name":{"kind":"Name","value":"lastUploaded"}}]}},{"kind":"Field","name":{"kind":"Name","value":"systemRelationships"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UpdateVisitorIdentityMutation, UpdateVisitorIdentityMutationVariables>;