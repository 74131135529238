import {
  Button,
  Chip,
  ChipCustomColors,
  ConfirmDialog,
  IconButton,
} from "@ameelio/ui";
import { Edit } from "@mui/icons-material";
import {
  Box,
  Card,
  Link as MUILink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SystemUserStatus } from "../api/graphql";
import buildPageTitle from "../lib/buildPageTitle";
import CustomAlertWithButton from "../lib/CustomAlertWithButton";
import DetailsStack from "../lib/DetailsStack";
import { getDocumentTypeLabel } from "../lib/DocumentType";
import { languageLabel } from "../lib/Language";
import Link from "../lib/Link";
import ProvideYourIdReminder from "../lib/ProvideYourIdReminder";
import Screen from "../lib/Screen";
import showPPTOS from "../lib/showPPTOS";
import { privacyPolicy, termsOfUse } from "../lib/staticUrls";
import { getRawDateTzUnaware, monthDateYearFormat } from "../lib/timeFormats";
import useRemindMinorToProvideId from "../lib/useRemindMinorToProvideId";
import { useCurrentCorrespondent, useCurrentUser } from "../SessionBoundary";
import DeleteVisitorDialog from "./DeleteVisitorDialog";
import EditProfileInfoDialog, { EditFields } from "./EditProfileInfoDialog";
import SettingsFAQListDialog from "./SettingsFAQListDialog";

type SettingsItemProps = {
  label: string;
  content: string;
  onEdit?: () => void;
};

function SettingsItem({ label, content, onEdit }: SettingsItemProps) {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ flexDirection: { xs: "column", md: "row" } }}
      gap={1.5}
      py={1.5}
    >
      {label && (
        <Box flex={1} sx={{ minWidth: 120 }}>
          <Typography variant="subtitle1" color="text.secondary">
            {label}
          </Typography>
        </Box>
      )}
      <Box flex={5} display="flex" gap={1}>
        <Box flex={4}>
          <Typography
            variant="subtitle1"
            color="text.primary"
            sx={{ wordBreak: "break-word" }}
          >
            {content}
          </Typography>
        </Box>
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          {onEdit && (
            <IconButton
              ariaLabel={t("Edit")}
              onClick={onEdit}
              sx={{
                cursor: "pointer",
                width: 24,
                height: 24,
              }}
            >
              <Edit />
            </IconButton>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

function FacilityStatus({
  name,
  status,
  statusDetails,
}: {
  name: string;
  status?: SystemUserStatus;
  statusDetails?: string | null;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const statusData: Record<string, ChipCustomColors> = (() => {
    switch (status) {
      case SystemUserStatus.Approved:
        return { label: t("Approved"), color: "green" };
      case SystemUserStatus.Pending:
        return { label: t("Pending"), color: "orange" };
      case SystemUserStatus.Rejected:
        return { label: t("Rejected"), color: "red" };
      case SystemUserStatus.Inactive:
        return { label: t("Inactive"), color: "grey" };
      default:
        // Unknown status
        return { label: t("Unknown"), color: "grey" };
    }
  })();

  if (status === SystemUserStatus.Rejected) {
    return (
      <Stack gap={1}>
        <Typography variant="subtitle1" color="text.primary">
          {name}
        </Typography>
        <CustomAlertWithButton
          severity="error"
          sx={{
            alignItems: "flex-start",
            minWidth: 300,
          }}
        >
          <Box display="flex" justifyContent="space-between" gap={1.5}>
            <Stack
              direction="column"
              spacing={0.5}
              sx={{ wordBreak: "break-word" }}
            >
              <Typography variant="body1" color="text.primary">
                {t("Your ID was rejected at {{facilityName}}", {
                  facilityName: name,
                })}
              </Typography>
              <Typography variant="body2" color="text.primary">
                {statusDetails || t("Reason for rejection unknown")}
              </Typography>
            </Stack>
            <Box
              display="flex"
              alignItems="center"
              onClick={() =>
                navigate("/settings/identification", {
                  state: { action: "edit" },
                })
              }
            >
              <Button variant="contained">{t("Resubmit")}</Button>
            </Box>
          </Box>
        </CustomAlertWithButton>
      </Stack>
    );
  }
  return (
    <Box display="flex" flex={1} gap={1} justifyContent="space-between">
      <Typography variant="subtitle1" color="text.primary">
        {name}
      </Typography>
      <Chip label={statusData.label} color={statusData.color} />
    </Box>
  );
}

function FacilitiesStatusItem() {
  const { t } = useTranslation();
  const user = useCurrentUser();
  // Type narrowing
  if (user.__typename !== "Visitor") return null;

  return (
    <Stack
      sx={{ flexDirection: { xs: "column", md: "row" } }}
      alignItems="center"
      gap={1.5}
      py={1.5}
    >
      <Box alignSelf="flex-start" flex={1} sx={{ minWidth: 120 }}>
        <Typography variant="subtitle1" color="text.secondary">
          {t("Facilities")}
        </Typography>
      </Box>
      <Stack spacing={1.5} flex={5} width={1}>
        {user.systemRelationships.length
          ? user.systemRelationships?.flatMap(
              ({ status, statusDetails, connectedFacilities }) =>
                connectedFacilities.map(({ id, name }) => (
                  <FacilityStatus
                    key={id}
                    name={name}
                    status={status}
                    statusDetails={statusDetails}
                  />
                ))
            )
          : "-"}
      </Stack>
    </Stack>
  );
}

export default function SettingsScreen() {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const navigate = useNavigate();
  const isVisitor = user.__typename === "Visitor";
  const [faqListModalShown, setFaqListModalShown] = useState<boolean>(false);

  // Confirm edit intent
  const [confirmEditDialogShown, setConfirmEditDialogShown] =
    useState<boolean>(false);
  const [confirmEditDialogData, setConfirmEditDialogData] = useState<{
    editFields: EditFields;
    title: string;
    description: string;
  }>({ editFields: "name_dob", title: "", description: "" });

  // Proceed with edit
  const [editDialogShown, setEditDialogShown] = useState<boolean>(false);

  const onConfirmNameAndDOBEdit = () => {
    setConfirmEditDialogData({
      editFields: "name_dob",
      title: t("Are you sure you want to edit this information?"),
      description: `${t(
        "Editing your name or date of birth will cancel all upcoming events you have at any facility."
      )} ${
        user.__typename === "Visitor" && !!user.identity
          ? t("Each facility will have to review your ID again.")
          : user.__typename === "Visitor" && !user.identity
            ? t(
                "Any approved or pending contact requests will be archived and must be re-submitted."
              )
            : ""
      }`,
    });
    setConfirmEditDialogShown(true);
  };

  const onConfirmEmailAndPhoneEdit = () => {
    setConfirmEditDialogData({
      ...confirmEditDialogData,
      editFields: "email_phone",
    });
    setEditDialogShown(true);
  };

  // Delete visitor account
  const [deleteVisitorDialogShown, setDeleteVisitorDialogShown] =
    useState<boolean>(false);

  const { showProvideIdReminder, reminderDueDate } = useRemindMinorToProvideId({
    user,
  });

  const headerTitle = t("Settings");
  const pageTitle = buildPageTitle(headerTitle);

  return (
    <Screen showNotifications title={headerTitle}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {showProvideIdReminder && (
        <ProvideYourIdReminder dueDate={reminderDueDate} />
      )}
      <Box pb={4}>
        {isVisitor && (
          <>
            <Typography variant="h2">{t("Personal information")}</Typography>
            <Card
              sx={{ p: 2, mt: 2, mb: 4, maxWidth: 1, overflow: "auto" }}
              variant="outlined"
            >
              <SettingsItem
                label={t("Name")}
                content={user.fullName}
                onEdit={onConfirmNameAndDOBEdit}
              />

              <>
                <SettingsItem
                  label={t("Date of birth")}
                  content={
                    (user.dateOfBirth &&
                      monthDateYearFormat.format(
                        getRawDateTzUnaware(user.dateOfBirth)
                      )) ||
                    "-"
                  }
                  onEdit={onConfirmNameAndDOBEdit}
                />
                {user.identity && (
                  <SettingsItem
                    label={t("Identification")}
                    content={
                      user.identity?.documentType
                        ? `${getDocumentTypeLabel(
                            user.identity?.documentType
                          )} ${t(`(last uploaded {{lastUploaded}})`, {
                            lastUploaded: monthDateYearFormat.format(
                              user.identity?.lastUploaded
                            ),
                          })}`
                        : "-"
                    }
                    onEdit={() => {
                      setConfirmEditDialogData({
                        editFields: "identification",
                        title: t(
                          "Are you sure you want to edit your Identification?"
                        ),
                        description: t(
                          "Editing your ID will cancel all upcoming events you have at any facility. Each facility will have to review your ID again."
                        ),
                      });
                      setConfirmEditDialogShown(true);
                    }}
                  />
                )}
                <FacilitiesStatusItem />
              </>
            </Card>

            <Typography variant="h2">{t("Contact information")}</Typography>
            <Card sx={{ p: 2, mt: 2, mb: 4 }} variant="outlined">
              <SettingsItem label={t("Email")} content={user.email || "-"} />
              <SettingsItem
                label={t("Phone number")}
                content={user.phone || "-"}
                onEdit={onConfirmEmailAndPhoneEdit}
              />
            </Card>
          </>
        )}

        <Typography variant="h2">{t("App configuration")}</Typography>
        <Card sx={{ p: 2, mt: 2, mb: 4 }} variant="outlined">
          <SettingsItem
            label={t("Language")}
            content={languageLabel(user.languagePreference)}
            onEdit={() => {
              setConfirmEditDialogData({
                ...confirmEditDialogData,
                editFields: "language",
              });
              setEditDialogShown(true);
            }}
          />
        </Card>

        <Typography variant="h2">{t("Policies and questions")}</Typography>
        <Card sx={{ p: 2, mt: 2 }} variant="outlined">
          <Box sx={{ mt: 1 }}>
            <DetailsStack>
              {user.__typename === "Inmate" && (
                <Box>
                  <MUILink // eslint-disable-line jsx-a11y/anchor-is-valid
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setFaqListModalShown(true);
                    }}
                  >
                    {t("FAQ")}
                  </MUILink>
                </Box>
              )}
              {showPPTOS(user) && (
                <>
                  <Box>
                    <MUILink href={termsOfUse} target="_blank">
                      {t("Terms of use")}
                    </MUILink>
                  </Box>
                  <Box>
                    <MUILink href={privacyPolicy} target="_blank">
                      {t("Privacy policy")}
                    </MUILink>
                  </Box>
                </>
              )}
            </DetailsStack>
          </Box>
        </Card>
        {isVisitor && (
          <Box display="flex" justifyContent="space-between" mt={4}>
            {!user.organizationMemberships.length && (
              <Tooltip
                arrow
                placement="top"
                title={t(
                  "Ameelio Connect organizations enable service providers to communicate with their incarcerated clients in a professional capacity. Please contact support for more information."
                )}
              >
                <span>
                  <Link button variant="text" to="/organization/new">
                    {t("Create Ameelio organization")}
                  </Link>
                </span>
              </Tooltip>
            )}
            <Button
              variant="outlined"
              color="error"
              onClick={() => setDeleteVisitorDialogShown(true)}
            >
              {t("Delete account")}
            </Button>
          </Box>
        )}
      </Box>

      <SettingsFAQListDialog
        isOpen={faqListModalShown}
        onClose={() => setFaqListModalShown(false)}
      />
      {confirmEditDialogShown && (
        <ConfirmDialog
          title={confirmEditDialogData.title}
          description={confirmEditDialogData.description}
          onConfirm={() => {
            setConfirmEditDialogShown(false);
            if (confirmEditDialogData.editFields === "identification") {
              navigate("/settings/identification", {
                state: { action: "edit" },
              });
            } else {
              setEditDialogShown(true);
            }
          }}
          confirmText={t("Yes, continue")}
          onCancel={() => setConfirmEditDialogShown(false)}
        />
      )}
      <EditProfileInfoDialog
        isOpen={editDialogShown}
        editFields={confirmEditDialogData.editFields}
        defaultValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          dateOfBirth:
            isVisitor && user.dateOfBirth
              ? getRawDateTzUnaware(user.dateOfBirth)
              : new Date(),
          email: (isVisitor && user.email) || "",
          phone: (isVisitor && user.phone) || "",
          language: user.languagePreference,
        }}
        onClose={() => setEditDialogShown(false)}
      />
      <DeleteVisitorDialog
        isOpen={deleteVisitorDialogShown}
        onClose={() => setDeleteVisitorDialogShown(false)}
      />
    </Screen>
  );
}
