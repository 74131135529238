import { differenceInYears } from "date-fns";

export const MINOR_AGE_YEARS_CUTOFF = 18;

function visitorAge({ dateOfBirth }: { dateOfBirth?: string | null }) {
  return dateOfBirth
    ? differenceInYears(new Date(), new Date(dateOfBirth))
    : 999;
}

export function isYoungerThan(
  age: number,
  user: {
    dateOfBirth?: string | null;
  }
): boolean {
  return visitorAge(user) < age;
}

export default function isMinor(user: {
  dateOfBirth?: string | null;
}): boolean {
  return isYoungerThan(MINOR_AGE_YEARS_CUTOFF, user);
}
