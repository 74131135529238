import { Avatar } from "@ameelio/ui";
import {
  Stack,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React from "react";
import { belowLargeTablet } from "../../lib/responsiveHelpers";

type CaptionsBoxProps = {
  user: { firstName: string; lastName: string; fullName: string };
  message: string;
};

export default function CaptionBox({ user, message }: CaptionsBoxProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: "center",
        borderRadius: 2,
      }}
      mb={1}
    >
      <Avatar contact={user} />
      <Typography
        variant={belowLargeTablet(measureScreenWidth) ? "body2" : "h3"}
        color="common.white"
        component="span"
      >
        {message}
      </Typography>
    </Stack>
  );
}
