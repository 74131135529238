import {
  ConnectionStateQuality,
  OutputConnectionState,
} from "@ameelio/connect-call-client";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { Icon, Tooltip } from "@mui/material";
import { green, grey, yellow } from "@mui/material/colors";
import useChange from "@react-hook/change";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const TOOLTIP_DURATION = 3000;

export const colorLUT: Record<ConnectionStateQuality, string> = {
  excellent: green[500],
  good: green[500],
  average: green[500],
  poor: yellow[500],
  bad: "error.main",
  unknown: grey[500], // may never be seen, but want to satisfy all conditions to make things durable
};

type Props = {
  connectionState: OutputConnectionState;
  origin?: "local" | "remote";
};

export function ConnectionStrength({ connectionState, origin }: Props) {
  const { t } = useTranslation();
  const [tooltipCopy, setTooltipCopy] = useState("");
  const [showTip, setShowTip] = useState<boolean>(false);
  const [showBars, setShowBars] = useState<boolean>(false);
  const timeout = useRef<NodeJS.Timeout>();

  useChange(tooltipCopy, (current) => {
    if (timeout.current) clearTimeout(timeout.current);
    if (current) {
      setShowBars(true);
      setShowTip(true);
      timeout.current = setTimeout(() => {
        setShowTip(false);
        if (
          ["excellent", "good", "average"].includes(connectionState.quality)
        ) {
          setShowBars(false);
        }
      }, TOOLTIP_DURATION);
    }
  });

  useChange(connectionState.quality, (current, prev) => {
    switch (current) {
      case "poor":
        setTooltipCopy(
          origin === "remote"
            ? t("Their connection is weak")
            : t("Your connection is weak")
        );
        break;
      case "bad":
        setTooltipCopy(
          origin === "remote"
            ? t("Their connection is unstable")
            : t("Your connection is unstable")
        );
        break;
      case "excellent":
      case "good":
      case "average":
        if (["bad", "poor"].includes(prev || "unknown")) {
          setTooltipCopy(
            origin === "remote"
              ? t("Their connection is strong")
              : t("Your connection is strong")
          );
        }
        break;
      default:
    }
  });

  return (
    <Tooltip
      open={showTip && showBars}
      onOpen={() => setShowTip(true)}
      onClose={() => setShowTip(false)}
      title={tooltipCopy}
      arrow
    >
      <Icon
        sx={{
          color: colorLUT[connectionState.quality],
        }}
      >
        {showBars && (
          <SignalCellularAltIcon
            sx={{ filter: "drop-shadow(1px 1px 2px rgb(0 0 0 / 1))" }}
          />
        )}
      </Icon>
    </Tooltip>
  );
}
