import { Button, CheckboxInputBase } from "@ameelio/ui";
import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { ScreenTitle } from "../lib/typography";
import { SearchResult } from "./types";

type Props = {
  selectedResult: SearchResult;
  stepper: StepperType;
  onNext: () => void;
};

type SingleFacilityProps = {
  approved: boolean;
  onConfirm: (approved: boolean) => void;
};

function SingleFacilityVisitationForm({
  selectedResult,
  approved,
  onConfirm,
}: Omit<Props, "stepper" | "onNext"> & SingleFacilityProps) {
  const { t } = useTranslation();
  const result = selectedResult;
  const { facilityName } = result;
  return (
    <>
      <ScreenTitle>
        {t(
          "Have you been approved as a visitor at {{facilityName}} for {{contact}}?",
          {
            facilityName,
            contact: result.fullName,
          }
        )}
      </ScreenTitle>
      <Typography color="text.primary" mt={2}>
        {t(
          "{{facilityName}} requires visitors to fill out a separate visitor approval application before using Ameelio Connect. If you submit an Ameelio Connect contact request without finishing the facility application process first, it will be rejected by the facility.",
          { facilityName }
        )}
      </Typography>
      <Box
        my={8}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        <CheckboxInputBase
          onChange={(_, checked) => {
            onConfirm(checked);
          }}
          label={t("Yes, I've been approved")}
          name="selected"
          helperText=""
          value={approved}
          error={false}
        />
        <Link
          href={result.facilityVisitationFormLink || ""}
          target="_blank"
          sx={{ mt: 2 }}
        >
          <Button variant="text">
            {t("No, I need to apply to be a visitor")}
          </Button>
        </Link>
      </Box>
    </>
  );
}

export default function FacilityVisitationFormLinks({
  selectedResult,
  stepper,
  onNext,
}: Props) {
  const [approved, setApproved] = useState<boolean>(false);
  return (
    <StepperFormWrapper
      stepper={stepper({
        loading: false,
        disabled: !approved,
      })}
      handleSubmit={(event) => {
        event.preventDefault();
        onNext();
      }}
    >
      {selectedResult.facilityVisitationFormLink && (
        <SingleFacilityVisitationForm
          selectedResult={selectedResult}
          onConfirm={(checked: boolean) => setApproved(checked)}
          approved={approved}
        />
      )}
    </StepperFormWrapper>
  );
}
