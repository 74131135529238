import {
  Box,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { common } from "@mui/material/colors";
import React from "react";
import { largeTabletAndUp } from "../../lib/responsiveHelpers";
import { ltrTheme } from "../../theme";

export const chatWidth = ltrTheme.spacing(38);

const callChatContainerBaseStyle: SxProps<Theme> = {
  position: "absolute",
  right: 0,
  height: 1,
  zIndex: "tooltip", // 1500
  background: common.white,
};

const callChatContainerStyle: SxProps<Theme> = {
  ...callChatContainerBaseStyle,
  top: 0,
  width: chatWidth,
};

const callChatContainerMobileTabletStyle: SxProps<Theme> = {
  ...callChatContainerBaseStyle,
  bottom: 0,
  borderRadius: `${ltrTheme.spacing(2)} ${ltrTheme.spacing(2)} 0 0`,
  width: 1,
  height: 0.92,
};

type Props = {
  children: React.ReactNode;
};

export default function CallPanel({ children }: Props) {
  const isLargeTabletAndUp = largeTabletAndUp(measureScreenWidth);

  return (
    <Box
      sx={
        isLargeTabletAndUp
          ? callChatContainerStyle
          : callChatContainerMobileTabletStyle
      }
    >
      {children}
    </Box>
  );
}
