import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationMembershipFragmentFragmentDoc } from '../api/OrganizationMembershipFragment.generated';
import { VisitorFragmentFragmentDoc } from '../api/VisitorFragment.generated';
export type GetOrganizationMembershipsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type GetOrganizationMembershipsQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, memberships: Array<{ __typename: 'OrganizationMembership', id: string, role: Types.OrganizationMembershipRole, status: Types.OrganizationMembershipStatus, visitor: { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string, email: string } }> } };


export const GetOrganizationMembershipsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOrganizationMemberships"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"memberships"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationMembershipFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}}]}}]}}]}},...OrganizationMembershipFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetOrganizationMembershipsQuery, GetOrganizationMembershipsQueryVariables>;