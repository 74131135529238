import { Box, Paper } from "@mui/material";
import React from "react";
import withSx from "../lib/withSx";

export const ConnectionsColumn = withSx(Box, {
  flex: { xs: "0 0 200px", sm: "0 0 240px" },
  maxWidth: { xs: 200, sm: 240 },
  borderColor: "divider",
  display: "flex",
  flexDirection: "column",
});

export const ConnectionsList = withSx(Box, { flex: 1, overflowY: "auto" });

export const ConnectionsFilter = withSx(Box, { mx: 1.5, my: 2 });

export const TimelineColumn = withSx(Box, {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  minWidth: { xs: 300, md: "none" },
  overflow: { xs: "auto", md: "hidden" },
});

export function MessageCenterPaper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "transparent",
        flexGrow: 1,
        borderRadius: { xs: 0, md: "16px" },
        borderWidth: { xs: 0, md: "1px" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        overflow: { xs: "auto", lg: "hidden" },
      }}
    >
      {children}
    </Paper>
  );
}
