import i18next from "i18next";

const customBackgroundUrls = [
  {
    large:
      "https://static.ameelio.org/images/CustomBackgrounds/beach1-large.jpg",
    small:
      "https://static.ameelio.org/images/CustomBackgrounds/beach1-small.jpg",
    alt: i18next.t("white sand beach"),
  },
  {
    large:
      "https://static.ameelio.org/images/CustomBackgrounds/beach2-large.jpg",
    small:
      "https://static.ameelio.org/images/CustomBackgrounds/beach2-small.jpg",
    alt: i18next.t("beach with sunset"),
  },
  {
    large:
      "https://static.ameelio.org/images/CustomBackgrounds/forest1-large.jpg",
    small:
      "https://static.ameelio.org/images/CustomBackgrounds/forest1-small.jpg",
    alt: i18next.t("sun shining through trees"),
  },
  {
    large:
      "https://static.ameelio.org/images/CustomBackgrounds/forest2-large.jpg",
    small:
      "https://static.ameelio.org/images/CustomBackgrounds/forest2-small.jpg",
    alt: i18next.t("alpine forest with distant mountains"),
  },
  {
    large:
      "https://static.ameelio.org/images/CustomBackgrounds/mountains2-large.jpg",
    small:
      "https://static.ameelio.org/images/CustomBackgrounds/mountains2-small.jpg",
    alt: i18next.t("clouded mountains"),
  },
  {
    large:
      "https://static.ameelio.org/images/CustomBackgrounds/mountains3-large.jpg",
    small:
      "https://static.ameelio.org/images/CustomBackgrounds/mountains3-small.jpg",
    alt: i18next.t("snowy mountains"),
  },
];
export default customBackgroundUrls;
