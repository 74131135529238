import { Message } from "@ameelio/connect-call-client";
import { useEffect, useState } from "react";

export default function useUnreadMessageCount(
  messages: Message[],
  messagesVisible: boolean
) {
  const [readIndex, setReadIndex] = useState(0);

  // mark messages as read when they are visible
  useEffect(() => {
    if (messagesVisible) setReadIndex(messages.length);
  }, [messages, messagesVisible]);

  return messages.length - readIndex;
}
