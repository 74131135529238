import { useTranslation } from "react-i18next";
import { IdDocumentType } from "../../api/graphql";
import { getDocumentTypeLabel } from "../../lib/DocumentType";

export default function useLabelsForDocumentType() {
  const { t } = useTranslation();
  const labelsForDocumentType = {
    DRIVERS_LICENSE: {
      header: {
        title: getDocumentTypeLabel(IdDocumentType.DriversLicense),
        description: t(
          "The driver's license should be issued by a state and not expired. The file format must be a JPG, PNG, or HEIC."
        ),
      },
      imageOne: {
        title: t("Front of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageTwo: {
        title: t("Back of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageThree: {
        title: t("Selfie with ID"),
        description: t(
          "Hold the document near your face. Make sure your face is fully visible (without glasses or face coverings) and that you are wearing appropriate clothing (shirt with sleeves). Make sure there is nothing inappropriate in the background."
        ),
      },
    },
    PASSPORT: {
      header: {
        title: getDocumentTypeLabel(IdDocumentType.Passport),
        description: t(
          "The passport should not be expired, and the file format must be a JPG, PNG, or HEIC."
        ),
      },
      imageOne: {
        title: t("Photo page"),
        description: t(
          "This is the bottom portion of the page without the signature."
        ),
      },
      imageTwo: {
        title: t("Signature page"),
        description: t(
          "This is the top portion of the page without the photo."
        ),
      },
      imageThree: {
        title: t("Selfie with passport"),
        description: t(
          "Hold the document near your face. Make sure your face is fully visible (without glasses or face coverings) and that you are wearing appropriate clothing (shirt with sleeves). Make sure there is nothing inappropriate in the background."
        ),
      },
    },
    ID_CARD: {
      header: {
        title: getDocumentTypeLabel(IdDocumentType.IdCard),
        description: t(
          "The ID card should not be expired, and the file format must be a JPG, PNG, or HEIC."
        ),
      },
      imageOne: {
        title: t("Front of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageTwo: {
        title: t("Back of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageThree: {
        title: t("Selfie with ID"),
        description: t(
          "Hold the document near your face. Make sure your face is fully visible (without glasses or face coverings) and that you are wearing appropriate clothing (shirt with sleeves). Make sure there is nothing inappropriate in the background."
        ),
      },
    },
    CNIC: {
      header: {
        title: getDocumentTypeLabel(IdDocumentType.Cnic),
        description: t(
          "The CNIC should not be expired, and the file format must be a JPG, PNG, or HEIC."
        ),
      },
      imageOne: {
        title: t("Front of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageTwo: {
        title: t("Back of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageThree: {
        title: t("Selfie with ID"),
        description: t(
          "Hold the document near your face. Make sure your face is fully visible (without glasses or face coverings) and that you are wearing appropriate clothing (shirt with sleeves). Make sure there is nothing inappropriate in the background."
        ),
      },
    },
    UNDOCUMENTED: {
      header: {
        title: getDocumentTypeLabel(IdDocumentType.Undocumented),
        description: t(""),
      },
      imageOne: {
        title: t("Front of ID"),
        description: "-",
      },
      imageTwo: {
        title: t("Back of ID"),
        description: "-",
      },
      imageThree: {
        title: t("Selfie"),
        description: t(
          "Make sure your face is fully visible, without glasses or face coverings."
        ),
      },
    },
    OTHER: {
      header: {
        title: getDocumentTypeLabel(IdDocumentType.Other),
        description: t(
          "The ID should not be expired, and the file format must be a JPG, PNG, or HEIC."
        ),
      },
      imageOne: {
        title: t("Front of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageTwo: {
        title: t("Back of ID"),
        description: t("Place your ID on a flat surface with good lighting."),
      },
      imageThree: {
        title: t("Selfie with ID"),
        description: t(
          "Hold the document near your face. Make sure your face is fully visible (without glasses or face coverings) and that you are wearing appropriate clothing (shirt with sleeves). Make sure there is nothing inappropriate in the background."
        ),
      },
    },
  };
  return labelsForDocumentType;
}
