import { AspectRatio } from "@ameelio/ui";
import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import withController from "./withController";

type Props = {
  label?: string;
  primaryLabel?: string;
  hideFormatLabel?: boolean;
  value: File;
  onChange: (file: File | undefined) => void;
  error?: boolean;
  ratio?: number;
  placeholderImage?: string;
  placeholderImageWidth?: number;
  styleOverrides?: SxProps<Theme>;
  round?: boolean;
};

export function ImageInputBase({
  label,
  value,
  onChange,
  error,
  ratio,
  placeholderImage,
  placeholderImageWidth,
  primaryLabel,
  hideFormatLabel,
  styleOverrides,
  round,
}: Props) {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    maxFiles: 1,
    onDropAccepted: ([file]) => onChange(file),
  });

  const [previewUrl, setPreviewUrl] = useState<string>();
  useEffect(() => {
    if (!value) return setPreviewUrl(undefined);
    const url = URL.createObjectURL(value);
    setPreviewUrl(url);
    return () => URL.revokeObjectURL(url);
  }, [value]);

  return (
    <>
      {label ? <Typography variant="overline">{label}</Typography> : null}
      <AspectRatio ratio={ratio || (round ? 1 : 3 / 2)}>
        <Box
          {...getRootProps()}
          role="form"
          sx={{
            color: error ? "error.main" : undefined,
            cursor: "pointer",
            border: 1,
            borderStyle: "dashed",
            borderColor: "rgba(0 0 0 / .23)",
            padding: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: round ? "50%" : undefined,
            ...styleOverrides,
          }}
        >
          <input
            aria-label={t("Click or drag file to this area to upload")}
            {...getInputProps()}
          />
          {value ? (
            <img
              src={previewUrl}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                height: round ? "100%" : undefined,
                width: round ? "100%" : undefined,
                margin: "auto",
                borderRadius: round ? "50%" : undefined,
              }}
            />
          ) : (
            <Stack spacing={2} textAlign="center" alignItems="center">
              {placeholderImage ? (
                <img
                  src={placeholderImage}
                  alt=""
                  width={placeholderImageWidth}
                />
              ) : null}
              <Typography>
                {primaryLabel || t("Click or drag file to this area to upload")}
              </Typography>
              {!hideFormatLabel && (
                <Typography variant="body2" color="text.secondary">
                  {t("JPG or PNG accepted")}
                </Typography>
              )}
            </Stack>
          )}
        </Box>
      </AspectRatio>
      {!hideFormatLabel && value && (
        <Typography variant="body2">
          {t("Click or drag to replace file")}
        </Typography>
      )}
    </>
  );
}

export default withController(ImageInputBase);
