import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { GetUploadUrlDocument } from "./GetUploadUrl.generated";

export default function useFileUpload() {
  const [getUploadUrl] = useLazyQuery(GetUploadUrlDocument);

  return useCallback(
    async (file: File) => {
      const { data, error } = await getUploadUrl({
        variables: { contentType: file.type },
        fetchPolicy: "no-cache",
      });
      if (!data?.uploadUrl) {
        throw error || new Error("Could not get upload URL");
      }

      await fetch(data.uploadUrl.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      return data.uploadUrl.key;
    },
    [getUploadUrl]
  );
}
