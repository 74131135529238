import { ConfirmDialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import useApolloErrorHandler from "../lib/handleApolloError";
import { CancelMeetingDocument } from "./CancelMeeting.generated";

type MeetingToCancel = {
  meetingId: string;
  description: string;
};
type Props = {
  meetingToCancel: MeetingToCancel;
  onClose: () => void;
};

export default function CancelMeetingDialog({
  meetingToCancel,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const handleApolloError = useApolloErrorHandler();

  const [cancelMeeting] = useMutation(CancelMeetingDocument, {
    onError: (e) => handleApolloError(e),
  });

  return (
    <ConfirmDialog
      onCancel={() => onClose()}
      onConfirm={async () => {
        await cancelMeeting({
          variables: {
            input: {
              meetingId: meetingToCancel.meetingId,
            },
          },
        });
        onClose();
      }}
      title={t("Are you sure you want to cancel the event?")}
      description={meetingToCancel.description}
      cancelText={t("Nevermind")}
      confirmText={t("Yes, cancel event")}
      variant="remove"
    />
  );
}
