import { ConfirmDialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import useApolloErrorHandler from "../lib/handleApolloError";
import { LeaveMeetingDocument } from "./LeaveMeeting.generated";

type MeetingToLeave = {
  meetingId: string;
  description: string;
};
type Props = {
  meetingToLeave: MeetingToLeave;
  onClose: () => void;
  onLeave?: () => void;
};

export default function LeaveMeetingDialog({
  meetingToLeave,
  onClose,
  onLeave,
}: Props) {
  const { t } = useTranslation();

  const handleApolloError = useApolloErrorHandler();

  const [leaveMeeting] = useMutation(LeaveMeetingDocument, {
    onError: (e) => handleApolloError(e),
    onCompleted: () => onLeave?.(),
  });

  return (
    <ConfirmDialog
      onCancel={onClose}
      onConfirm={async () => {
        await leaveMeeting({
          variables: {
            input: {
              meetingId: meetingToLeave.meetingId,
            },
          },
        });
        onClose();
      }}
      title={t("Are you sure you want to leave the event?")}
      description={meetingToLeave.description}
      cancelText={t("Nevermind")}
      confirmText={t("Yes, leave event")}
      variant="remove"
    />
  );
}
