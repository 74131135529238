import React from "react";
import { RouteObject } from "react-router-dom";
import ameelio from "../ameelio.svg";
import EnvBoundary from "../EnvBoundary";
import AuthenticationLayout from "./AuthenticationLayout";
import FacilitySelectScreen from "./FacilitySelectScreen";
import HomeScreen from "./HomeScreen";
import InmateHomeScreen from "./InmateHomeScreen";
import {
  DomainBasedInmateLoginScreen,
  PathBasedInmateLoginScreen,
} from "./InmateLoginScreen";
import LoginScreen from "./LoginScreen";
import LogoutScreen from "./LogoutScreen";
import OrganizationSignupScreen from "./OrganizationSignupScreen";
import SignupScreen from "./SignupScreen";

// inmates use many domains, everyone else uses one.
const inmateVersion = window.location.host !== import.meta.env.VITE_MAIN_HOST;

const AuthenticationRoutes: RouteObject = {
  children: inmateVersion
    ? [
        {
          index: true,
          element: <InmateHomeScreen />,
        },
        {
          path: "/login-select",
          element: (
            <AuthenticationLayout
              corner={
                <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
              }
            >
              <EnvBoundary environment="development">
                <FacilitySelectScreen />
              </EnvBoundary>
            </AuthenticationLayout>
          ),
        },
        {
          path: "/login",
          element: (
            <AuthenticationLayout
              corner={
                <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
              }
            >
              <DomainBasedInmateLoginScreen />
            </AuthenticationLayout>
          ),
        },
        {
          path: "/login/:systemCode",
          element: (
            <AuthenticationLayout
              corner={
                <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
              }
            >
              <PathBasedInmateLoginScreen />
            </AuthenticationLayout>
          ),
        },
        {
          path: "/logout",
          element: <LogoutScreen />,
        },
      ]
    : [
        {
          index: true,
          element: <HomeScreen />,
        },
        {
          path: "/login",
          element: (
            <AuthenticationLayout
              corner={
                <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
              }
            >
              <LoginScreen />
            </AuthenticationLayout>
          ),
        },
        {
          path: "/signup",
          element: (
            <AuthenticationLayout
              corner={
                <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
              }
            >
              <SignupScreen />
            </AuthenticationLayout>
          ),
        },
        {
          path: "/invite/:inviteCode",
          element: (
            <AuthenticationLayout
              corner={
                <img src={ameelio} alt="Ameelio" style={{ width: 100 }} />
              }
            >
              <OrganizationSignupScreen />
            </AuthenticationLayout>
          ),
        },
        {
          path: "/logout",
          element: <LogoutScreen />,
        },
      ],
};

export default AuthenticationRoutes;
