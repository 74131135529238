import { ConnectionStatus } from "../api/graphql";
import { useCurrentUser } from "../SessionBoundary";

export default function useHasActiveContact() {
  const user = useCurrentUser();
  if (user.__typename === "Visitor" || user.__typename === "Inmate") {
    return user.connections.some((c) => c.status === ConnectionStatus.Active);
  }
  return false;
}
