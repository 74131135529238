/* eslint-disable jsx-a11y/media-has-caption */
import React, { ForwardedRef, useEffect } from "react";

type Props = {
  refVideo: ForwardedRef<HTMLVideoElement>;
  mediaStream?: MediaStream;
  hide?: boolean;
  styles?: React.CSSProperties;
};

export function VideoBase({ refVideo, mediaStream, hide, styles }: Props) {
  useEffect(() => {
    if (refVideo && typeof refVideo === "object" && mediaStream) {
      const { current } = refVideo;
      if (current) current.srcObject = mediaStream;
    }
  }, [refVideo, mediaStream]);

  return (
    <video
      ref={refVideo}
      autoPlay
      style={{
        borderRadius: "8px",
        maxWidth: "100%",
        maxHeight: "100%",
        width: "auto",
        display: hide ? "none" : "block",
        ...(styles || {}),
      }}
    />
  );
}

const Video = React.forwardRef(VideoBase) as typeof VideoBase;

export default Video;
