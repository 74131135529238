import { Role } from "@ameelio/connect-call-client";
import { Peer } from "./LiveCall/types/ConnectionInfo";

// Determines if provided role is a host
export const isHost = (role?: Role): boolean =>
  !!(role && role === Role.webinarHost);

// Determines if provided role is a student
export const isAttendee = (role?: Role): boolean =>
  !!(
    role &&
    (role === Role.webinarAttendee || role === Role.webinarIsolatedAttendee)
  );

const byId = (a: Peer, b: Peer) => a.user.id.localeCompare(b.user.id);

// Sorts peers by role (secondary sort on id) with hosts first
// by default, but can optionally sort by students too
export const getPeersOrdered = (
  peers: Peer[],
  sortRole?: "host" | "student"
) => {
  const sortedHosts = peers.filter((p) => isHost(p.user.role)).sort(byId);
  const sortedStudents = peers
    .filter((p) => isAttendee(p.user.role))
    .sort(byId);
  return sortRole && sortRole === "student"
    ? [...sortedStudents, ...sortedHosts]
    : [...sortedHosts, ...sortedStudents];
};
