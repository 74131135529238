import { Role } from "@ameelio/connect-call-client";
import { useEffect, useRef, useState } from "react";
import { TYPICAL_MAXIMUM, TYPICAL_MINIMUM } from "../../lib/useAudioLevel";
import {
  LocalWebinarParticipant,
  RemoteWebinarParticipant,
  WebinarParticipant,
} from "./types/ConnectionInfo";

type Props = {
  localParticipant: LocalWebinarParticipant;
  remoteParticipants: RemoteWebinarParticipant[];
  isScreensharing: boolean;
};

export default function useSpotlightVideo({
  localParticipant,
  remoteParticipants,
  isScreensharing,
}: Props) {
  const [spotlitParticipant, setSpotlitParticipant] = useState<
    WebinarParticipant | undefined
  >(undefined);
  const [speakingStreamIds, setSpeakingStreamIds] = useState<string[]>([]);
  const analyzersRef = useRef<({ disconnect: () => void } | undefined)[]>([]);

  // Used to parse unmuted peer audio streams and create an analyzer
  // that tracks stream volume each second/update "speaking" and "non-speaking"
  // streams accordingly
  useEffect(() => {
    const createVolumeAnalyzer = (stream?: MediaStream) => {
      const numAudioTracks = stream?.getAudioTracks().length || 0;
      if (stream && numAudioTracks > 0) {
        const context = new AudioContext();
        const source = context.createMediaStreamSource(stream);
        const analyzer = context.createAnalyser();
        analyzer.fftSize = 2048;

        source.connect(analyzer);

        const processVolume = () => {
          const buffer = new Float32Array(analyzer.frequencyBinCount);
          analyzer.getFloatFrequencyData(buffer);
          // Estimate volume by max frequency bin. (note:
          // this will mark white noise as quiet
          // and sine waves as very loud even at the same sound energy)
          // In future we might update this to do something fancier
          // to pick out frequencies close to human speech and measure those.
          const volume = buffer.reduce((a, b) => Math.max(a, b));

          const relativeVolume = stream
            ? Math.min(
                1,
                Math.max(
                  0,
                  (volume - TYPICAL_MINIMUM) /
                    (TYPICAL_MAXIMUM - TYPICAL_MINIMUM)
                )
              )
            : 0;
          // Using 0.55 as the minimum threshold produced by a "speaking" individual.
          // NOT a perfect science
          if (relativeVolume > 0.55 && !speakingStreamIds.includes(stream.id)) {
            setSpeakingStreamIds([...speakingStreamIds, stream.id]);
          } else if (speakingStreamIds.includes(stream.id)) {
            // Otherwise remove if included
            setSpeakingStreamIds(
              speakingStreamIds.filter((streamId) => streamId !== stream.id)
            );
          }
        };

        // Analyze every second
        const analysisInterval = setInterval(processVolume, 1000);

        // Clean-up
        return {
          disconnect: () => {
            clearInterval(analysisInterval);
            analyzer.disconnect();
            source.disconnect();
            context.close();
          },
        };
      }
      return undefined;
    };
    // Parse unmuted peer audio streams
    const activeAudioStreams = Object.values(remoteParticipants)
      .filter((p) => p.consumers.audio && !p.consumers.audio.paused)
      .map((p) => p.consumers.audio?.stream);
    // Create a volume analyzer for each stream
    analyzersRef.current = activeAudioStreams.map((stream) =>
      createVolumeAnalyzer(stream)
    );

    return () => {
      // Clean-up: release analyzers when the component unmounts
      analyzersRef.current.forEach((analyzer) => {
        if (analyzer && "disconnect" in analyzer) analyzer.disconnect();
      });
    };
  }, [remoteParticipants, speakingStreamIds]);

  // Used to produce the spotlit video tile seen when the user is in SpotlightMode
  useEffect(() => {
    // If the user is sharing their own screen, always show their own shared screen
    if (isScreensharing) return;

    // If no peers and not screensharing, show waiting placeholder
    if (remoteParticipants.length === 0) {
      setSpotlitParticipant(undefined);
      return;
    }

    // Parse all hosts
    const hosts = remoteParticipants.filter(
      (p) => p.user.role === Role.webinarHost
    );

    // Parse all screensharing hosts
    const screenSharingHosts = hosts.filter((h) => !!h.consumers.screenshare);

    // If there is a current spotlit participant and they are screensharing,
    // don't steal their spotlight! (But set it to the same user to be sure the object
    // is up-to-date with any changes)
    if (
      spotlitParticipant &&
      screenSharingHosts.some((h) => spotlitParticipant.user.id === h.user.id)
    ) {
      setSpotlitParticipant(
        screenSharingHosts.find((h) => spotlitParticipant.user.id === h.user.id)
      );
      return;
    }

    // If not, first parse all speaking hosts
    const speakingHosts = hosts.filter(
      (h) =>
        h.consumers.audio &&
        !h.consumers.audio.paused &&
        speakingStreamIds.includes(h.consumers.audio.stream.id)
    );

    // Then parse all speaking, screensharing hosts
    const speakingScreensharingHosts = speakingHosts.filter((sh) =>
      screenSharingHosts.some((ss) => sh.user.id === ss.user.id)
    );

    // Prioritize a speaking, screensharing host
    if (speakingScreensharingHosts.length > 0) {
      setSpotlitParticipant(speakingScreensharingHosts[0]);
      return;
    }

    // Next prioritize any screensharing host over a speaking host
    // (even if they're silent)
    if (screenSharingHosts.length > 0) {
      setSpotlitParticipant(screenSharingHosts[0]);
      return;
    }

    // Then check for any speaking hosts
    if (speakingHosts.length > 0) {
      setSpotlitParticipant(speakingHosts[0]);
      return;
    }

    // Then check for any hosts with their video and audio BOTH
    // unmuted (even if NOT speaking)
    const videoAudioUnmutedHosts = hosts.filter(
      (h) =>
        h.consumers.video &&
        !h.consumers.video.paused &&
        h.consumers.audio &&
        !h.consumers.audio.paused
    );
    if (videoAudioUnmutedHosts.length > 0) {
      // If the currently spotlit participant is included in this group, keep the spotlight on
      // them, don't just switch to the first host in the list
      setSpotlitParticipant(
        videoAudioUnmutedHosts.find(
          (h) => h.user.id === spotlitParticipant?.user.id
        ) || videoAudioUnmutedHosts[0]
      );
      return;
    }

    // Lastly, fallback to ANY host (if one is present)
    if (hosts.length > 0) {
      // Similarly, if the currently spotlit participant is included in this group, keep
      // the spotlight on them, don't just switch to the first host in the list
      setSpotlitParticipant(
        hosts.find((h) => h.user.id === spotlitParticipant?.user.id) || hosts[0]
      );
      return;
    }

    // By this point, it means there are no
    // hosts present, so just set the spotlight to the placeholder
    setSpotlitParticipant(undefined);
  }, [
    remoteParticipants,
    spotlitParticipant,
    localParticipant,
    isScreensharing,
    speakingStreamIds,
  ]);

  return spotlitParticipant;
}
