import { Message, Role } from "@ameelio/connect-call-client";
import { useSnackbarContext } from "@ameelio/ui";
import { useEffect, useState } from "react";

function findLastIndex<T>(array: T[], predicate: (item: T) => boolean): number {
  const index = array.slice().reverse().findIndex(predicate);
  return index >= 0 ? array.length - 1 - index : -1;
}

// This hook is used to display alerts for messages that are sent by monitors, in voice and video calls.
function useCallAlerts(messages: Message[]) {
  const snackbarContext = useSnackbarContext();
  const [lastAlertIndex, setLastAlertIndex] = useState<number>(-1);

  useEffect(() => {
    const newAlerts = (messages || [])
      .slice(lastAlertIndex + 1)
      .filter((r) => r.user.role === Role.monitor);

    if (newAlerts.length) {
      const last = newAlerts[newAlerts.length - 1];
      snackbarContext.alert("warning", last.contents, {
        onClose: () => {
          const newLastIndex = findLastIndex(messages, (r) => r === last);
          setLastAlertIndex(newLastIndex);
        },
      });
    }
  }, [lastAlertIndex, messages, snackbarContext]);
}

export default useCallAlerts;
