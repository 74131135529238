import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { identify } from "./analytics";
import { useAuthContext } from "./AuthProvider";
import { GetCurrentUserDocument } from "./GetCurrentUser.generated";
import { languageToI18n } from "./lib/Language";
import getLanguageDirection from "./lib/languageDir";

export function useCurrentUserData() {
  const { isLoggedIn } = useAuthContext();
  const { loading, data } = useQuery(GetCurrentUserDocument, {
    fetchPolicy: "cache-first",
    skip: !isLoggedIn,
  });
  return { loading, ...data };
}

export default function CurrentUserProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const { i18n } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  const { error } = useQuery(GetCurrentUserDocument, {
    skip: !isLoggedIn,
    onCompleted: ({ me }) => {
      if (me) identify(me.id);

      // Update language preference whenever we fetch it
      if (me && "languagePreference" in me) {
        const targetLanguage = languageToI18n[me.languagePreference];
        if (i18n.language !== targetLanguage) {
          i18n.changeLanguage(targetLanguage);
          document.documentElement.lang = targetLanguage;
          i18n.dir(getLanguageDirection(targetLanguage));
        }
      }
    },
  });
  if (error) throw error;

  return children;
}
