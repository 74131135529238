import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import NotificationsScreen from "./NotificationsScreen";

const NotificationsRoutes: RouteObject = {
  path: "/notifications",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <MenuLayout currentTab="none">
          <NotificationsScreen />
        </MenuLayout>
      ),
    },
  ],
};

export default NotificationsRoutes;
