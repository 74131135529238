import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import MenuLayout from "../MenuLayout";
import SessionBoundary from "../SessionBoundary";
import ValidateUUID from "../ValidateUUID";
import EventScreen from "./EventScreen";
import EventsScreen from "./EventsScreen";
import NewEventScreen from "./NewEventScreen";

const EventsRoutes: RouteObject = {
  path: "/events",
  element: (
    <SessionBoundary>
      <Outlet />
    </SessionBoundary>
  ),
  children: [
    {
      index: true,
      element: (
        <MenuLayout currentTab="events">
          <EventsScreen />
        </MenuLayout>
      ),
    },
    { path: "new", element: <NewEventScreen /> },
    { path: "new/webinar", element: <NewEventScreen webinar /> },
    {
      path: "/events/:id",
      element: (
        <MenuLayout currentTab="events">
          <ValidateUUID>
            <EventScreen />
          </ValidateUUID>
        </MenuLayout>
      ),
    },
  ],
};

export default EventsRoutes;
