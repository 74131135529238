import { IconButton } from "@ameelio/ui";
import { Face6, WebAssetOff } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { blue, green, grey, red } from "@mui/material/colors";
import { t } from "i18next";
import React from "react";
import {
  Connection,
  Meeting,
  Message,
  NotificationEventVerb,
  Organization,
  SystemUser,
} from "../api/graphql";

type NotificationObjectType =
  | Connection["__typename"]
  | Message["__typename"]
  | Meeting["__typename"]
  | Organization["__typename"]
  | SystemUser["__typename"];

const notificationIcons: Record<
  NotificationEventVerb,
  Record<Exclude<NotificationObjectType, undefined>, JSX.Element | null>
> = {
  [NotificationEventVerb.Create]: {
    Organization: (
      <IconButton
        sx={{ background: blue[50] }}
        ariaLabel={t("Create organization")}
      >
        <WorkOutlineIcon sx={{ color: blue[900] }} />
      </IconButton>
    ),
    Connection: (
      <IconButton
        sx={{ background: green[50] }}
        ariaLabel={t("Create contact request")}
      >
        <PersonAddAltIcon sx={{ color: green[900] }} />
      </IconButton>
    ),
    Meeting: (
      <IconButton sx={{ background: blue[50] }} ariaLabel={t("Schedule event")}>
        <EventAvailableIcon sx={{ color: blue[900] }} />
      </IconButton>
    ),
    Message: null,
    SystemUser: null,
  },
  [NotificationEventVerb.Approve]: {
    Organization: null,
    Connection: (
      <IconButton
        sx={{ background: green[50] }}
        ariaLabel={t("Approve contact request")}
      >
        <PersonAddAltIcon sx={{ color: green[900] }} />
      </IconButton>
    ),
    Meeting: (
      <IconButton sx={{ background: green[50] }} ariaLabel={t("Approve event")}>
        <EventAvailableIcon sx={{ color: green[900] }} />
      </IconButton>
    ),
    Message: null,
    SystemUser: (
      <IconButton sx={{ background: green[50] }} ariaLabel={t("Approve user")}>
        <Face6 sx={{ color: green[900] }} />
      </IconButton>
    ),
  },
  [NotificationEventVerb.Reject]: {
    Organization: (
      <IconButton
        sx={{ background: red[50] }}
        ariaLabel={t("Reject organization")}
      >
        <WorkOffIcon sx={{ color: red[900] }} />
      </IconButton>
    ),
    Connection: (
      <IconButton
        sx={{ background: red[50] }}
        ariaLabel={t("Reject contact request")}
      >
        <PersonOffOutlinedIcon sx={{ color: red[900] }} />
      </IconButton>
    ),
    Meeting: (
      <IconButton sx={{ background: red[50] }} ariaLabel={t("Reject event")}>
        <EventBusyIcon sx={{ color: red[900] }} />
      </IconButton>
    ),
    Message: null,
    SystemUser: (
      <IconButton sx={{ background: red[50] }} ariaLabel={t("Reject user")}>
        <WebAssetOff sx={{ color: red[900] }} />
      </IconButton>
    ),
  },
  [NotificationEventVerb.Call]: {
    Organization: null,
    Connection: null,
    Meeting: null,
    Message: null,
    SystemUser: null,
  },
  [NotificationEventVerb.Cancel]: {
    Organization: null,
    Connection: null,
    Meeting: (
      <IconButton sx={{ background: red[50] }} ariaLabel={t("Cancel event")}>
        <EventBusyIcon sx={{ color: red[900] }} />
      </IconButton>
    ),
    Message: null,
    SystemUser: null,
  },
  [NotificationEventVerb.Remind]: {
    Organization: null,
    Connection: null,
    Meeting: (
      <IconButton sx={{ background: blue[50] }} ariaLabel={t("Remind event")}>
        <CalendarTodayIcon sx={{ color: blue[900] }} />
      </IconButton>
    ),
    Message: null,
    SystemUser: null,
  },
  [NotificationEventVerb.Update]: {
    Organization: null,
    // Archived contact
    Connection: (
      <IconButton
        sx={{ background: grey[50] }}
        ariaLabel={t("Update contact request")}
      >
        <PersonOffOutlinedIcon sx={{ color: grey[900] }} />
      </IconButton>
    ),
    Meeting: (
      <IconButton sx={{ background: blue[50] }} ariaLabel={t("Update event")}>
        <DriveFileRenameOutlineIcon sx={{ color: blue[900] }} />
      </IconButton>
    ),
    Message: null,
    SystemUser: null,
  },
};

export default notificationIcons;
