import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConnectionFragmentFragmentDoc } from '../api/ConnectionFragment.generated';
export type CreatePendingProfessionalConnectionMutationVariables = Types.Exact<{
  input: Types.CreatePendingProfessionalConnectionInput;
}>;


export type CreatePendingProfessionalConnectionMutation = { __typename: 'Mutation', createPendingProfessionalConnection: { __typename: 'CreatePendingProfessionalConnectionResponse', connection: { __typename: 'Connection', id: string, status: Types.ConnectionStatus, relationship: string, confidential: boolean, createdAt: number, messages: { __typename: 'MessageConnection', unreadMessageCount: number } } } };


export const CreatePendingProfessionalConnectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePendingProfessionalConnection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePendingProfessionalConnectionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPendingProfessionalConnection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}},{"kind":"Field","name":{"kind":"Name","value":"messages"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unreadMessageCount"}}]}}]}}]}}]}},...ConnectionFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreatePendingProfessionalConnectionMutation, CreatePendingProfessionalConnectionMutationVariables>;