import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MessageFragmentFragmentDoc } from '../api/MessageFragment.generated';
export type GetMessageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetMessageQuery = { __typename: 'Query', message: { __typename: 'Message', content: string, id: string, isRead: boolean, createdAt: number, availability: Types.MessageAvailability, statusDetails: string | null, isInstantReply: boolean, fileUrl: string | null, type: Types.MessageType } };


export const GetMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageFragment"}}]}}]}},...MessageFragmentFragmentDoc.definitions]} as unknown as DocumentNode<GetMessageQuery, GetMessageQueryVariables>;