import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import useApolloErrorHandler from "../lib/handleApolloError";
import { useServerEvent } from "../lib/serverEventsProvider";
import { useCurrentCorrespondent } from "../SessionBoundary";
import { GetConnectionTimelineDocument } from "./GetConnectionTimeline.generated";
import { UpdateMessageIsReadDocument } from "./UpdateMessageIsRead.generated";

export default function useConnectionTimeline(connectionId: string) {
  const onError = useApolloErrorHandler();
  const { data, error, fetchMore } = useQuery(GetConnectionTimelineDocument, {
    fetchPolicy: "cache-and-network",
    onError,
    variables: {
      id: connectionId,
      perPage: 100,
      before: undefined,
      after: undefined,
    },
  });

  // listen and update
  useServerEvent("updateConnectionTimeline", async ({ id }) => {
    if (connectionId === id) {
      fetchMore({
        variables: {
          after: data?.connection.timelineEvents.pageInfo.endCursor,
        },
      });
    }
  });

  const timelineEvents = useMemo(
    () =>
      data?.connection.timelineEvents.edges
        .map((te) => te.node)
        .filter(
          (e) =>
            e.object.__typename === "Meeting" ||
            e.object.__typename === "Message" ||
            e.object.__typename === "Connection"
        )
        .sort((a, b) => a.createdAt - b.createdAt),
    [data]
  );

  // optional callback that will load older events
  const fetchOlder = useMemo(() => {
    const pageInfo = data?.connection.timelineEvents.pageInfo;
    return pageInfo?.hasPreviousPage
      ? () =>
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
            },
          })
      : undefined;
  }, [data, fetchMore]);

  // callback that will mark all messages as read
  const [updateMessageIsRead] = useMutation(UpdateMessageIsReadDocument, {
    onError,
  });
  const user = useCurrentCorrespondent();
  const markMessagesAsRead = useCallback(() => {
    const messageIds: string[] = [];
    timelineEvents?.forEach((e) => {
      if (
        e.object.__typename === "Message" &&
        e.object.sender.id !== user.id &&
        !e.object.isRead
      ) {
        messageIds.push(e.object.id);
      }
    });
    if (messageIds.length > 0) {
      Promise.all(
        messageIds.map((messageId) =>
          updateMessageIsRead({ variables: { input: { messageId } } })
        )
      );
    }
  }, [timelineEvents, user.id, updateMessageIsRead]);

  return { timelineEvents, error, fetchOlder, markMessagesAsRead };
}
