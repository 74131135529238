import { ConnectionFeature, FacilityFeature } from "../api/graphql";

const connectionFeatureToFacilityFeature = (Feature: ConnectionFeature) => {
  const featureTypes: Record<ConnectionFeature, FacilityFeature> = {
    [ConnectionFeature.ConfidentialInPersonVisit]:
      FacilityFeature.ConfidentialInPersonVisit,
    [ConnectionFeature.ConfidentialVideoCall]:
      FacilityFeature.ConfidentialVideoCall,
    [ConnectionFeature.VideoCall]: FacilityFeature.VideoCall,
    [ConnectionFeature.InPersonVisit]: FacilityFeature.InPersonVisit,
    [ConnectionFeature.VoiceCall]: FacilityFeature.VoiceCall,
    [ConnectionFeature.Webinar]: FacilityFeature.Webinar,
    [ConnectionFeature.SendMessages]: FacilityFeature.Emessaging,
  };
  return featureTypes[Feature];
};

export default connectionFeatureToFacilityFeature;
