import { Button } from "@ameelio/ui";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CancelMeetingDialog from "./CancelMeetingDialog";
import { GetEventDetailsQuery } from "./GetEventDetails.generated";

type Props = {
  description: string;
  meeting: GetEventDetailsQuery["meeting"];
};

export default function CancelMeetingButton({ description, meeting }: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {open && (
        <CancelMeetingDialog
          meetingToCancel={{
            description,
            meetingId: meeting.id,
          }}
          onClose={() => setOpen(false)}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Button
          variant="outlined"
          color="error"
          sx={{ width: 1 }}
          onClick={() => setOpen(true)}
        >
          {t("Cancel event")}
        </Button>
      </Box>
    </>
  );
}
