const STATE_TO_INMATE_DB: Record<string, Record<string, string>> = {
  AK: {
    link: "https://vinelink.vineapps.com/search/AK/Person",
    name: "Find an Inmate / VINElink",
  },
  AL: {
    link: "http://www.doc.state.al.us/InmateSearch",
    name: "Inmate Search", // On-page title is "Search for inmates..."
  },
  AR: {
    link: "https://apps.ark.org/inmate_info/index.php",
    name: "Inmate Search",
  },
  AZ: {
    link: "https://corrections.az.gov/public-resources/inmate-datasearch",
    name: "Inmate Datasearch",
  },
  CA: {
    link: "https://inmatelocator.cdcr.ca.gov/search.aspx",
    name: "Inmate Locator",
  },
  CO: { link: "http://www.doc.state.co.us/oss/", name: "Offender Search" },
  CT: {
    link: "http://www.ctinmateinfo.state.ct.us/searchop.asp",
    name: "Offender Information Search",
  },
  DC: { link: "https://doc.dc.gov/node/305632", name: "Locate an Inmate" },
  DE: {
    link: "https://vinelink.vineapps.com/search/DE/Person",
    name: "Locate an Inmate / VINElink",
  },
  FL: {
    link: "http://www.dc.state.fl.us/OffenderSearch/Search.aspx",
    name: "Offender Information Search",
  },
  GA: {
    link: "https://services.gdc.ga.gov/GDC/OffenderQuery/jsp/OffQryForm.jsp?Institution=",
    name: "Find an Offender",
  },
  HI: {
    link: "https://vinelink.vineapps.com/search/HI/Person",
    name: "Statewide Automated Victim Information and Notification (SAVIN) / VINElink",
  },
  IA: {
    link: "https://doc-search.iowa.gov/Offender/Search",
    name: "Offender Search",
  },
  ID: {
    link: "https://www.idoc.idaho.gov/content/prisons/offender_search",
    name: "Resident/Client Search",
  },
  IL: {
    link: "https://www2.illinois.gov/idoc/offender/pages/inmatesearch.aspx",
    name: "Individual in Custody Search",
  },
  IN: {
    link: "https://www.in.gov/apps/indcorrection/ofs/ofs",
    name: "Incarcerated Search",
  },
  KS: {
    link: "https://kdocrepository.doc.ks.gov/kasper/",
    name: "Offender Population Search",
  },
  KY: { link: "http://kool.corrections.ky.gov/", name: "Offender Search" },
  LA: {
    link: "https://vinelink.vineapps.com/search/LA/Person",
    name: "Imprisoned Person Locator / VINElink",
  },
  MA: {
    link: "https://vinelink.vineapps.com/search/MA/Person",
    name: "VINElink",
  },
  MD: {
    link: "http://www.dpscs.state.md.us/inmate/",
    name: "Incarcerated Individual Locator",
  },
  ME: {
    link: "https://www1.maine.gov/cgi-bin/online/mdoc/search-and-deposit/search.pl?Search=Continue",
    name: "Adult Resident/Adult Community Corrections Client Search",
  },
  MI: {
    link: "http://mdocweb.state.mi.us/OTIS2/otis2.aspx",
    name: "Offender Tracking Information System",
  },
  MN: {
    link: "https://coms.doc.state.mn.us/PublicViewer",
    name: "Offender Locator",
  },
  MO: {
    link: "https://web.mo.gov/doc/offSearchWeb/welcome.do",
    name: "Offender Web Search",
  },
  MS: { link: "https://www.ms.gov/mdoc/inmate", name: "Inmate Search" },
  MT: { link: "https://app.mt.gov/conweb/", name: "Offender Search" },
  NC: {
    link: "https://webapps.doc.state.nc.us/opi/offendersearch.do?method=view",
    name: "Offender Search",
  },
  ND: {
    link: "http://www.nd.gov/docr/offenderlkup/index.asp",
    name: "Offender Lookup",
  },
  NE: {
    link: "http://dcs-inmatesearch.ne.gov/Corrections/COR_input.html",
    name: "Incarceration Records",
  },
  NH: {
    link: "http://business.nh.gov/inmate_locator/",
    name: "Inmate Locator",
  },
  NJ: {
    link: "https://www20.state.nj.us/DOC_Inmate/inmatesearch",
    name: "Offender Search Engine",
  },
  NM: {
    link: "https://www.cd.nm.gov/offender-search/",
    name: "Offender Search",
  },
  NV: { link: "https://ofdsearch.doc.nv.gov/form.php", name: "Inmate Search" },
  NY: {
    link: "http://nysdoccslookup.doccs.ny.gov/",
    name: "Incarcerated Lookup",
  },
  OH: {
    link: "https://appgateway.drc.ohio.gov/OffenderSearch",
    name: "Offender Search",
  },
  OK: { link: "https://okoffender.doc.ok.gov/", name: "Offender Search" },
  OR: {
    link: "http://docpub.state.or.us/OOS/intro.jsf",
    name: "Offender Search",
  },
  PA: {
    link: "http://inmatelocator.cor.pa.gov/#/",
    name: "Inmate/Parolee Locator",
  },
  RI: { link: "https://doc.ri.gov/inmate-search", name: "Inmate Search" },
  SC: {
    link: "http://public.doc.state.sc.us/scdc-public/",
    name: "Incarcerated Inmate Search",
  },
  SD: { link: "https://doc.sd.gov/adult/lookup/", name: "Offender Locator" },
  TN: {
    link: "https://foil.app.tn.gov/foil/search.jsp",
    name: "Felony Offender Information Search",
  },
  TX: {
    link: "https://inmate.tdcj.texas.gov/InmateSearch/start.action",
    name: "Inmate Information Search",
  },
  UT: {
    link: "https://corrections.utah.gov/offender-search/",
    name: "Offender Search",
  },
  VA: {
    link: "https://vadoc.virginia.gov/offenders/locator/index.aspx",
    name: "Inmate Locator",
  },
  VT: {
    link: "https://omsweb.public-safety-cloud.com/jtclientweb/(S(w42wllbjj4dtbxy4gvmfk4q4))/jailtracker/index/Vermont",
    name: "Offender Locator", // The link to this is "Offender Locator". The on-page title is "Jail Tracker" which is misleading.
  },
  WA: {
    link: "https://www.doc.wa.gov/information/inmate-search/default.aspx",
    name: "Inmate Search",
  },
  WI: {
    link: "https://appsdoc.wi.gov/lop/home.do",
    name: "Offender Locator Public",
  },
  WV: {
    link: "https://apps.wv.gov/ois/offendersearch/doc",
    name: "Offender Search",
  },
  WY: { link: "http://wdoc-loc.wyo.gov/", name: "Offender Locator" },
};

export default STATE_TO_INMATE_DB;
