import { uniq } from "@ameelio/core";
import { Button, MultiSelectChipInput } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Alert, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ConnectionStatus,
  Inmate,
  Kiosk,
  OrganizationMembershipStatus,
} from "../../../api/graphql";
import useApolloErrorHandler from "../../../lib/handleApolloError";
import StepperFormWrapper from "../../../lib/StepperFormWrapper";
import { StepperType } from "../../../lib/StepperWrapper";
import { ScreenTitle } from "../../../lib/typography";
import { currentMembership, useCurrentVisitor } from "../../../SessionBoundary";
import { GetColleagueMembershipsDocument } from "../../GetColleagueMemberships.generated";
import { getMaxGuestsForCapacity } from "../SelectTimeStep";
import {
  GuestListData,
  MeetingData,
  RegisteredGuest,
  UnregisteredGuest,
} from "../types";
import { WorkflowAction } from "../useWorkflowSteps";
import LearnMoreDialog from "./LearnMoreDialog";

type Props = {
  stepper: StepperType;
  action: WorkflowAction;
  defaultValues: {
    meetingType: MeetingData["meetingType"];
    registeredGuests?: RegisteredGuest[];
    unregisteredGuests?: UnregisteredGuest[];
    kiosk?: Pick<Kiosk, "id" | "capacity">;
  };
  inmate: Pick<Inmate, "id" | "firstName">;
  onSubmit: (data: GuestListData) => void;
};

export default function OrganizationAddGuestListStep({
  stepper,
  action,
  defaultValues,
  inmate,
  onSubmit,
}: Props) {
  const isEdit = action === "edit";
  const orgMember = useCurrentVisitor(); // orgMember is alias for admin or member
  const membership = currentMembership(orgMember);
  // only active org members should ever access this step
  if (!membership) throw new Error("missing a valid membership");

  const { t } = useTranslation();
  const onError = useApolloErrorHandler();

  const { control, watch } = useForm<{
    registeredGuestIds: string[];
  }>({
    mode: "onChange",
    defaultValues: {
      registeredGuestIds: uniq(
        (defaultValues.registeredGuests || []).map((rg) => rg.id)
      ),
    },
  });
  const registeredGuestIds = watch("registeredGuestIds");

  const { data } = useQuery(GetColleagueMembershipsDocument, {
    onError,
    fetchPolicy: "cache-and-network",
    variables: { organizationId: membership.organization.id },
  });
  // only colleagues with active memberships can be added to the guest list
  const colleagueMemberships = data?.organization.memberships.filter(
    (om) =>
      om.status === OrganizationMembershipStatus.Active &&
      om.visitor.id !== orgMember.id
  );

  // used for managing and validating the guest list
  const maxGuests = defaultValues.kiosk
    ? getMaxGuestsForCapacity(defaultValues.kiosk.capacity)
    : null;

  // used to display the Learn More dialog
  const [showLearnMore, setShowLearnMore] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const registeredGuests =
      colleagueMemberships
        ?.filter((om) => registeredGuestIds.includes(om.visitor.id))
        .map((om) => ({
          id: om.visitor.id,
          email: om.visitor.email,
          firstName: om.visitor.firstName,
          lastName: om.visitor.lastName,
          fullName: om.visitor.fullName,
          // the connection must exist by this point
          connectionId: om.visitor.connections.find(
            (c) =>
              c.status === ConnectionStatus.Active && c.inmate.id === inmate.id
          )!.id,
        })) ?? [];
    onSubmit({
      registeredGuests,
      unregisteredGuests: [], // org members cannot add unregistered guests
    });
  };

  return (
    <StepperFormWrapper
      stepper={stepper({ disabled: false, loading: false })}
      handleSubmit={handleSubmit}
    >
      <ScreenTitle>{t("Guest list")}</ScreenTitle>
      <Typography variant="body1" color="text.primary" mt={2}>
        {t("Guests must be added to the list for approval.")}{" "}
        {t("Select from active colleagues at your organization.")}{" "}
        {isEdit &&
          maxGuests &&
          t(
            "The current meeting time can accommodate up to {{count}} guests, if you add more you will need to choose a new time.",
            { count: maxGuests }
          )}
      </Typography>
      <Button
        variant="text"
        onClick={() => setShowLearnMore(true)}
        sx={{
          mt: 2,
          px: 0,
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        {t("Learn more about bringing guests")}
      </Button>
      {isEdit && (
        <Box my={3}>
          <Alert severity="warning">
            {t(
              "Some facilities require reapproval for events with new guests. Requests are approved in most cases."
            )}
          </Alert>
        </Box>
      )}
      <Box my={6}>
        <MultiSelectChipInput
          name="registeredGuestIds"
          label={t("Colleague(s)")}
          control={control}
          items={
            colleagueMemberships?.map((m) => ({
              value: m.visitor.id,
              name: m.visitor.fullName,
              disabled: !m.visitor.connections.some(
                (c) =>
                  c.inmate.id === inmate.id &&
                  c.status === ConnectionStatus.Active
              ),
              helperText: !m.visitor.connections.some(
                (c) =>
                  c.inmate.id === inmate.id &&
                  c.status === ConnectionStatus.Active
              )
                ? t(
                    "{{visitorName}} is not an approved contact of {{inmateName}}",
                    {
                      visitorName: m.visitor.firstName,
                      inmateName: inmate.firstName,
                    }
                  )
                : "",
            })) ?? []
          }
        />
      </Box>
      {showLearnMore && (
        <LearnMoreDialog
          meetingType={defaultValues.meetingType}
          visitorType="organization"
          onClose={() => setShowLearnMore(false)}
        />
      )}
    </StepperFormWrapper>
  );
}
