import { Box, Grid } from "@mui/material";
import React from "react";

type Props = {
  corner?: React.ReactNode;
  children: React.ReactNode;
};

export default function FullscreenLayout({ corner, children }: Props) {
  return (
    <Grid container sx={{ height: 1 }}>
      {corner && (
        <Box
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(4),
            left: (theme) => theme.spacing(4),
            display: "flex",
            justifyContent: "center",
          }}
        >
          {corner}
        </Box>
      )}
      <Grid item xs={10} md={8} lg={6} sx={{ marginX: "auto" }}>
        <Grid
          sx={{
            pt: { xs: 13, md: 20 },
            height: 1,
            overflowY: "auto",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
