import React from "react";
import { useParams } from "react-router-dom";
import NotFoundScreen from "./404";

export default function ValidateUUID({
  id = "id",
  children,
}: {
  id?: string;
  children: JSX.Element;
}) {
  const params = useParams();
  const validUUIDFormat = params[`${id}`]?.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
  );
  if (!validUUIDFormat) return <NotFoundScreen />;
  return children;
}
