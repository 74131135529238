import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Location, useLocation, useNavigate } from "react-router-dom";
import FullscreenFlow from "../../FullscreenFlow";
import { AvailableCountry } from "../../lib/DocumentType";
import isObjectWithKey from "../../lib/isObjectWithKey";
import ResetScroll from "../../lib/ResetScroll";
import StepperWrapper from "../../lib/StepperWrapper";
import SuccessScreen from "../../lib/SuccessScreen";
import { getRawDateTzUnaware } from "../../lib/timeFormats";
import {
  useCurrentCorrespondent,
  useSelectableCountry,
} from "../../SessionBoundary";
import ConfirmDataStep, { ConfirmStepData } from "./ConfirmDataStep";
import DocumentTypeStep, { DocumentTypeStepData } from "./DocumentTypeStep";
import IDSaved from "./IDSaved.svg";
import UploadPhotosStep, {
  IdDocumentTypeWithoutBirthCertificate,
  UploadPhotosStepData,
} from "./UploadPhotosStep";
import useSubmitID, { SubmitIDFormData } from "./useSubmitID";

type LocationState = {
  action?: "create" | "edit";
};

function isLocationState(obj: unknown): obj is LocationState {
  return isObjectWithKey(obj, "action") && typeof obj.action === "string";
}

function getLocationState(location: Location): LocationState | undefined {
  if (isLocationState(location.state)) return location.state;
  return undefined;
}

const STEPS = ["document_type", "upload_photos", "confirm", "finished"];
type FormStep = (typeof STEPS)[number];

type Props = {
  backTo: string;
  parentName: string;
};

export default function IdentificationFlow({ backTo, parentName }: Props) {
  const location = useLocation();
  const state = getLocationState(location) ?? { action: "create" };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const [step, setStep] = useState<FormStep>("document_type");
  const [formData, setFormData] = useState<SubmitIDFormData>({});
  const { submitIdentity } = useSubmitID({
    action: state.action || "create",
    onIDSubmitted: () => setStep("finished"),
  });

  /*  We need to detect for which country the user must choose the document to upload
      This is inferred from the connected facilities.
      The assumption is that a user will not have multiple connections from multiple countries.
      That's why we're taking only the first facility to determine the country.
      In case there is no connection, we assume that the country is the same of the user country (from CDN)
   */
  const userSelectableCountry = useSelectableCountry();
  const userConnectedFacilities =
    user.__typename === "Visitor"
      ? user.connections.map((c) => c.inmate?.facility)
      : [];
  const inferredDocumentCountry = userConnectedFacilities.length
    ? (userConnectedFacilities[0].country as AvailableCountry)
    : userSelectableCountry;

  return (
    <FullscreenFlow backTo={backTo} breadcrumbs={[parentName, t("Upload ID")]}>
      <ResetScroll key={step} />
      {step === "document_type" && (
        <DocumentTypeStep
          forcedCountry={inferredDocumentCountry}
          documentType={
            user.__typename === "Visitor"
              ? (user.identity
                  ?.documentType as IdDocumentTypeWithoutBirthCertificate)
              : undefined
          }
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () => navigate(backTo),
              buttonType: "next",
              loading: !!loading,
              disabled,
              steps: STEPS,
              step,
            })
          }
          onNext={(documentType: DocumentTypeStepData) => {
            setFormData({ ...formData, ...documentType });
            setStep("upload_photos");
          }}
        />
      )}
      {step === "upload_photos" && (
        <UploadPhotosStep
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          documentType={formData.documentType!}
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () => setStep("document_type"),
              buttonType: "next",
              loading: !!loading,
              disabled,
              steps: STEPS,
              step,
            })
          }
          defaultValues={formData}
          onNext={(photosData: UploadPhotosStepData) => {
            setFormData({ ...formData, ...photosData });
            setStep("confirm");
          }}
        />
      )}
      {step === "confirm" && (
        <ConfirmDataStep
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () => setStep("upload_photos"),
              buttonType: "finish",
              loading: !!loading,
              disabled,
              steps: STEPS,
              step,
            })
          }
          defaultValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            dateOfBirth:
              user.__typename === "Visitor" && user.dateOfBirth
                ? getRawDateTzUnaware(user.dateOfBirth)
                : new Date(),
          }}
          onNext={async (confirmData: ConfirmStepData) => {
            await submitIdentity({ ...formData, ...confirmData });
          }}
        />
      )}
      {step === "finished" && (
        <SuccessScreen
          illustration={IDSaved}
          illustrationWidth={324}
          title={t("ID saved")}
          content={t(
            "Your ID has been saved to your profile. We’ll include it when you send a request to connect with an incarcerated person, and facility staff will review it for a background check."
          )}
          buttonText={t("Return to settings")}
          buttonPath="/settings"
        />
      )}
    </FullscreenFlow>
  );
}
