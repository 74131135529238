import { Button } from "@ameelio/ui";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Types from "../api/graphql";
import { BaseSkeleton } from "../lib/closet";
import withSx from "../lib/withSx";
import { useCurrentCorrespondent } from "../SessionBoundary";
import ConnectionEventItem from "./ConnectionEventItem";
import MeetingEventItem from "./MeetingEventItem";
import MessageEventItem from "./MessageEventItem";
import TimelineDateMarker from "./TimelineDateMarker";
import useConnectionTimeline from "./useConnectionTimeline";

function EventItemSkeleton({ idx }: { idx: number }) {
  return (
    <Box
      key={idx}
      sx={{
        display: "flex",
        justifyContent: idx % 2 ? "flex-end" : "flex-start",
      }}
    >
      <BaseSkeleton width={240} height={36} sx={{ transform: "none" }} />
    </Box>
  );
}

const TimelineStack = withSx(Stack, {
  mx: 2,
  height: 1,
  "> div:nth-of-type(1)": { marginTop: "auto" },
});

type Props = {
  connectionId: string;
  pinToBottom?: boolean;
};

type Message = Omit<Types.Message, "fileUrl" | "sender" | "statusDetails"> & {
  fileUrl: string | null;
  statusDetails: string | null;
  sender:
    | { __typename: "Inmate"; id: string }
    | {
        __typename: "Visitor";
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        fullName: string;
      };
};

export default function ConnectionTimelineBox({
  connectionId,
  pinToBottom,
}: Props) {
  const { t } = useTranslation();

  const { timelineEvents, fetchOlder, markMessagesAsRead } =
    useConnectionTimeline(connectionId);

  // determine the last message sent by _this_ user
  const user = useCurrentCorrespondent();
  const messages = timelineEvents
    ?.map((e) => e.object)
    .filter((e): e is Message => e?.__typename === "Message");
  const latestOwnMessageIndex = messages
    ? messages.map((e) => e.sender.id).lastIndexOf(user.id)
    : -1;
  const latestOwnMessageId =
    latestOwnMessageIndex >= 0 && messages
      ? messages[latestOwnMessageIndex]?.id
      : undefined;

  const [loadingMore, setLoadingMore] = useState(false);
  useEffect(() => {
    setLoadingMore(false);
  }, [timelineEvents]);

  // mark them as read on render
  useEffect(() => {
    markMessagesAsRead();
  }, [markMessagesAsRead]);

  // loading
  if (!timelineEvents) {
    return (
      <TimelineStack spacing={1}>
        <EventItemSkeleton idx={1} />
        <EventItemSkeleton idx={3} />
        <EventItemSkeleton idx={4} />
        <EventItemSkeleton idx={5} />
      </TimelineStack>
    );
  }

  // empty
  if (timelineEvents.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography textAlign="center" variant="body2" color="text.secondary">
          {t("Send a message to start the conversation")}
        </Typography>
      </Box>
    );
  }

  return (
    <TimelineStack spacing={1}>
      {fetchOlder && (
        <Box sx={{ textAlign: "center", p: 2 }}>
          {loadingMore ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              size="small"
              onClick={() => {
                setLoadingMore(true);
                fetchOlder();
              }}
            >
              {t("Load earlier messages")}
            </Button>
          )}
        </Box>
      )}
      {timelineEvents.map((event, i, arr) => {
        const timelineDateMarker = (i === 0 ||
          new Date(event.createdAt).setHours(0, 0, 0, 0) !==
            new Date(arr[i - 1].createdAt).setHours(0, 0, 0, 0)) && (
          <TimelineDateMarker date={new Date(event.createdAt)} />
        );
        const result =
          event.object.__typename === "Message" ? (
            <MessageEventItem
              facility={event.object.connection.inmate.facility}
              latestOwnMessageId={latestOwnMessageId}
              object={event.object}
            />
          ) : event.object.__typename === "Meeting" ? (
            <MeetingEventItem object={event.object} />
          ) : event.object.__typename === "Connection" ? (
            <ConnectionEventItem object={event.object} />
          ) : (
            <div />
          );
        return (
          <Box key={event.id}>
            {timelineDateMarker}
            {result}
          </Box>
        );
      })}
      {pinToBottom && (
        <div
          ref={(node) =>
            node?.scrollIntoView({
              behavior: "auto",
              block: "start",
            })
          }
        />
      )}
    </TimelineStack>
  );
}
