import { DeviceType, useCurrentDeviceType } from "../../lib/responsiveHelpers";

type ParticipantsToColumns = Record<number, number>;

// how many videos (columns) per page at various screen sizes
const ItemsPerStrip: Record<DeviceType, number> = {
  [DeviceType.Desktop]: 8,
  [DeviceType.Laptop]: 6,
  [DeviceType.LargeTablet]: 5,
  [DeviceType.Tablet]: 3,
  [DeviceType.Mobile]: 2,
};

// how participants fit into the space at each breakpoint
const StripColumns: Record<DeviceType, ParticipantsToColumns> = {
  [DeviceType.Desktop]: {
    1: 2,
    2: 2,
    4: 8,
    9: 8,
    16: 8,
    25: 8,
  },
  [DeviceType.Laptop]: {
    1: 1,
    2: 2,
    4: 6,
    9: 6,
    16: 6,
    25: 6,
  },
  [DeviceType.LargeTablet]: {
    1: 1,
    2: 2,
    4: 5,
    9: 5,
    16: 5,
    25: 5,
  },
  [DeviceType.Tablet]: {
    1: 1,
    2: 2,
    4: 3,
    9: 3,
    16: 3,
    25: 3,
  },
  [DeviceType.Mobile]: {
    1: 1,
    2: 2,
    4: 2,
    9: 2,
    16: 2,
    25: 2,
  },
};

// how many videos (rows x columns) per page at various screen sizes
const VideosPerGrid: Record<DeviceType, number> = {
  [DeviceType.Desktop]: 9,
  [DeviceType.Laptop]: 9,
  [DeviceType.LargeTablet]: 6,
  [DeviceType.Tablet]: 4,
  [DeviceType.Mobile]: 3,
};

// how participants fit into the space at each breakpoint
const GridColumns: Record<DeviceType, ParticipantsToColumns> = {
  [DeviceType.Desktop]: {
    1: 1,
    2: 2,
    4: 2,
    9: 3,
    16: 4,
    25: 5,
  },
  [DeviceType.Laptop]: {
    1: 1,
    2: 2,
    4: 2,
    9: 3,
    16: 3,
    25: 3,
  },
  [DeviceType.LargeTablet]: {
    1: 1,
    2: 2,
    4: 2,
    9: 2,
    16: 2,
    25: 2,
  },
  [DeviceType.Tablet]: {
    1: 1,
    2: 2,
    4: 2,
    9: 2,
    16: 2,
    25: 2,
  },
  [DeviceType.Mobile]: {
    1: 1,
    2: 1,
    4: 1,
    9: 1,
    16: 1,
    25: 1,
  },
};

export default function useResponsiveVideoGridDimensions(numRows?: number) {
  // a forced single row, like with spotlight
  const isStrip = numRows === 1;

  const deviceType = useCurrentDeviceType();

  return {
    itemsPerPage: isStrip
      ? ItemsPerStrip[deviceType]
      : VideosPerGrid[deviceType],
    gridColumns: isStrip ? StripColumns[deviceType] : GridColumns[deviceType],
  };
}
