import i18n from "../i18n/i18nConfig";
import getFullName from "./getFullName";

/**
 * Generates an English or Spanish list.
 *
 * - a
 * - a <and, y> b     // no Oxford Comma
 * - a, b, <and, y> c // yes Oxford Comma
 */
export function englishOrSpanishList(arr: string[]): string {
  if (arr.length === 0) return "";
  // TODO: Once JS target in project is set to es2021, update to using Intl.ListFormat
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat
  const conjunction = i18n.language === "es" ? " y " : " and ";
  if (arr.length <= 2) return arr.join(conjunction);
  return [arr.slice(0, arr.length - 1).join(", "), arr[arr.length - 1]].join(
    `,${conjunction}`
  );
}

type Named = { firstName: string; lastName: string };

export function getFirstName(named: Named) {
  return named.firstName;
}

export function participantsList(participants: Named[], fullName?: boolean) {
  return englishOrSpanishList(
    participants.map(fullName ? getFullName : getFirstName)
  );
}
