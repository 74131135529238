import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../../api/MeetingFragment.generated';
import { CorrespondentFragmentFragmentDoc } from '../../api/CorrespondentFragment.generated';
export type UpdateMeetingMutationVariables = Types.Exact<{
  input: Types.UpdateScheduledMeetingInput;
}>;


export type UpdateMeetingMutation = { __typename: 'Mutation', updateScheduledMeeting: { __typename: 'UpdateScheduledMeetingResponse', meeting: { __typename: 'Meeting', id: string, title: string | null, meetingType: Types.MeetingType, status: Types.MeetingStatus, statusDetails: string | null, privacyLevel: Types.PrivacyLevel, contacts: Array<{ __typename: 'Inmate', id: string, firstName: string, lastName: string, fullName: string } | { __typename: 'Visitor', id: string, firstName: string, lastName: string, fullName: string }>, interval: { __typename: 'Interval', startAt: number, endAt: number, seconds: number | null } } } };


export const UpdateMeetingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateMeeting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateScheduledMeetingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateScheduledMeeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"contacts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CorrespondentFragment"}}]}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions,...CorrespondentFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateMeetingMutation, UpdateMeetingMutationVariables>;