export default class CustomBackground {
  kind: "none" | "blur" | "url";

  url?: string;

  lowResFallback?: HTMLImageElement;

  static NONE: CustomBackground;

  static BLUR: CustomBackground;

  constructor(
    kind: "none" | "blur" | "url",
    url?: string,
    lowResFallback?: HTMLImageElement
  ) {
    this.kind = kind;
    this.url = url;
    this.lowResFallback = lowResFallback;
  }
}

CustomBackground.NONE = new CustomBackground("none");
CustomBackground.BLUR = new CustomBackground("blur");
