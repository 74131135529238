import {
  ClientStatus,
  ProducerLabel,
  Role,
} from "@ameelio/connect-call-client";
import {
  Box,
  Stack,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import useChange from "@react-hook/change";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Correspondent, Interval, PrivacyLevel } from "../../api/graphql";
import blurBackgroundEnabled from "../../lib/blurBackgroundEnabled";
import isAmeelioTablet from "../../lib/isAmeelioTablet";
import { largeTabletAndUp } from "../../lib/responsiveHelpers";
import {
  DroppedStreams,
  useStreamTrackerContext,
} from "../../lib/StreamTrackerProvider";
import { DeviceInfo, useDeviceChanges } from "../../lib/useDevices";
import { useCurrentUser } from "../../SessionBoundary";
import { darkPalette, ltrTheme } from "../../theme";
import CallBackgroundSelector from "./CallBackgroundSelector";
import CallChat from "./CallChat";
import CallConfidentialWidget from "./CallConfidentialWidget";
import CallControls from "./CallControls";
import CallPanel, { chatWidth } from "./CallPanel";
import CallTimerWidget from "./CallTimerWidget";
import CaptionBox from "./CaptionBox";
import CaptionsContainer from "./CaptionsContainer";
import FloatingSelfieVideo from "./FloatingSelfieVideo";
import ParticipantAudio from "./ParticipantAudio";
import { useSegmenterContext } from "./SegmenterProvider";
import StatusWidget from "./StatusWidget";
import { ConnectionInfo } from "./types/ConnectionInfo";
import CustomBackground from "./types/CustomBackground";
import useCallAlerts from "./useCallAlerts";
import useUnreadMessageCount from "./useUnreadMessageCount";
import VideoParticipant from "./VideoParticipant";

enum Panel {
  None,
  Chat,
  Participants,
  Backgrounds,
}

const canBlurBackground = blurBackgroundEnabled();

const backdropStyle: SxProps<Theme> = {
  px: { xs: 1, md: 2 },
  py: { xs: 1, md: 2 },
  height: "100%",
  backgroundColor: darkPalette.background.default,
  overflow: "hidden",
  position: "relative",
};

export type Props = {
  facilityName: string;

  // User
  user: {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
  };

  // All correspondents
  correspondents: Pick<
    Correspondent,
    "id" | "firstName" | "lastName" | "fullName"
  >[];

  // Connection information
  connectionInfo: ConnectionInfo;

  privacyLevel: PrivacyLevel;
  interval: Pick<Interval, "startAt" | "endAt">;
  onPeerJoined: () => void;
  onLeave: () => void;
  beginMuted: boolean;
  beginHidden: boolean;

  customBackground?: CustomBackground;
  onSetCustomBackground?: (c: CustomBackground) => void;
};

function VideoCallInterface({
  facilityName,
  connectionInfo,
  user,
  correspondents,
  privacyLevel,
  interval,
  onPeerJoined,
  onLeave,
  beginMuted,
  beginHidden,
  customBackground,
  onSetCustomBackground,
}: Props) {
  const { t } = useTranslation();

  const correspondentById = Object.fromEntries(
    correspondents.map((c) => [c.id, c])
  );

  const currentUser = useCurrentUser();

  const {
    // isCisco,
    error,

    // Things Connect has which Cisco doesn't:
    messages,
    sendMessage,

    // Things only used in webinars:
    // remoteAudioMute,
    // remoteAudioUnmute,

    // Things both have:
    callStatus,
    clientStatus,

    localProducers,
    pauseProducer,
    resumeProducer,

    toggleCaption,

    logClientEvent,
    peers, // Currently only one peer allowed; future-proofed
    chatEnabled,

    captionsTimeline,

    enableFrux,

    user: receivedUser,
  } = connectionInfo;

  if (error) throw error;

  useCallAlerts(messages);

  // Enable frux immediately
  useEffect(() => {
    if (enableFrux) enableFrux();
  }, [enableFrux]);

  const onDeviceAdded = useCallback(
    (device: DeviceInfo) => {
      if (clientStatus === ClientStatus.connected) {
        logClientEvent(`Device added: ${device.label} - ${device.kind}`);
      }
    },
    [logClientEvent, clientStatus]
  );
  const onDeviceRemoved = useCallback(
    (device: DeviceInfo) => {
      if (clientStatus === ClientStatus.connected) {
        logClientEvent(`Device removed: ${device.label} - ${device.kind}`);
      }
    },
    [logClientEvent, clientStatus]
  );
  // Handle device changes (e.g. unplugging a microphone)
  useDeviceChanges(onDeviceAdded, onDeviceRemoved);

  const toggleAudio = useCallback(() => {
    if (localProducers.audio?.paused) resumeProducer(ProducerLabel.audio);
    else pauseProducer(ProducerLabel.audio);
  }, [pauseProducer, resumeProducer, localProducers]);

  const toggleVideo = useCallback(() => {
    if (localProducers.video?.paused) resumeProducer(ProducerLabel.video);
    else pauseProducer(ProducerLabel.video);
  }, [pauseProducer, resumeProducer, localProducers]);

  const nonMonitorPeers = Object.values(peers).filter(
    (p) => p.user.role !== Role.monitor
  );
  const peer = nonMonitorPeers[0];
  const streamTracker = useStreamTrackerContext();
  const [peerHasConnected, setPeerHasConnected] = useState<boolean>(false);
  const [shownPanel, setShownPanel] = useState<Panel>(Panel.None);
  const [showCaptions, setShowCaptions] = useState<boolean>(false);

  // Used for responsive layout
  const isLargeTabletAndUp = largeTabletAndUp(measureScreenWidth);

  // Track last known set of unjoined peers
  // and trigger onPeerJoined event when a new one appears.
  const knownPeers = useRef<Set<string>>(new Set());
  useEffect(() => {
    Object.keys(nonMonitorPeers).forEach((key) => {
      if (!knownPeers.current.has(key)) onPeerJoined();
    });
    knownPeers.current = new Set(Object.keys(nonMonitorPeers));
  }, [nonMonitorPeers, onPeerJoined]);

  const unreadMessageCount = useUnreadMessageCount(
    messages,
    shownPanel === Panel.Chat
  );

  useChange(
    receivedUser?.connectionState?.badConnection,
    (isDisabled: boolean | undefined): void => {
      if (isDisabled) {
        // Track a stream drop originating locally if video disabled
        streamTracker.handleDrop(DroppedStreams.local);
      }
    }
  );

  // Track a stream drop originating from peer if their connection state changes/video disabled
  useChange(
    peer?.connectionState?.badConnection,
    (isDisabled: boolean | undefined): void => {
      if (isDisabled) streamTracker.handleDrop(DroppedStreams.peer);
    }
  );

  const peerExists = !!peer;
  useEffect(() => {
    if (!peerHasConnected) {
      setPeerHasConnected(peerExists);
    }
  }, [peerExists, peerHasConnected]);

  const { status: segmenterStatus } = useSegmenterContext();

  const displaySelfMuted = localProducers.audio
    ? localProducers.audio.paused
    : beginMuted;
  const displaySelfHidden = localProducers.video
    ? localProducers.video.paused
    : beginHidden;

  return (
    <Box
      sx={{
        height: 1,
        width:
          shownPanel !== Panel.None && isLargeTabletAndUp
            ? `calc(100% - ${chatWidth})`
            : "100%",
      }}
    >
      <Box sx={backdropStyle}>
        <FloatingSelfieVideo
          user={user}
          peerHasConnected={peerHasConnected}
          hidden={displaySelfHidden}
          muted={displaySelfMuted}
          localProducers={localProducers}
          callStatus={callStatus}
          clientStatus={clientStatus}
          connectionState={receivedUser?.connectionState}
        />
        {!isLargeTabletAndUp && (
          <Box
            sx={{
              position: "absolute",
              top: ltrTheme.spacing(3),
              right: ltrTheme.spacing(2),
              zIndex: "modal",
            }}
          >
            <Stack direction="row" spacing={2}>
              {privacyLevel !== PrivacyLevel.Monitored && (
                <CallConfidentialWidget privacyLevel={privacyLevel} />
              )}
              <CallTimerWidget
                startAt={interval.startAt}
                endAt={interval.endAt}
              />
            </Stack>
          </Box>
        )}
        <Box sx={{ width: 1, height: 1 }}>
          {peerHasConnected && peer && (
            <>
              <VideoParticipant
                origin="remote"
                audioStream={peer.consumers.audio?.stream}
                stream={peer.consumers.video?.stream}
                videoPaused={peer.consumers.video?.paused}
                audioMuted={peer.consumers.audio?.paused}
                user={correspondentById[peer.user.id]}
                callStatus={callStatus}
                clientStatus={clientStatus}
                connectionState={peer?.connectionState}
                containerSx={{ borderRadius: 4 }}
              />
              <ParticipantAudio
                srcObject={peer.consumers.audio?.stream}
                autoPlay
              />
            </>
          )}
        </Box>
        {clientStatus === "disconnected" ? (
          <StatusWidget
            message={t(
              "You are disconnected from the call. Please check your network connection."
            )}
          />
        ) : !peerHasConnected ? (
          <StatusWidget message={t("Waiting for others to join")} />
        ) : !peer ? (
          <StatusWidget message={t("Waiting for others to rejoin")} />
        ) : null}
        {showCaptions && captionsTimeline.length && (
          <CaptionsContainer>
            {captionsTimeline.map((segment) => (
              <CaptionBox
                key={segment.timestamp}
                user={
                  segment.user && correspondentById[segment.user]
                    ? correspondentById[segment.user]
                    : {
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        fullName: t("You"),
                      }
                }
                message={segment.text}
              />
            ))}
          </CaptionsContainer>
        )}
        <Box
          sx={{
            display: "flex",
            width: 1,
            position: "absolute",
            zIndex: "modal",
            left: 0,
            bottom: ltrTheme.spacing(8),
            px: 2,
            height: 0,
          }}
        >
          <Box
            sx={{
              flex: "1 1 0px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {isLargeTabletAndUp && (
              <Stack direction="row" spacing={2}>
                <CallTimerWidget
                  startAt={interval.startAt}
                  endAt={interval.endAt}
                />
                {privacyLevel !== PrivacyLevel.Monitored && (
                  <CallConfidentialWidget privacyLevel={privacyLevel} />
                )}
              </Stack>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <CallControls
              muted={displaySelfMuted}
              hidden={displaySelfHidden}
              captions={showCaptions}
              captionsDisabled={clientStatus !== ClientStatus.connected}
              alerts={false}
              onToggleAudio={toggleAudio}
              onToggleVideo={toggleVideo}
              onToggleCaptions={() => {
                if (toggleCaption) {
                  const newState = !showCaptions;
                  toggleCaption(newState).catch((e) => {
                    if (e instanceof Error && e.message !== "Not connected") {
                      Sentry.captureException(e);
                    }
                  });
                  setShowCaptions(newState);
                }
              }}
              backgroundSelectionDisabled={
                clientStatus !== ClientStatus.connected
              }
              onToggleBackgroundSelection={
                segmenterStatus === "failed" ||
                !onSetCustomBackground ||
                isAmeelioTablet()
                  ? undefined
                  : () =>
                      setShownPanel(
                        shownPanel === Panel.Backgrounds
                          ? Panel.None
                          : Panel.Backgrounds
                      )
              }
              onToggleChat={
                chatEnabled
                  ? () =>
                      setShownPanel(
                        shownPanel === Panel.Chat ? Panel.None : Panel.Chat
                      )
                  : undefined
              }
              messageCount={unreadMessageCount}
              onLeave={onLeave}
              leaveCallLabel={t("Leave call")}
              leaveCallDisabled={clientStatus === ClientStatus.initializing}
              styleOverrides={{
                transform: "translateY(-50%)",
              }}
            />
          </Box>
          <Box sx={{ flex: "1 1 0px" }}>&nbsp;</Box>
        </Box>
      </Box>
      {shownPanel !== Panel.None && (
        <CallPanel>
          {shownPanel === Panel.Chat && (
            <CallChat
              facilityName={facilityName}
              privacyLevel={privacyLevel}
              currentUserId={user.id}
              correspondents={correspondents}
              messages={messages}
              onSend={
                callStatus === "live" && clientStatus === ClientStatus.connected
                  ? async (message) => {
                      await sendMessage(message).catch((e) =>
                        Sentry.captureException(e)
                      );
                    }
                  : undefined
              }
              onClose={() => setShownPanel(Panel.None)}
            />
          )}
          {shownPanel === Panel.Backgrounds && onSetCustomBackground && (
            <CallBackgroundSelector
              value={customBackground || CustomBackground.NONE}
              onChange={(c: CustomBackground) => {
                onSetCustomBackground(c);
                logClientEvent(`Setting custom background: ${c.kind}`);
              }}
              onClose={() => setShownPanel(Panel.None)}
              canClearCustomBackground
              canBlurBackground={canBlurBackground} // SC-6218 Blur filter on a 2D canvas isn't currently supported in Safari
            />
          )}
        </CallPanel>
      )}
    </Box>
  );
}

export default VideoCallInterface;
