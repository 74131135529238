import { FacilityFeature } from "../api/graphql";
import { useCurrentCorrespondent } from "../SessionBoundary";
import useConnectionsFeature from "./useConnectionsFeature";

export default function useHasScheduling(): boolean {
  const user = useCurrentCorrespondent();
  // Visitor or inmate whose facility supports meetings, should be able to see upcoming/not approved events
  const connectionsHaveEvents = [
    useConnectionsFeature(FacilityFeature.VideoCall),
    useConnectionsFeature(FacilityFeature.ConfidentialVideoCall),
    useConnectionsFeature(FacilityFeature.InPersonVisit),
    useConnectionsFeature(FacilityFeature.ConfidentialInPersonVisit),
    useConnectionsFeature(FacilityFeature.Webinar),
  ].some(Boolean);

  const inmateFacilityHasEvents =
    user.__typename === "Inmate" &&
    [
      FacilityFeature.VideoCall,
      FacilityFeature.InPersonVisit,
      FacilityFeature.ConfidentialInPersonVisit,
      FacilityFeature.ConfidentialVideoCall,
      FacilityFeature.Webinar,
    ].some((f) => user.facility.features.includes(f));

  return inmateFacilityHasEvents || connectionsHaveEvents;
}
