import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotFoundScreen from "../404";
import AppSkeleton from "../AppSkeleton";
import { useAuthContext } from "../AuthProvider";
import useApolloErrorHandler from "../lib/handleApolloError";
import SuccessScreen from "../lib/SuccessScreen";
import { dateToIso } from "../lib/timeFormats";
import { ScreenTitle } from "../lib/typography";
import { AcceptOrganizationInvitationDocument } from "./AcceptOrganizationInvitation.generated";
import CredentialsSignupForm, {
  CredentialsStepData,
} from "./CredentialsSignupForm";
import { GetInviteOrganizationDocument } from "./GetInviteOrganization.generated";
import ProfileSignupForm, { ProfileStepData } from "./ProfileSignupForm";
import SignupSuccess from "./SignupSuccess.svg";

type FormStep = "credentials" | "profile";
type FormData = Omit<CredentialsStepData, "email"> & ProfileStepData;

export default function OrganizationSignupScreen() {
  const { inviteCode } = useParams<{ inviteCode: string }>();
  if (!inviteCode) throw new Error("Missing inviteCode");

  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("credentials");
  const [finished, setFinished] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    password: "",
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
    phone: "",
    dateOfBirth: undefined,
    termsOfUse: false,
  });
  const { register } = useAuthContext();

  const onError = useApolloErrorHandler();
  const { client, data, loading } = useQuery(GetInviteOrganizationDocument, {
    variables: { inviteCode },
    onError,
  });
  const [acceptInvitation] = useMutation(AcceptOrganizationInvitationDocument, {
    onError,
  });

  const onSubmit = async ({ termsOfUse, ...input }: FormData) => {
    // Must have a dob, accepted the terms, and an invite code
    if (
      !input.dateOfBirth ||
      !termsOfUse ||
      !data?.organizationInvitationByCode
    )
      return;
    if (
      await register({
        ...input,
        email: data.organizationInvitationByCode.email,
        dateOfBirth: dateToIso(input.dateOfBirth),
      })
    ) {
      const result = await acceptInvitation({
        variables: { input: { inviteCode } },
      });
      // Force storage refresh to get the latest OrganizationMembership info
      await client.clearStore();

      if (result.data) setFinished(true);
    }
  };

  if (loading) return <AppSkeleton />;
  if (!data?.organizationInvitationByCode) return <NotFoundScreen />;

  const invitation = data.organizationInvitationByCode;

  if (!finished) {
    return (
      <>
        <ScreenTitle>
          {t("Join {{organizationName}} on Ameelio", {
            organizationName: invitation.organization.name,
          })}
        </ScreenTitle>
        {step === "credentials" && (
          <CredentialsSignupForm
            defaultValues={{ ...formData, email: invitation.email }}
            onSubmit={(credentialsData) => {
              setFormData({ ...formData, ...credentialsData });
              setStep("profile");
            }}
            disableEmailField
          />
        )}
        {step === "profile" && (
          <ProfileSignupForm
            defaultValues={formData}
            onBack={() => setStep("credentials")}
            onSubmit={async (profileData) => {
              setFormData({ ...formData, ...profileData });
              await onSubmit({ ...formData, ...profileData });
            }}
          />
        )}
      </>
    );
  }

  return (
    <SuccessScreen
      illustration={SignupSuccess}
      illustrationWidth={376}
      title={t("You're all set up, {{firstName}}!", {
        firstName: formData.firstName,
      })}
      content={t(
        "You should receive an email shortly confirming your account with us."
      )}
      buttonText={t(`Go to workspace`)}
      buttonPath="/"
    />
  );
}
