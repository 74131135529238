import { useEffect, useState } from "react";

/**
 * useNow returns the current Date and updates it regularly, after a delay (ms).
 */
export default function useNow({ updateDelay }: { updateDelay: number }) {
  const [now, setNow] = useState<Date>(new Date());
  useEffect(() => {
    const timer = setInterval(() => setNow(new Date()), updateDelay);
    return () => clearInterval(timer);
  }, [updateDelay]);
  return now;
}
