import { Paper, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
};

export function ScreenSectionTitle({ title }: Pick<Props, "title">) {
  return (
    <Typography variant="h2" sx={{ my: 2 }}>
      {title}
    </Typography>
  );
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section
 */
export default function ScreenSection({ title, children }: Props) {
  return (
    <section>
      <ScreenSectionTitle title={title} />
      <Paper variant="outlined" sx={{ py: 2, px: 3, borderRadius: 4 }}>
        {children}
      </Paper>
    </section>
  );
}
