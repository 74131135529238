export enum FacingMode {
  USER = "user",
  ENVIRONMENT = "environment",
}

type VideoStreamContraints = {
  mediaId?: string;
  facingMode?: FacingMode;
};
export const getVideoStream = (videoConstraints?: VideoStreamContraints) => {
  const videoConstraint =
    videoConstraints?.mediaId || videoConstraints?.facingMode
      ? {
          mediaId: videoConstraints?.mediaId,
          facingMode: videoConstraints?.facingMode,
        }
      : true;
  return navigator.mediaDevices.getUserMedia({
    video: videoConstraint,
    audio: false,
  });
};
