import { Box } from "@mui/material";
import withSx from "../../lib/withSx";

const CallWidget = withSx(Box, {
  backgroundColor: "#323232",
  boxShadow:
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  color: "white",
  py: 2,
  px: 3,
  textAlign: "center",
});

export default CallWidget;
