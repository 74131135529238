import { useEffect } from "react";

/**
 * useOnLoad will run an effect exactly once when the component first mounts.
 * it is useful primarily to document the desired effect of an empty dependency array.
 */
export default function useOnLoad(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(fn, []);
}
