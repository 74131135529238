import { Error, Lock } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Correspondent, Interval, PrivacyLevel } from "../../api/graphql";
import { formatTimer } from "../../lib/timeFormats";

type Props = {
  contact: Pick<Correspondent, "firstName" | "lastName" | "fullName">;
  interval?: Pick<Interval, "startAt" | "endAt">;
  privacyLevel: PrivacyLevel;
};

export default function VoiceCallContext({
  contact,
  interval,
  privacyLevel,
}: Props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        position: "absolute",
        zIndex: "modal",
        color: "white",
        left: 0,
        top: 0,
        p: 2,
        flexDirection: "column",
      }}
    >
      <Typography variant="h3">{contact.fullName}</Typography>
      {interval && (
        <Typography color="dark.text.secondary" variant="body1">
          {t("Time limit:")} {formatTimer(interval.endAt - interval.startAt)}
        </Typography>
      )}
      {privacyLevel === PrivacyLevel.Monitored ? (
        <Typography variant="body1" color="dark.text.secondary">
          {t("May be monitored or recorded")}{" "}
          <Error fontSize="small" sx={{ verticalAlign: "text-bottom" }} />
        </Typography>
      ) : (
        <Typography variant="body1" color="dark.text.secondary">
          {t("Not monitored or recorded")}
          <Lock fontSize="small" sx={{ verticalAlign: "text-bottom" }} />
        </Typography>
      )}
    </Box>
  );
}
