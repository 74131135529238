import { useQuery } from "@apollo/client";
import { subMonths } from "date-fns";
import { useEffect } from "react";
import { MeetingStatus } from "../api/graphql";
import { useServerEvent } from "../lib/serverEventsProvider";
import { GetEventsDocument } from "./GetEvents.generated";
import useMountedPolling from "./useMountedPolling";

const useEventsQuery = (statuses?: MeetingStatus[], itemsPerPage?: number) => {
  const dayStart = new Date();
  dayStart.setHours(0, 0, 0, 0);

  const {
    data,
    error,
    startPolling,
    stopPolling,
    refetch,
    fetchMore,
    loading,
  } = useQuery(GetEventsDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      dayStart: subMonths(dayStart, 1).getTime(),
      before: undefined,
      after: undefined,
      itemsPerPage: itemsPerPage || 50,
      meetingStatus: statuses || [
        MeetingStatus.PendingApproval,
        MeetingStatus.Rejected,
        MeetingStatus.Cancelled,
        MeetingStatus.Scheduled,
        MeetingStatus.Live,
        MeetingStatus.NoShow,
        MeetingStatus.Terminated,
        MeetingStatus.Ended,
      ],
    },
  });
  useMountedPolling(30000, startPolling, stopPolling);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useServerEvent("videoCallInitialized", () => {
    refetch();
  });

  useServerEvent("inPersonVisitInitialized", () => {
    refetch();
  });

  return { data, error, refetch, fetchMore, loading };
};

export default useEventsQuery;
